//piece of code to make a redirect after X seconds

const initRedirectIn = () => {
  const wrapper = document.querySelector('[data-redirect-in]');
  if(wrapper){
    window.setTimeout(() => {
      window.location.href = wrapper.dataset.redirectTo;
    }, wrapper.dataset.redirectIn);
  }
}

export { initRedirectIn }
