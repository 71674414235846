import {ajax, capitalize, debounced, observeClassChangeOn, ymdFormattedDate} from "./../utils";
import DateVisitor from "./DateVisitor";

class BookingProcess {
  constructor(wrapper = document) {
    this.wrapper = wrapper.querySelector('.wrapper-booking');
    if(!this.wrapper || this.wrapper.dataset.available !== 'true') {
      return;
    }
    // // check if on mobile
    // if(this.wrapper && isHidden(this.wrapper)) {
    //   this.wrapper = document.getElementById('mobileBookingModal');
    // }

    this.isTicket = this.wrapper.hasAttribute('data-is-ticket');
    this.useGroupPricing = this.wrapper.hasAttribute('data-use-group-pricing');
    if(this.useGroupPricing){
      this.groupsPrices = JSON.parse(this.wrapper.dataset.groupsPrices);
    }

    this.datepicker = this.wrapper.querySelector('input[name=date]');
    this.initializingCalendarDiv = this.wrapper.querySelector('.initializing-calendar');
    this.humanDates = document.querySelectorAll('.booking-human-date');
    // this.visitorsText = this.wrapper.querySelector('.container-calendar-visitor__visitor-text');

    this.mainLangIdName = this.isTicket ? 'lang_id' : 'main_lang_id';
    this.langOptions = this.wrapper.querySelectorAll(`[name=${this.mainLangIdName}]`);

    this.adultspicker = this.wrapper.querySelector('input[name=seats_adult]');
    this.childrenpicker = this.wrapper.querySelector('input[name=seats_child]');
    this.infantspicker = this.wrapper.querySelector('input[name=seats_infant]');

    this.experienceId = this.wrapper.dataset.experienceId;
    this.offerGiftButtons = document.querySelectorAll(".gift-offer");

    this.currentIteration = 0;
    this.maxIterations = 18; //Check available slots on 18 months
    this.nextMonthAvailable = false;
    this.nextYearAvailable = false;

    this.ajaxRequestRunning = false;
    this.ajaxRequest = null;
    this.isFree = this.wrapper.dataset.isFree === 'true';

    //in case experience uses the ticket system, display the total amount
    this.ticketTotal = this.wrapper.querySelector('.ticket-amount');

    //Initializes the whole process.
    this.initProcess();

    new DateVisitor(this.wrapper);

    if(!this.isTicket){
      this.moreSlotsDrawer = this.wrapper.querySelector('.drawer.more-slots-drawer');
      this.proposeSlotsDrawer = this.wrapper.querySelector('.drawer.propose-slots-drawer');
      this.proposeSlotsModalSuccess = document.querySelector('#suggestSlotsQuoteSuccessModal');
      if(this.moreSlotsDrawer){
        const drawers = [this.moreSlotsDrawer];
        if(this.proposeSlotsDrawer){
          drawers.push(this.proposeSlotsDrawer);
        }
        if(this.proposeSlotsModalSuccess){
          drawers.push(this.proposeSlotsModalSuccess);
        }

        //workaround to manage z-index, so the drawers stay above the navbar
        drawers.forEach(drawer => {
          observeClassChangeOn(drawer, () => {
            this.wrapper.classList.toggle('z-50');
          });
        });

        //in case we are in a drawer
        let bookExperienceDrawerContainer = this.moreSlotsDrawer.closest('.book-experience-drawer-container');
        if(bookExperienceDrawerContainer){
          //workaround for safari
          drawers.forEach(drawer => {
            observeClassChangeOn(drawer, () => {
              bookExperienceDrawerContainer.classList.toggle('overflow-visible');
              bookExperienceDrawerContainer.classList.toggle('overflow-y-auto');
            });
          });
        }
      }

      this.moreSlotsWrapper = this.wrapper.querySelector('#more-slots-wrapper');

      if(this.moreSlotsWrapper){
        this.previousDayButton = this.moreSlotsWrapper.querySelector('#previous-day-button');
        this.nextDayButton = this.moreSlotsWrapper.querySelector('#next-day-button');

        //init the two way data binding between the main booking form, and the form in the "more-slots drawer"
        this.initSyncBetweenForms();
      }

      //bind the next & previous buttons in the more slots drawer
      this.bindNextAndPreviousButtons();

      this.mainForm = this.wrapper.querySelector('form');

      this.bindBookButtons();
      this.timeslotsContainer = this.wrapper.querySelector('#timeslots-container');
      this.langIdInput = this.wrapper.querySelector("[name='lang_id']");
      this.slotIdInput = this.wrapper.querySelector("[name='slot_id']");
      this.slotStartInput = this.wrapper.querySelector("[name='slot']");
      this.moreTimeslotsButton = this.wrapper.querySelector('#more-timeslots-button');

      const _this = this;
      this.slowDebouncedTimeslotsUpdate = debounced(600, function (){
        _this.updateTimeslots();
      });

      this.debouncedTimeslotsUpdate = debounced(200, function (){
        _this.updateTimeslots();
      });
    }

    this.setPrice();

    //init clear filters (change language to any & clear the current date)
    this.initClearFilters();
  }

  /**
   * Initializes the whole process.
   *
   */
  initProcess() {
    const _this = this;

    // this.setupSession();

    this.currentAdults = parseInt(this.adultspicker.value);
    this.currentChildren = this.childrenpicker ? parseInt(this.childrenpicker.value) : 0;

    const currentLangChecked = this.wrapper.querySelector(`[name=${this.mainLangIdName}]:checked`);
    this.currentLangId = currentLangChecked?.value;
    this.langIdDuringIterations = currentLangChecked?.value;
    this.currentLangSvg = currentLangChecked?.dataset.svgUrl;

    // this.maxPersons = this.adultspicker.max;

    this.setupPickers();
    this.bindClearVisitors(this.wrapper);

    //If experience doesn't use the ticket system, then do everything that needs to be done with flatpickr / dates / slots
    if(!this.isTicket){
      let now = _this.datepicker.hasAttribute('data-default-date') ? new Date(_this.datepicker.dataset.defaultDate) : new Date();
      this.currentDate = null;
      this.currentDates = [];

      this.currentMonth = now.getMonth()+1;
      this.currentYear = now.getFullYear();

      this.defaultDate = now;
      let minDate = new Date();
      minDate.setHours(0, 0, 0, 0);

      this.initDates(function(dates) {
        _this.currentDates = dates;
        const enabledDates = _this.getEnabledDates();

        _this.mainFlatpickr = DateVisitor.initDatePicker(_this.datepicker, {
          inline: true,
          altInput: true,
          altFormat: "l, M J, Y",
          dateFormat: "Y-m-d",
          enable: enabledDates,
          defaultDate: _this.defaultDate,
          minDate: minDate,
          disableMobile: true,
          onMonthChange: function(selectedDates, dateStr, instance) {
            _this.rContainer?.classList.add('loading-spinner', 'tiny-spinner');

            _this.currentYear = instance.currentYear;
            _this.currentMonth = instance.currentMonth + 1;

            const unavailableMonthContainer = _this.mainFlatpickr.calendarContainer.querySelector('.month-unavailable');
            // check if we need to display this month as unavailable or not
            if(_this.currentIteration > 0 && _this.langIdDuringIterations == _this.currentLangId) {
              if (_this.currentYear > _this.nextYearAvailable) {
                unavailableMonthContainer.classList.remove('active');
              } else if (_this.currentYear === _this.nextYearAvailable && _this.currentMonth >= _this.nextMonthAvailable) {
                // do not display
                unavailableMonthContainer.classList.remove('active');
              } else {
                // display
                unavailableMonthContainer.classList.add('active');
              }
            }

            //fetch all dates available for the current month
            _this.fetchDates(function() {
              const enabledDates = _this.getEnabledDates(); //enable only the days with timeslots available (for both flatpickrs)
              instance.set('enable', enabledDates);
              _this.moreFlatpickr?.set('enable', enabledDates);

              _this.rContainer?.classList.remove('loading-spinner', 'tiny-spinner');
              _this.moreRContainer?.classList.remove('loading-spinner', 'tiny-spinner');
            });

            const date = new Date(instance.currentYear, instance.currentMonth, 1);
            _this.moreFlatpickr.jumpToDate(date, false);

            //hide the next & previous buttons while the user hasn't picked a new date
            _this.previousDayButton?.classList.add('hidden');
            _this.nextDayButton?.classList.add('hidden');
          },
          onChange: function(selectedDates, dateStr) {
            _this.formatHumanDate(selectedDates);

            if(selectedDates.length > 0) {
              _this.handleDateChange();
            }

            //set the date in the "more" datepicker
            _this.moreFlatpickr?.setDate(selectedDates[0], false);

            //show the current date selected in the "more slots" section
            if(_this.moreSlotsDateHeader && selectedDates.length > 0){
              const localeDateOptions = { weekday: 'long', month: 'long', day: 'numeric', year: 'numeric' };
              const dateString = selectedDates[0].toLocaleDateString(document.body.dataset.lang.replace('_', '-'), localeDateOptions);
              _this.moreSlotsDateHeader.textContent = dateString[0].toUpperCase() + dateString.slice(1); //to capitalize
            }

            if(selectedDates.length > 0) {
              DateVisitor.switchToVisitorsOnDateClick(_this.wrapper);
            }
          },
          onReady: function(selectedDates, dateStr, instance) {
            _this.initializingCalendarDiv?.remove();

            _this.moreFlatpickr?.set('enable', enabledDates);

            _this.formatHumanDate(selectedDates);

            if(selectedDates.length > 0) {
              _this.handleDateChange();

              //show the current date selected in the "more slots" section
              if(_this.moreSlotsDateHeader){
                const localeDateOptions = { weekday: 'long', month: 'long', day: 'numeric', year: 'numeric' };
                const dateString = selectedDates[0].toLocaleDateString(document.body.dataset.lang.replace('_', '-'), localeDateOptions);
                _this.moreSlotsDateHeader.textContent = dateString[0].toUpperCase() + dateString.slice(1); //to capitalize
              }
            }

            _this.rContainer = instance.calendarContainer.querySelector('.flatpickr-rContainer');

            _this.moreFlatpickr?.setDate(selectedDates[0], false);
          }
        });

        // check if we need to display each month as unavailable or not
        // iterates through all the months until there is a month that is available
        if(_this.currentIteration > 0) {
          const currentDate = new Date(_this.nextYearAvailable, _this.nextMonthAvailable-1);
          const flatpickrContainer = _this.mainFlatpickr.calendarContainer.querySelector('.flatpickr-rContainer');
          const divElement = document.createElement('div');
          divElement.classList.add('month-unavailable', 'active');
          const divExplanation = document.createElement('div');

          if(_this.currentIteration === 18){
            const text = document.createTextNode(_this.wrapper.dataset.textNotAvailable);
            divExplanation.appendChild(text);
            divElement.appendChild(divExplanation);
            flatpickrContainer.appendChild(divElement);
          }
          else{
            const text = document.createTextNode(_this.wrapper.dataset.textBookedUntil.replace('{0}', capitalize(currentDate.toLocaleDateString(undefined, {month: 'long'}))));
            const button = document.createElement('button');
            button.type = 'button';
            button.innerText = _this.wrapper.dataset.textView.replace('{0}', capitalize(currentDate.toLocaleDateString(undefined, {month: 'long'})));
            button.addEventListener('click', function () {
              divElement.classList.remove('active');
              _this.mainFlatpickr.changeYear(_this.nextYearAvailable);
              _this.mainFlatpickr.changeMonth(_this.nextMonthAvailable - 1, false);
            });

            divExplanation.appendChild(text);
            divExplanation.appendChild(button);
            divElement.appendChild(divExplanation);
            flatpickrContainer.appendChild(divElement);
          }
        }
      });

      // bind the clear date button
      // clears the calendar and timeslots container.
      this.bindClearDate(this.wrapper);

      this.debouncedFetchDates = debounced(400, function(){
        _this.fetchDates(function() {
          const enabledDates = _this.getEnabledDates();
          _this.mainFlatpickr.set('enable', enabledDates);
          if(_this.moreFlatpickr) _this.moreFlatpickr.set('enable', enabledDates);

          if(_this.rContainer) _this.rContainer.classList.remove('loading-spinner', 'tiny-spinner');
          if(_this.moreRContainer) _this.moreRContainer.classList.remove('loading-spinner', 'tiny-spinner');
        });
      });
    }
  }

  /**
   * Binds the clear date button to a click event listener.
   * When the button is clicked, it clears the calendar and timeslots container.
   */
  bindClearDate(wrapper) {
    const clearDateButton = wrapper.querySelector('.container-calendar-visitor__clear_date');
    if(clearDateButton){
      clearDateButton.addEventListener('click', () => {
        this.clearDate();
        this.hideMoreSlotsWrapperContent();
      });
    }
  }

  clearDate(){
    //first, clear the calendar
    this.resetCalendarAndSlot();

    //then empty the timeslots container
    this.timeslotsContainer.innerHTML = '';
    this.moreTimeslotsContainer.innerHTML = '';

    //also hide the more timeslots button in case it was visible
    this.moreTimeslotsButton.classList.add('hidden');
  }

  /**
   * Binds the clear visitors button to an event listener that resets the input values.
   * When the clear visitors button is clicked, the input values will be set to their initial values.
   */
  bindClearVisitors(wrapper){
    const clearVisitorsButton = wrapper.querySelector('.container-calendar-visitor__clear_visitors');
    const visitorsInputs = wrapper.querySelectorAll('.content-visitor .input-number input');
    if(clearVisitorsButton && visitorsInputs){
      clearVisitorsButton.addEventListener('click', () => {
        visitorsInputs.forEach(input => {
          input.value = input.dataset.initialValue;
          input.dispatchEvent(new Event('change'));
        });

        this.handleChangeAdult(this.adultspicker.value);

        if(this.childrenpicker){
          this.currentChildren = parseInt(this.childrenpicker.value);
        }
      });
    }
  }

  /**
   * Format the selected dates into a human-readable format and update the UI.
   *
   * @param {Array} selectedDates - An array of selected date objects.
   * @return {undefined} This function does not return a value.
   */
  formatHumanDate(selectedDates){
    if(selectedDates.length === 1 && this.humanDates) {
      this.humanDates.forEach((humanDate) => {
        humanDate.innerHTML = selectedDates[0].toLocaleDateString(undefined, { month: 'short', day: 'numeric' });
        humanDate.classList.add("font-semibold");
        humanDate.parentElement.classList.add('value-set');
      });
    }
  }

  /**
   * Sets up the pickers for language options, adults, children, and infants.
   * Adds event listeners for language options and updates the current language SVG.
   * Adds event listeners for the adults picker and updates the adults count.
   * Adds event listeners for the children picker and updates the children count.
   * Adds event listeners for the infants picker
   */
  setupPickers() {
    const _this = this;
    this.langOptions.forEach(option => {
      option.addEventListener('change', () => {
        if(option.checked){
          _this.handleLangChange(option.value);

          //to display on the timeslot div
          _this.currentLangSvg = option.dataset.svgUrl;
        }
      });
    });


    this.adultspicker.addEventListener('change', () => {
      _this.handleChangeAdult(this.adultspicker.value);
    });
    if(this.childrenpicker) {
      _this.childrenpicker.addEventListener('change', () => {
        _this.handleChangeChild(this.childrenpicker.value);
      });
    }

    if(this.infantspicker){
      this.infantspicker.addEventListener('change', () => {
        _this.handleChangeInfant();
      });
    }
  }


  /**
   * Updates the number of adult visitors and recalculates the price.
   *
   * @param {type} newValue - The new value of adult visitors.
   * @return {type} - None.
   */
  handleChangeAdult(newValue) {
    // if(this.childrenpicker) {
    //   this.childrenpicker.max = this.maxPersons - newValue;
    // }

    // update current value
    this.currentAdults = parseInt(newValue);
    this.setPrice();

    if(!this.isTicket){
      const _this = this;

      if(_this.rContainer) _this.rContainer.classList.add('loading-spinner', 'tiny-spinner');
      if(_this.moreRContainer) _this.moreRContainer.classList.add('loading-spinner', 'tiny-spinner');
      this.debouncedFetchDates();

      if(this.currentDate !== null){
        this.slowDebouncedTimeslotsUpdate();
      }
    }
  }

  /**
   * Updates the child value and calculates the maximum number of adults based on the new child value.
   * and recalculates the price
   * @param {number} newValue - The new value of the child.
   */
  handleChangeChild(newValue) {
    // this.adultspicker.max = this.maxPersons - newValue;

    // update current value
    this.currentChildren = parseInt(newValue);
    this.setPrice();

    if(!this.isTicket){
      const _this = this;

      if(_this.rContainer) _this.rContainer.classList.add('loading-spinner', 'tiny-spinner');
      if(_this.moreRContainer) _this.moreRContainer.classList.add('loading-spinner', 'tiny-spinner');
      this.debouncedFetchDates();

      if(this.currentDate !== null){
        this.slowDebouncedTimeslotsUpdate();
      }
    }
  }

  handleChangeInfant() {
    this.setPrice();

    if(!this.isTicket){
      const _this = this;

      if(_this.rContainer) _this.rContainer.classList.add('loading-spinner', 'tiny-spinner');
      if(_this.moreRContainer) _this.moreRContainer.classList.add('loading-spinner', 'tiny-spinner');
      this.debouncedFetchDates();

      if(this.currentDate !== null){
        this.slowDebouncedTimeslotsUpdate();
      }
    }
  }

  /**
   * Updates the current language and triggers the necessary actions.
   * Fetches the available dates & slots for the new language
   *
   * @param {type} newValue - The new value for the current language.
   * @return {type} None
   */
  handleLangChange(newValue) {
    this.currentLangId = newValue;
    if(!this.isTicket){
      const _this = this;

      if(_this.rContainer) _this.rContainer.classList.add('loading-spinner', 'tiny-spinner');
      if(_this.moreRContainer) _this.moreRContainer.classList.add('loading-spinner', 'tiny-spinner');
      this.debouncedFetchDates();

      if(this.currentDate !== null){
        this.debouncedTimeslotsUpdate();
      }
    }
  }

  /**
   * Resets the calendar and slots container.
   *
   * @param {}
   * @return {}
   */
  resetCalendarAndSlot() {
    this.mainFlatpickr.clear();
    if(this.moreFlatpickr) this.moreFlatpickr.clear();

    let today = new Date();

    //since flatpickr clear resets to the current month,
    //we need to check if we need to fetch dates for the current month if it has changed
    let triggerADatesFetch = this.currentMonth != (today.getMonth() + 1) || this.currentYear != today.getFullYear();

    this.currentDate = null;
    this.currentMonth = today.getMonth() + 1;
    this.currentYear = today.getFullYear();

    //also clear the human date below the date picker
    this.humanDates.forEach((humanDate) => {
      humanDate.textContent = humanDate.dataset.initialText;
      humanDate.parentElement.classList.remove('value-set');
    });

    if(triggerADatesFetch){
      if(this.rContainer) this.rContainer.classList.add('loading-spinner', 'tiny-spinner');
      if(this.moreRContainer) this.moreRContainer.classList.add('loading-spinner', 'tiny-spinner');
      this.debouncedFetchDates();
    }
  }

  /**
   * Sets the price of the booking depending on adults/children/infants count and currency.
   *
   * @return {void}
   */
  setPrice(){
    this.price = 0;
    this.formattedPrice = '';

    if(this.useGroupPricing){
      this.setGroupPrice();
    }
    else{
      this.setRegularPrice();
    }

    //do we apply a reduction on the price? (should be in a cookie)
    let applyExperienceReduction = this.wrapper.hasAttribute('data-experience-reduction-amount') && this.wrapper.dataset.hostCurrency == 'EUR' && document.body.dataset.currencyIso == 'EUR';
    if(applyExperienceReduction){
      this.reducedPrice = Number(this.price) - Number(this.wrapper.dataset.experienceReductionAmount);
      if(this.reducedPrice < 0) this.reducedPrice = 0;
    }

    if(this.wrapper.dataset.hostCurrency != document.body.dataset.currencyIso){
      this.price = this.price * this.wrapper.dataset.hostCurrencyRate;
    }

    //used to display below each timeslot
    let currencyLocale = document.body.dataset.currencyLocale != '' ? document.body.dataset.currencyLocale : 'en-US';
    this.formattedPrice = this.price.toLocaleString(currencyLocale.replace('_', '-'), {
      style: 'currency',
      currency: document.body.dataset.currencyIso
    });

    if(applyExperienceReduction){
      this.formattedReducedPrice = this.reducedPrice.toLocaleString(currencyLocale.replace('_', '-'), {
        style: 'currency',
        currency: document.body.dataset.currencyIso
      });
    }

    if(this.ticketTotal){
      if(this.formattedReducedPrice){
        let pricing = `<span class="text-xl font-semibold">${this.formattedReducedPrice}&nbsp;</span>`;

        if(this.reducedPrice > 0){
          pricing += `<span class="text-sm text-gray-500 line-through">${this.formattedPrice}</span>`;
        }

        this.ticketTotal.innerHTML = pricing;
      }
      else{
        this.ticketTotal.innerHTML = this.formattedPrice;
      }
    }

    // update gift buttons
    if(this.offerGiftButtons) {
      this.offerGiftButtons.forEach(button => {
        // button.href = button.dataset.url.replace("replaceAdults", this.adultspicker.value).replace("replaceChildren", this.childrenpicker ? this.childrenpicker.value : 0);
        button.dataset.redirectGo = window.btoa(button.dataset.url.replace("replaceAdults", this.adultspicker.value).replace("replaceChildren", this.childrenpicker ? this.childrenpicker.value : 0));
      });
    }
  }

  setRegularPrice(){
    if(!this.isFree) {
      if (this.childrenpicker) {
        this.price = ((this.adultspicker.value * this.wrapper.dataset.priceAdult) + (this.childrenpicker.value * this.wrapper.dataset.priceChild)) / 100;
      } else {
        this.price = ((this.adultspicker.value * this.wrapper.dataset.priceAdult)) / 100;
      }

      if(this.infantspicker){
        this.price += this.infantspicker.value * this.wrapper.dataset.priceInfant / 100;
      }
    }
  }

  setGroupPrice(){
    let totalPersons = Number(this.adultspicker.value);
    if(this.childrenpicker) totalPersons += Number(this.childrenpicker.value);
    if(this.infantspicker) totalPersons += Number(this.infantspicker.value);

    for(const group of this.groupsPrices){
      if(totalPersons >= group.from_persons && totalPersons <= group.to_persons){
        this.price = group.price;
        break;
      }
    }
  }

  //init clear filters (change language to any & clear the current date)
  initClearFilters(){
    const anyLanguageButton = this.wrapper.querySelector('.any-booking-form-language');
    if(anyLanguageButton){
      window.addEventListener('click', (e) => {
        if(e.target.classList.contains('clear-booking-form-filters')){
          //first, clear the date
          this.clearDate();

          //then, change the language to any
          anyLanguageButton.checked = true;
          anyLanguageButton.dispatchEvent(new Event('change'));

          //also hide current date, and previous & next available day buttons
          this.hideMoreSlotsWrapperContent();
        }
      });
    }
  }

  /**
   * Hides the content of the more slots wrapper if the date header, previous day button, and next day button are visible.
   */
  hideMoreSlotsWrapperContent(){
    if(this.moreSlotsDateHeader && this.previousDayButton && this.nextDayButton){
      this.moreSlotsDateHeader.textContent = '';

      this.previousDayButton.classList.add('hidden');
      this.nextDayButton.classList.add('hidden');
    }
  }

  // setupSession() {
  //   // set value from session if available
  //   if(this.session) {
  //     if (this.session.adults > this.adultspicker.max) {
  //       // this.handleChangeAdult(this.adultspicker.max);
  //       this.notAvailableText.innerText = this.wrapper.dataset.textAdultsHigher;
  //       // this.wrapperNotAvailableText.classList.remove('hidden');
  //     } else if (this.session.adults > 0 && this.session.adults < this.adultspicker.min) {
  //       this.notAvailableText.innerText = this.wrapper.dataset.textAdultsLess;
  //       // this.wrapperNotAvailableText.classList.remove('hidden');
  //     } else if (this.session.adults > 0) {
  //       this.adultspicker.value = this.session.adults;
  //       this.adultspicker.dispatchEvent(new Event('change'));
  //     }

  //     if (this.childrenpicker) {
  //       if (this.session.children > this.childrenpicker.max) {
  //         this.notAvailableText.innerText = this.wrapper.dataset.textChildrenHigher;
  //         // this.wrapperNotAvailableText.classList.remove('hidden');
  //       } else if (this.session.children > 0 && this.session.children < this.childrenpicker.min) {
  //         this.notAvailableText.innerText = this.wrapper.dataset.textChildrenLess;
  //         // this.wrapperNotAvailableText.classList.remove('hidden');
  //       } else if (this.session.children > 0) {
  //         this.childrenpicker.value = this.session.children;
  //         this.childrenpicker.dispatchEvent(new Event('change'));
  //       }
  //     }


  //     // try to select session language (first occurrence)
  //     if (this.session.langs !== undefined && this.session.langs !== null) {
  //       if (this.session.langs.length > 0) {
  //         const availableLangsId = [...this.langpicker.options].map(function (option) {
  //           return option.value;
  //         });

  //         // check if one language
  //         if (this.session.langs.length === 1) {
  //           if (availableLangsId.indexOf(this.session.langs[0]) === -1) {
  //             this.notAvailableText.innerText = this.wrapper.dataset.textLanguageNotAvailable;
  //             this.wrapperNotAvailableText.classList.remove('hidden');
  //           }
  //         } else {
  //           let available = false;

  //           langLoop:
  //             for (let i = 0; i < this.langpicker.options.length; i++) {
  //               for (let j = 0; j < this.session.langs.length; j++) {
  //                 if (this.langpicker.options[i].value === this.session.langs[j]) {
  //                   available = true;
  //                   continue langLoop;
  //                 }
  //               }
  //             }

  //           if (!available) {
  //             this.notAvailableText.innerText = this.wrapper.dataset.textNoneLanguageAvailable;
  //             this.wrapperNotAvailableText.classList.remove('hidden');
  //           }
  //         }
  //       }
  //     }
  //   }
  // }

  /**
   * Handle the change of the date.
   * Updates the current date in this
   * and the timeslots container
   *
   * @param {} - No parameters.
   * @return {} - No return value.
   */
  handleDateChange() {
    // set current date from datepicker value
    this.currentDate = this.datepicker.value;

    // update timeslots
    this.debouncedTimeslotsUpdate();
  }


  /**
   * Updates the timeslots container with timeslots (if found).
   *
   * @return {void}
   */
  updateTimeslots() {
    // check if we have all information we need to make the request
    if(this.currentDate !== null && this.currentDate !== '' && this.currentLangId !== null) {
      // get available time slots
      // make ajax request
      this.timeslotsContainer.classList.add('loading-spinner', 'set-min-height-10');
      this.moreTimeslotsContainerWrapper.classList.add('loading-spinner', 'set-min-height-20');

      ajax(`/api/experience/${this.experienceId}/timeslots?date=${this.currentDate}&adults=${this.currentAdults}&children=${this.currentChildren}&languages=${this.currentLangId}`, (data) => {
        if (data.timeslots === 'undefined' || data.timeslots.length === 0) {
          this.timeslotsContainer.innerHTML = this.buildClearFiltersNotice(
            this.timeslotsContainer.dataset.clearFiltersNotice
          );

          this.moreTimeslotsContainer.innerHTML = this.buildClearFiltersNotice(
            this.moreTimeslotsContainer.dataset.clearFiltersNotice
          );
        }
        else {
          //show the button to open the drawer that shows more timeslots if there are more than 2
          this.moreTimeslotsButton.classList.remove('hidden');

          //build timeslots for the main timeslots container
          let timeslotsHTML = ``;
          let currentSlotsInMainForm = 0;
          let maxSlotsInMainForm = Number(this.timeslotsContainer.dataset.maxSlotsShown) ?? 2;

          //we want to build a div for each language if "Any" lang is selected
          //otherwise show only slots filtered by the selected language
          data.timeslots.forEach((timeslot) => {
            timeslot.slot_languages.forEach((lang) => {
              if(currentSlotsInMainForm < maxSlotsInMainForm
                && (this.currentLangId == 'any' || this.currentLangId == lang.id))
              {
                timeslotsHTML += this.buildTimeslotDiv(timeslot, false, lang);
                currentSlotsInMainForm++;
              }
            })
          });

          this.timeslotsContainer.innerHTML = timeslotsHTML;

          // if(!this.showAllTimeSlotsInTheMainContainer){
          //and also build the timeslots for the drawer timeslots container (containing all the timeslots)
          let moreTimeslotsHTML = ``;
          data.timeslots.forEach((timeslot) => {
            timeslot.slot_languages.forEach((lang) => {
              if(this.currentLangId == 'any' || this.currentLangId == lang.id){
                moreTimeslotsHTML += this.buildTimeslotDiv(timeslot, true, lang);
              }
            });
          });

          this.moreTimeslotsContainer.innerHTML = moreTimeslotsHTML;
          //scroll the mroe time slots container to top
          this.moreTimeslotsContainer.scrollTop = 0;

          //handle the click on next & previous day buttons
          this.handlePreviousDay();
          this.handleNextDay();
          // }
        }

        this.timeslotsContainer.classList.remove('loading-spinner', 'set-min-height-10');
        this.moreTimeslotsContainerWrapper.classList.remove('loading-spinner', 'set-min-height-20');
      });
    }
  }

  /**
   * Builds a timeslot div based on the given timeslot.
   *
   * @param {Object} timeslot - The timeslot object containing start and end times.
   * @return {string} The HTML representation of the timeslot div.
   */
  buildTimeslotDiv(timeslot, inMoreSlotsWrapper = false, lang){
    let flag = `<img src="${lang.svg_url}" width="22" class="rounded-sm" />`;
    let specificClasses = inMoreSlotsWrapper ? 'border rounded-lg px-4 mb-4' : 'border-b';
    let instantBooking = timeslot.booking_auto ? '<i class="icon icon-bolt ml-2"></i>' : '';

    let pricing = `<span class="text-sm">${this.formattedPrice}</span>`;
    if(this.formattedReducedPrice){
      pricing = `<span class="text-xl font-semibold">${this.formattedReducedPrice}&nbsp;</span>`;

      if(this.reducedPrice > 0){
        pricing += `<span class="text-sm text-gray-500 line-through">${this.formattedPrice}</span>`;
      }
    }

    return `
      <div class="bg-white flex items-center ${specificClasses} py-4">
          <div class="flex flex-col mr-5">
            <div class="flex items-center">
              <span class="font-semibold mr-2">${timeslot.start} - ${timeslot.end} </span>
              <span>${flag}</span>
            </div>
            <div>${pricing}</div>
          </div>

          <button type="button" name="slot" class="btn btn-me-red btn-min-w px-6 ml-auto rounded-lg book-button btn-add-to-cart"
                  data-slot-id="${timeslot.id}" data-slot-start="${timeslot.start_at}"
                  data-lang-id="${lang.id}">
            <span class="button__content flex items-center justify-center">
              ${this.wrapper.dataset.textBook}
              ${instantBooking}
            </span>
          </button>
      </div>
    `;
  }

  buildClearFiltersNotice(text){
    let replacedText = text
    .replace('{0}', '<button type="button" class="underline clear-booking-form-filters">')
    .replace('{1}', '</button>');

    return `<div class="my-6 clear-booking-form-filters-wrapper">${replacedText}</div>`;
  }

  /**
   * Binds event listener to book buttons.
   *
   * @param {Event} e - The event object.
   * @return {void} This function does not return anything.
   */
  bindBookButtons(){
    window.addEventListener('click', (e) => {
      if(e.target.classList.contains('book-button') || e.target.closest('.book-button')){
        let button = e.target;

        //in case the span inside the button was clicked, we want to reference the actual button and not the span
        if(!button.classList.contains('book-button')){
          button = e.target.closest('.book-button');
        }

        //should not exist when experience used the ticketing system
        if(this.langIdInput){
          this.langIdInput.value = button.dataset.langId;
        }

        this.slotIdInput.value = button.dataset.slotId;
        this.slotStartInput.value = button.dataset.slotStart;

        button.classList.add('loading', 'tiny-loading');

        //disable all booking buttons on the page, just to be sure
        const bookingButtons = document.querySelectorAll('.book-button');
        bookingButtons.forEach(button => {
          button.disabled = true;
        });

        this.mainForm.submit();
      }
    });
  }

  /**
   * Retrieves the enabled dates.
   *
   * @return {Array} An array of enabled dates in the format "date_format".
   */
  getEnabledDates() {
    const enabledDates = this.currentDates.filter((date) => {
      return !date.close;
    });

    // check if 0 enabled dates
    if(enabledDates.length === 0) {
      // return false to disable all dates
      return [function() {
        return false;
      }];
    }

    return enabledDates.map((date) => {
      return date.date_format;
    });
  }

  /**
   * Retrieves the raw enabled dates from the current dates list.
   *
   * @return {Array} An array of date_format values representing the enabled dates.
   */
  getRawEnabledDates() {
    const enabledDates = this.currentDates.filter((date) => {
      return !date.close;
    });

    return enabledDates.map((date) => {
      return date.date_format;
    });
  }

  /**
   * Fetches the dates using an AJAX request and calls the provided callback function with the retrieved dates.
   *
   * @param {function} callback - The callback function to be called with the retrieved dates.
   * @return {void}
   */
  fetchDates(callback) {
    const _this = this;
    callback = callback || function(){};

    if(this.ajaxRequestRunning) {
      this.ajaxRequest.abort();
    }

    this.ajaxRequestRunning = true;
    this.ajaxRequest = ajax(this.wrapper.dataset.datesUrl + `?month=${this.currentMonth}&year=${this.currentYear}&lang_id=${this.currentLangId}&adults=${this.currentAdults}&children=${this.currentChildren}`, function(data) {
      _this.currentDates = data.dates;
      callback(data.dates);
      _this.ajaxRequestRunning = false;
    });
  }

/**
 * Initializes the dates by making an AJAX request to the specified URL
 * and populates the currentDates array with the response data. If no enabled
 * dates are found and the currentIteration is less than the maxIterations,
 * the currentMonth and currentYear are incremented and the function is called
 * recursively. The nextMonthAvailable and nextYearAvailable are updated
 * accordingly. Finally, the provided callback function is called with the
 * updated dates data.
 *
 * @param {function} callback - The callback function to be called with the
 *                              updated dates data.
 * @return {void}
 */
  initDates(callback) {
    const _this = this;
    callback = callback || function(){};
    ajax(this.wrapper.dataset.datesUrl + `?month=${this.currentMonth}&year=${this.currentYear}&lang_id=${this.currentLangId}&adults=${this.currentAdults}&children=${this.currentChildren}`, function(data) {
      _this.currentDates = data.dates;
      let enabledDates = _this.getRawEnabledDates();
      if(enabledDates.length === 0 && _this.currentIteration < _this.maxIterations) {
        if(_this.currentMonth === 12) {
          _this.currentMonth = 1;
          _this.currentYear++;
        } else {
          _this.currentMonth = _this.currentMonth + 1;
        }
        _this.currentIteration++;
        _this.initDates(callback);
        return;
      }

      _this.nextMonthAvailable = _this.currentMonth;
      _this.nextYearAvailable = _this.currentYear;

      callback(data.dates);
    });
  }

  /**
   * Initializes the synchronization between the main form and the drawer booking form.
   *
   * @param {}
   * @return {}
   */
  initSyncBetweenForms(){
    this.moreSlotsDateHeader = this.moreSlotsWrapper.querySelector('#more-slots-date-header');
    this.moreTimeslotsContainer = this.moreSlotsWrapper.querySelector('#more-timeslots-container');
    this.moreTimeslotsContainerWrapper = this.moreTimeslotsContainer.closest('.more-timeslots-container-wrapper');

    this.syncLangs();
    this.syncDates();
    this.syncVisitors();

    // bind the clear date & visitors button
    // clears the calendar and timeslots container.
    this.bindClearDate(this.moreSlotsWrapper);
    this.bindClearVisitors(this.moreSlotsWrapper);
  }

  /**
   * Synchronizes the selected language between main booking form & drawer booking form
   *
   */
  syncLangs(){
    //first, bind all more langs
    this.moreLangs = this.moreSlotsWrapper.querySelectorAll("[name='more_slots_lang_id']");
    if(this.moreLangs){
      this.moreLangs.forEach((lang) => {
        lang.addEventListener('change', (e) => {
          if(lang.checked){
            //check the corresponding main lang
            const langOption = document.querySelector(`[name='${this.mainLangIdName}'][data-lang-id='${lang.dataset.langId}']`);
            if(langOption){
              langOption.checked = true;
            }

            this.handleLangChange(lang.value);
            this.currentLangSvg = lang.dataset.svgUrl;
          }
        });
      });

      this.langOptions.forEach((lang) => {
        lang.addEventListener('change', (e) => {
          if(lang.checked){
            //check the corresponding main lang
            const langOption = document.querySelector(`[name='more_slots_lang_id'][data-lang-id='${lang.dataset.langId}']`);
            if(langOption){
              langOption.checked = true;
            }
          }
        });
      })
    }
  }

/**
 * Syncs the "more" date visitor component with the main one.
 *
 * @return {void}
 */
  syncDates(){
    //next, bind/sync the "more" date visitor component with the main one
    new DateVisitor(this.moreSlotsWrapper);

    const _this = this;
    const moreDatepicker = this.moreSlotsWrapper.querySelector('.flatpickr');
    const initializingCalendarDiv = this.moreSlotsWrapper.querySelector('.initializing-calendar');
    this.moreFlatpickr = DateVisitor.initDatePicker(moreDatepicker, {
      inline: true,
      altInput: true,
      altFormat: "l, M J, Y",
      dateFormat: "Y-m-d",
      minDate: new Date(),
      disableMobile: true,
      monthSelectorType: 'static',
      onMonthChange: function(selectedDates, dateStr, instance) {
        _this.moreRContainer?.classList.add('loading-spinner', 'tiny-spinner');

        //to change the calendar view for the main flatpickr
        const date = new Date(instance.currentYear, instance.currentMonth, 1);
        _this.mainFlatpickr.jumpToDate(date, true);
      },
      onChange: function(selectedDates, dateStr) {
        if(selectedDates){
          _this.mainFlatpickr.setDate(selectedDates[0], true);
        }
      },
      onReady: function(selectedDates, dateStr, instance) {
        initializingCalendarDiv?.remove();

        _this.moreRContainer = instance.calendarContainer.querySelector('.flatpickr-rContainer');
      }
    });
  }

  /**
   * Synchronizes the values of visitors pickers between the main form and the drawer booking form.
   *
   */
  syncVisitors(){
    this.moreAdultspicker = this.moreSlotsWrapper.querySelector('input[name=more_slots_seats_adult]');
    this.moreChildrenpicker = this.moreSlotsWrapper.querySelector('input[name=more_slots_seats_child]');
    this.moreInfantspicker = this.moreSlotsWrapper.querySelector('input[name=more_slots_seats_infant]');

    this.adultspicker.addEventListener('change', () => {
      this.moreAdultspicker.value = this.adultspicker.value;
      this.moreAdultspicker.dispatchEvent(new Event('updateContainerValue')); //updates UI
      this.moreAdultspicker.max = this.adultspicker.max;
    });

    this.moreAdultspicker.addEventListener('change', () => {
      this.adultspicker.value = this.moreAdultspicker.value;
      this.adultspicker.dispatchEvent(new Event('updateContainerValue')); //updates UI
      this.adultspicker.max = this.moreAdultspicker.max;

      this.handleChangeAdult(this.moreAdultspicker.value);
    });

    if(this.childrenpicker && this.moreChildrenpicker){
      this.childrenpicker.addEventListener('change', () => {
        this.moreChildrenpicker.value = this.childrenpicker.value;
        this.moreChildrenpicker.dispatchEvent(new Event('updateContainerValue')); //updates UI
        this.moreChildrenpicker.max = this.childrenpicker.max;
      });

      this.moreChildrenpicker.addEventListener('change', () => {
        this.childrenpicker.value = this.moreChildrenpicker.value;
        this.childrenpicker.dispatchEvent(new Event('updateContainerValue')); //updates UI
        this.childrenpicker.max = this.moreChildrenpicker.max;

        this.handleChangeChild(this.moreChildrenpicker.value);
      });
    }

    if(this.infantspicker && this.moreInfantspicker){
      this.infantspicker.addEventListener('change', () => {
        this.moreInfantspicker.value = this.infantspicker.value;
        this.moreInfantspicker.dispatchEvent(new Event('updateContainerValue')); //updates UI
        this.moreInfantspicker.max = this.infantspicker.max;
      });

      this.moreInfantspicker.addEventListener('change', () => {
        this.infantspicker.value = this.moreInfantspicker.value;
        this.infantspicker.dispatchEvent(new Event('updateContainerValue')); //updates UI
        this.infantspicker.max = this.moreInfantspicker.max;

        this.handleChangeInfant();
      });
    }
  }

  handleNextDay(){
    if(this.nextDayButton){
      let currentDay = this.wrapper.querySelector('.flatpickr-day.selected');
      if(currentDay){
        let nextDay = null;
        let checkLimit = 0;
        while(!nextDay && checkLimit < 30){
          if(currentDay && currentDay.nextSibling && !currentDay.nextSibling.classList.contains('flatpickr-disabled')){
            nextDay = currentDay.nextSibling;
            break;
          }

          if(currentDay) currentDay = currentDay.nextSibling;
          checkLimit++;
        }

        if(nextDay){
          const date = new Date(this.currentYear, this.currentMonth - 1, nextDay.textContent);
          this.nextDayButton.dataset.date = ymdFormattedDate(date);

          this.nextDayButton.classList.remove('hidden');
        }
        else{
          this.nextDayButton.classList.add('hidden');
        }
      }
    }
    else{
      this.nextDayButton.classList.add('hidden');
    }
  }

  handlePreviousDay(){
    if(this.previousDayButton){
      let currentDay = this.wrapper.querySelector('.flatpickr-day.selected');
      if(currentDay){
        let previousDay = null;
        let checkLimit = 0;
        while(!previousDay && checkLimit < 30){
          if(currentDay && currentDay.previousSibling && !currentDay.previousSibling.classList.contains('flatpickr-disabled')){
            previousDay = currentDay.previousSibling;
            break;
          }

          if(currentDay) currentDay = currentDay.previousSibling;
          checkLimit++;
        }

        if(previousDay){
          const date = new Date(this.currentYear, this.currentMonth - 1, previousDay.textContent);
          this.previousDayButton.dataset.date = ymdFormattedDate(date);

          this.previousDayButton.classList.remove('hidden');
        }
        else{
          this.previousDayButton.classList.add('hidden');
        }
      }
      else{
        this.previousDayButton.classList.add('hidden');
      }
    }
  }

  bindNextAndPreviousButtons(){
    if(this.nextDayButton && this.previousDayButton){
      [this.nextDayButton, this.previousDayButton].forEach((button) => {
        button.addEventListener('click', () => {
          this.mainFlatpickr.setDate(button.dataset.date, true);
        });
      });
    }
  }
}

export default BookingProcess;
