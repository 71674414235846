import Modal from './frontv2/Modal';
import Message from './Message';
// import { initPhoneValidation } from './phone_validation';
import { ajax, debounced } from './utils';

class UserProfile {
    constructor() {
        this.wrapper = document.getElementById('profileWrapper');

        if(this.wrapper){
            this.initEditables();
            this.initAddressAutoComplete();
            this.initPasswordStrengthBar();
            this.initNotifications();
            this.initPrivacy();
            this.initPaymentDetails();
            this.initGiftCardRedeem();
            this.initPromoCodeRedeem();

            // initPhoneValidation();
        }
    }

    initEditables() {
        const editables = this.wrapper.querySelectorAll(".editable");
        const overlayLoading = this.wrapper.querySelector("#overlay-loading");
        const _this = this;

        if(editables && overlayLoading){
            editables.forEach(editable => {
                let editButton = editable.getElementsByClassName('editButton')[0];
                let data = editable.getElementsByClassName('data')[0];
                let editForm = editable.getElementsByClassName('editForm')[0];

                if(editButton && editForm && data) {
                    const editText = editButton.dataset.editText;
                    const cancelText = editButton.dataset.cancelText;

                    editButton.addEventListener('click', () => {
                        data.classList.toggle('hidden');
                        editForm.classList.toggle('hidden');

                        editButton.textContent = editButton.textContent == editText ? cancelText : editText;
                    });

                    //if there's a get parameter to set birth date, simulate a click on birth date edit button
                    let getParamBirthDate = new URLSearchParams(window.location.search).get('action');
                    if (getParamBirthDate != null && getParamBirthDate === 'set_birth_date' && editButton.classList.contains('birth-date')){
                      editButton.dispatchEvent(new Event('click'));
                      // editButton.scrollIntoView();
                    }

                    editForm.addEventListener('submit', function(e){
                        e.preventDefault();
                        let formData = new FormData(this);
                        const action = this.action;

                        overlayLoading.classList.remove('hidden');

                        ajax(action, (res) => {
                            const {updated_info, success, message} = res;

                            if(updated_info){
                                Object.keys(updated_info).forEach(name => {
                                    let value = updated_info[name];

                                    let infoDisplay = editable.querySelector("[data-field = '"+ name +"']");
                                    if(infoDisplay){
                                        infoDisplay.textContent = value;
                                    }
                                });
                            }

                            if(success){
                                overlayLoading.classList.add('hidden');
                                Message.success(message);
                                data.classList.toggle('hidden');
                                editForm.classList.toggle('hidden');

                                editButton.textContent = editButton.textContent == editText ? cancelText : editText;

                                if(action.includes("update-password")){
                                    _this.onPasswordChanged();
                                }
                            } else {
                                overlayLoading.classList.add('hidden');
                                Message.error(message);
                            }

                        }, 'POST', formData, {
                                "X-CSRF-Token": document.querySelector('meta[name="csrfToken"]')?.content
                        }, true, true);
                    });
                }
            });
        }

        const increaseSecurityButton = this.wrapper.querySelector('#increaseSecurityButton');
        const passwordEditButton = this.wrapper.querySelector('#passwordEditButton');

        if(increaseSecurityButton && passwordEditButton){
            increaseSecurityButton.addEventListener('click', () => {
                const editText = passwordEditButton.dataset.editText;
                if(editText && passwordEditButton.textContent == editText) {
                    passwordEditButton.click();
                }
            })
        }
    }

    initAddressAutoComplete() {
        this.address = this.wrapper.querySelector("input[name='winadress']");
        this.address_line_1 = this.wrapper.querySelector("input[name='address[address_line_1]']");
        this.zip = this.wrapper.querySelector("input[name='address[zip]']");
        this.city = this.wrapper.querySelector("input[name='address[city]']");
        this.lat = this.wrapper.querySelector("input[name='address[lat]']");
        this.lng = this.wrapper.querySelector("input[name='address[lng]']");
        this.selectCountry = this.wrapper.querySelector("select[name='address[country_id]']");

        if(this.address){
            const _this = this;
            let autocomplete = new google.maps.places.Autocomplete(this.address);
            google.maps.event.addListener(autocomplete, 'place_changed', function () {
                let place = autocomplete.getPlace();
                _this.city.value = place.vicinity;
                if(_this.city){
                  const componentMap = {
                    locality: 'locality',
                    sublocality_level_1 : 'sublocality_level_1',
                  };

                  let components = place.address_components;
                  for(var i = 0; i < components.length; i++){
                    var types = components[i].types;
                    for(var j = 0; j < types.length; j++){
                      var component_type = types[j];
                      if(componentMap.hasOwnProperty(component_type)){
                        _this.city.value = components[i]['long_name'];
                      }
                    }
                  }
                }

                _this.address_line_1.value = place.name;

                for (let i = 0; i < place.address_components.length; i++) {
                    let addressType = place.address_components[i].types[0];
                    if (addressType == "country") {
                    let isoCode = place.address_components[i].short_name;
                    let country = _this.selectCountry.querySelector("option[data-iso='" + isoCode + "']");
                    if(country){
                        country.selected = true;
                        _this.selectCountry.dispatchEvent(new Event("change"));
                    }
                    }
                    else if(addressType = "postal_code"){
                    _this.zip.value = place.address_components[i].long_name;
                    }
                }

                let lat = place.geometry.location.lat();
                let lng = place.geometry.location.lng();
                _this.lat.value = lat;
                _this.lng.value = lng;
            });
        }
    }

    initPasswordStrengthBar(){
        const passwordStrength = this.wrapper.querySelector('#passwordStrength');
        const newPasswordInput = this.wrapper.querySelector("input[name='new_password']");
        const passwordStrengthInput = this.wrapper.querySelector("input[name='password_strength']");

        if(passwordStrength && newPasswordInput && passwordStrengthInput){
            const passwordStrengthBar = passwordStrength.querySelector('#passwordStrengthBar');
            const passwordStrengthIndicator = passwordStrength.querySelector('#passwordStrengthIndicator');

            newPasswordInput.addEventListener('keyup', () => {
                if(newPasswordInput.value.length > 0){
                    passwordStrength.classList.remove('hidden');
                    const low = passwordStrength.dataset.low;
                    const medium = passwordStrength.dataset.medium;
                    const strong = passwordStrength.dataset.strong;
                    const barBaseClass = "h-3 border-px rounded-xl mb-5 ml-2 mt-1";

                    const strength = this.getPasswordStrength(newPasswordInput.value);
                    passwordStrengthInput.value = strength;

                    switch(strength){
                        case 'low':
                            passwordStrengthBar.classList = barBaseClass + " w-12 bg-me-red";
                            passwordStrengthIndicator.textContent = low;
                            break;
                        case 'medium':
                            passwordStrengthBar.classList = barBaseClass + " w-24 bg-yellow";
                            passwordStrengthIndicator.textContent = medium;
                            break;
                        case 'strong':
                            passwordStrengthBar.classList = barBaseClass + " w-48 bg-green";
                            passwordStrengthIndicator.textContent = strong;
                            break;
                    }
                } else {
                    passwordStrength.classList.add('hidden');
                }
            })
        }
    }

    getPasswordStrength(password){
        return /\d/.test(password) && /\D/.test(password) && password.length > 5 ? (password.length > 8 ? 'strong' : 'medium') : 'low';
    }

    onPasswordChanged(){
        const passwordStrength = this.wrapper.querySelector('#passwordStrength');
        const accountSecurity = this.wrapper.querySelector("#accountSecurity");
        const passwordStrengthInput = this.wrapper.querySelector("input[name='password_strength']");
        const newPasswordInput = this.wrapper.querySelector("input[name='new_password']");
        const confirmNewPasswordInput = this.wrapper.querySelector("input[name='confirm_new_password']");
        const currentPasswordInput = this.wrapper.querySelector("input[name='current_password']");
        if(passwordStrength && accountSecurity && passwordStrengthInput && newPasswordInput && confirmNewPasswordInput && currentPasswordInput) {
            const low = passwordStrength.dataset.low;
            const medium = passwordStrength.dataset.medium;
            const strong = passwordStrength.dataset.strong;
            const accountSecurityBaseClass = "ml-2 font-semibold";

            switch(passwordStrengthInput.value){
                case 'low':
                    accountSecurity.classList = accountSecurityBaseClass + " text-me-red";
                    accountSecurity.textContent = low;
                    break;
                case 'medium':
                    accountSecurity.classList = accountSecurityBaseClass + " text-yellow";
                    accountSecurity.textContent = medium;
                    break;
                case 'strong':
                    accountSecurity.classList = accountSecurityBaseClass + " text-green";
                    accountSecurity.textContent = strong;
                    break;
            }

            newPasswordInput.value = '';
            confirmNewPasswordInput.value = '';
            currentPasswordInput.value = '';
            passwordStrength.classList.add('hidden');
        }
    }

    initNotifications(){
        const _this = this;
        const notificationsWrapper = this.wrapper.querySelector('#notificationsWrapper');

        if(notificationsWrapper){
            const notifications = notificationsWrapper.querySelectorAll('.notification');

            notifications.forEach((notification) => {
                const debounceUpdateNotifications = debounced(800, function (input) {
                    _this.updateNotification(input);
                });

                notification.addEventListener('change', () => {
                    debounceUpdateNotifications(notification);
                });
            });
        }
    }

    updateNotification(input){
      let formData = new FormData();

      if(input.hasAttribute('data-slug')){
        formData.append('slug', input.dataset.slug);
      }
      else{
        const {category, receiver, channel} = input.dataset;

        formData.append('category', category);
        formData.append('receiver', receiver);
        formData.append('channel', channel);
      }

      formData.append('accept', input.checked);

      ajax("/account/update-notifications", (res) => {

      }, 'POST', formData, {
      "X-CSRF-Token": document.querySelector('meta[name="csrfToken"]')?.content
      }, true, true);
    }

    initPrivacy(){
        const forgetRequest =  this.wrapper.querySelector("#forgetRequest");
        const dataRequest =  this.wrapper.querySelector("#dataRequest");
        const cookiesModal = document.querySelector("#cookiesModal");

        if(cookiesModal){
            const form = cookiesModal.querySelector("form");

            form.addEventListener('submit', function(e){
                e.preventDefault();

                let formData = new FormData(this);
                const action = this.action;

                ajax(action, (res) => {
                    const {success, message} = res;

                    if(success){
                        Message.success(message);
                        Modal.closeAllModals();
                    } else {
                        Message.error(message);
                    }
                }, 'POST', formData, {
                        "X-CSRF-Token": document.querySelector('meta[name="csrfToken"]')?.content
                }, true, true);
            });
        }

        if(forgetRequest){
            forgetRequest.addEventListener('click', () => {
                let formData = new FormData();
                formData.append('bookings', 1);
                formData.append('reviews', 1);
                formData.append('terms', 1);

                ajax("/account/forgot-request", (res) => {
                    const {success, message} = res;

                    if(success){
                        Message.success(message);
                    } else {
                        Message.error(message);
                    }
                }, 'POST', formData, {
                        "X-CSRF-Token": document.querySelector('meta[name="csrfToken"]')?.content
                }, true, true);
            });
        }

        if(dataRequest){
            dataRequest.addEventListener('click', () => {
                ajax("/account/personal-data", (res) => {
                    const {success, message} = res;

                    if(success){
                        Message.success(message);
                    } else {
                        Message.error(message);
                    }
                }, 'POST', {}, {
                        "X-CSRF-Token": document.querySelector('meta[name="csrfToken"]')?.content
                }, true, true);
            });
        }
    }

    initPaymentDetails(){
        const _this = this;

        const addPaymentMethodModal = document.getElementById("addPaymentMethodModal");
        const overlayLoading = this.wrapper.querySelector("#overlay-loading");

        if(overlayLoading){
            this.initPaymentMethodDeleteButton(overlayLoading);

            if(addPaymentMethodModal){
                // Prepare the styles for Elements.
                const style = {
                    base: {
                    fontFamily: 'Averta, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif',
                    fontSmoothing: 'antialiased',
                    fontSize: '16px',
                    '::placeholder': {
                        color: '#a0aec0',
                    },
                    },
                };

                // Create a Stripe client.
                const stripe = Stripe(addPaymentMethodModal.dataset.stripePublishKey);

                // Create an instance of Elements.
                const elements = stripe.elements();

                    /**
                 * Implement a Stripe Card Element that matches the look-and-feel of the app.
                 *
                 * This makes it easy to collect debit and credit card payments information.
                 */

                var cardNumber = elements.create('cardNumber', {
                    style: style,
                    showIcon: true
                    // classes: elementClasses,
                });
                cardNumber.mount('#card-number-element');

                var cardExpiry = elements.create('cardExpiry', {
                    style: style,
                    // classes: elementClasses,
                });
                cardExpiry.mount('#card-expiry-element');

                var cardCvc = elements.create('cardCvc', {
                    style: style,
                    // classes: elementClasses,
                });
                cardCvc.mount('#card-cvc-element');


                const addPaymentMethodForm = addPaymentMethodModal.querySelector("#addPaymentMethodForm");
                const cardCvcWrapper = addPaymentMethodModal.querySelector("#card-cvc-element");
                const cardExpiryWrapper = addPaymentMethodModal.querySelector("#card-expiry-element");
                const cardNumberWrapper = addPaymentMethodModal.querySelector("#card-number-element");

                if(addPaymentMethodForm && cardCvcWrapper && cardExpiryWrapper && cardNumberWrapper){

                    addPaymentMethodForm.addEventListener('submit', function(e){
                        e.preventDefault();
                        if(cardCvcWrapper.classList.contains('StripeElement--complete')
                           && cardExpiryWrapper.classList.contains('StripeElement--complete')
                           && cardNumberWrapper.classList.contains('StripeElement--complete')
                        ){
                            //Loading effect
                            Modal.closeAllModals();
                            overlayLoading.classList.remove('hidden');

                            const action = this.action;

                            stripe.createPaymentMethod({
                                type:"card",
                                card: cardNumber
                            }).then(({paymentMethod}) => {
                                let formData = new FormData();
                                formData.append("payment_method_id", paymentMethod.id);

                                ajax(action, (res) => {
                                    const {success, message} = res;
                                    if(success){
                                        _this.resetPaymentMethods(overlayLoading, message)
                                    } else {
                                        overlayLoading.classList.add('hidden');
                                        Message.error(message);
                                    }
                                }, 'POST', formData, {
                                        "X-CSRF-Token": document.querySelector('meta[name="csrfToken"]')?.content
                                }, true, true);
                            })
                        }
                    });
                }
            }
        }
    }

    initPaymentMethodDeleteButton(overlayLoading){
        const paymentMethods = document.getElementById("paymentMethods");

        if(paymentMethods){
            const deleteMethodButtons = paymentMethods.querySelectorAll('.deleteMethodButton');
            if(deleteMethodButtons){
                deleteMethodButtons.forEach((deleteButton) => {
                    let formData = new FormData();
                    formData.append("payment_method_id", deleteButton.dataset.paymentMethodId);

                    deleteButton.addEventListener('click', () => {
                        overlayLoading.classList.remove('hidden');

                        ajax("/account/delete-payment-method", (res) => {
                            const {success, message} = res;

                            if(success){
                                this.resetPaymentMethods(overlayLoading, message)
                            } else {
                                overlayLoading.classList.add('hidden');
                                Message.error(message);
                            }
                        }, 'POST', formData, {
                                "X-CSRF-Token": document.querySelector('meta[name="csrfToken"]')?.content
                        }, true, true);
                    });
                });
            }
        }
    }

    resetPaymentMethods(overlayLoading, message) {
        const _this = this;

        ajax("/account/get-payment-methods", (res) => {
            const paymentMethods = _this.wrapper.querySelector('#paymentMethods');
            paymentMethods.innerHTML = res;

            const allDropdowns = paymentMethods.querySelectorAll('.dropdown');
            if (allDropdowns.length > 0) {
                allDropdowns.forEach(function (dropdown) {
                const buttons = dropdown.querySelectorAll('button[data-toggle="dropdown"]');
                if (buttons) {
                    buttons.forEach(function (button) {
                    button.addEventListener('click', function () {
                        allDropdowns.forEach(function (otherDropdown) {
                        if (dropdown !== otherDropdown) {
                            otherDropdown.classList.remove('active');
                        }
                        });
                        dropdown.classList.toggle('active');
                    });
                    });
                }
                });
            }

            overlayLoading.classList.add('hidden');

            Message.success(message);

            _this.initPaymentMethodDeleteButton(overlayLoading);
        }, 'GET', {}, {
                "X-CSRF-Token": document.querySelector('meta[name="csrfToken"]')?.content
        }, true, false);
    }

    initGiftCardRedeem(){
        const redeemGiftCardForm = document.getElementById("redeemGiftCardForm");
        const overlayLoading = this.wrapper.querySelector("#overlay-loading");
        const giftAmountDisplay = this.wrapper.querySelector("#giftAmount");

        if(redeemGiftCardForm && overlayLoading && giftAmountDisplay){
            redeemGiftCardForm.addEventListener('submit', function(e) {
                e.preventDefault();
                overlayLoading.classList.remove('hidden');
                Modal.closeAllModals();

                const action = this.action;
                let formData = new FormData(this);

                this.reset();

                ajax(action, (res) => {
                    const {success, message, giftCardAmount} = res;
                    if(success){
                        const newAmount = giftCardAmount + parseInt(giftAmountDisplay.dataset.amount);
                        giftAmountDisplay.innerHTML =  newAmount + '€'
                        Message.success(message);
                    } else {
                        Message.error(message);
                    }

                    overlayLoading.classList.add('hidden');
                }, 'POST', formData, {
                        "X-CSRF-Token": document.querySelector('meta[name="csrfToken"]')?.content
                }, true, true);
            });
        }
    }

    initPromoCodeRedeem(){
        const redeemCouponForm = document.getElementById("redeemCouponForm");
        const overlayLoading = this.wrapper.querySelector("#overlay-loading");
        const couponCountDisplay = this.wrapper.querySelector("#couponCount");
        const discounts = this.wrapper.querySelector("#discounts");

        if(redeemCouponForm && overlayLoading){
            redeemCouponForm.addEventListener('submit', function(e) {
                e.preventDefault();
                overlayLoading.classList.remove('hidden');
                Modal.closeAllModals();

                const action = this.action;
                let formData = new FormData(this);

                this.reset();

                ajax(action, (res) => {
                    const {success, message, code} = res;
                    console.log('res', res);
                    if(success){
                        couponCountDisplay.innerHTML = parseInt(couponCountDisplay.dataset.couponCount) + 1;

                        const neverUsed = discounts.dataset.neverUsed;
                        const saved = discounts.dataset.saved;

                        discounts.innerHTML +=
                            `<div class="border-b border-dashed border-gray-400 text-gray-400 flex flex-wrap py-2 mb-5">
                                <div class="flex flex-wrap w-full">
                                    <p class="bold mb-5 text-blue">
                                        ${code}
                                    </p>

                                    <p class="text-blue ml-auto">
                                        0€${saved}
                                    </p>
                                </div>

                                <p class="text-sm">
                                        ${neverUsed}
                                </p>
                            </div>`
                        Message.success(message);
                    } else {
                        Message.error(message);
                    }

                    overlayLoading.classList.add('hidden');
                }, 'POST', formData, {
                        "X-CSRF-Token": document.querySelector('meta[name="csrfToken"]')?.content
                }, true, true);
            });
        }
    }
}

export default UserProfile;
