const initSortReviews = () => {
  const sort = document.getElementById("sort-reviews");
  const reviewsContainer = document.getElementById("reviewsContainer");
  if(sort && reviewsContainer){
    sort.addEventListener("change", function(){
      let toSort = Array.prototype.slice.call(reviewsContainer.children, 0);
      if(this.value === "rated"){
        toSort.sort(sortByBestRated);
      }
      else if(this.value === "recent"){
        toSort.sort(sortByMostRecent);
      }

      reviewsContainer.innerHTML = "";
      for(var i = 0, l = toSort.length; i < l; i++) {
        reviewsContainer.appendChild(toSort[i]);
      }
    });
  }

  const sortByBestRated = (a, b) => {
    return b.dataset.note - a.dataset.note;
  }

  const sortByMostRecent = (a, b) => {
    return b.dataset.date - a.dataset.date;
  }
}

export { initSortReviews }
