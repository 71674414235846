import { ajax, debounced } from "../utils";
import { initCheckoutCode } from "./ApplyCheckoutCode";

class ExtraCheckout {
  constructor(){
    this.wrapper = document.getElementById('checkout-cart');
    if(this.wrapper){
      this.initInitialAddButtons();

      this.extrasInputs = [];
      this.initChooseQuantityGroups();

      this.continueToPaymentButtons = this.wrapper.querySelectorAll('.continue-to-payment');
      this.confirmExtrasButton = this.wrapper.querySelector('.confirm-extras');
      this.extras = {};
      this.retailCartWrapper = this.wrapper.querySelector('#retail-cart-top-part');
      this.debouncedAddExtras = debounced(750, () => {
        this.addExtras();
      });

      //include the departure point choosing if the experience is a guided tour
      this.initChooseDeparturePoint();
    }
  }

  initInitialAddButtons(){
    const initialButtons = this.wrapper.querySelectorAll('.initial-add');
    initialButtons.forEach(button => {
      const plusInput = this.wrapper.querySelector(button.dataset.forPlus);
      const plusInputWrapper = plusInput.closest('.choose-qty-group');
      if(plusInput && plusInputWrapper){
        button.addEventListener('click', () => {
          button.remove();

          plusInput.dispatchEvent(new Event('click'));
          plusInputWrapper.classList.remove('hidden');
        });
      }
    });
  }

  /**
   * Initializes the choose quantity groups.
   *
   * @return {void}
   */
  initChooseQuantityGroups(){
    this.chooseQtyGroups = this.wrapper.querySelectorAll('.choose-qty-group');
    if(this.chooseQtyGroups){
      this.chooseQtyGroups.forEach(group => {
        const minus = group.querySelector('.minus');
        const plus = group.querySelector('.plus');
        const qty = group.querySelector('.qty');

        if(minus && plus && qty){
          //push the qty input into the extrasInputs array so we can check if there are extras selected in updateConfirmExtrasButtonText()
          this.extrasInputs.push(qty);

          minus.addEventListener('click', () => {
            if(Number(qty.value) > Number(qty.min)){
              if(Number(qty.value) > Number(qty.dataset.min)){
                qty.step = 1;
              }

              qty.stepDown();

              if(qty.value == 0){
                qty.step = qty.dataset.min;
              }

              if(qty.value == Number(qty.dataset.min) - 1){
                qty.value = 0;
                qty.step = qty.dataset.min;
              }

              //manually trigger the change event to update the displayed value since stepDown && stepUp don't do it
              qty.dispatchEvent(new Event('change'));

              this.extras[group.dataset.extraId] = qty.value;

              this.toggleDisableContinueToPaymentButtons(true);
              this.debouncedAddExtras();
              this.updateConfirmExtrasButtonText();
            }
          });

          plus.addEventListener('click', () => {
            if(Number(qty.value) < Number(qty.max)){
              if(Number(qty.value) === Number(qty.dataset.min)){
                qty.step = 1;
              }

              qty.stepUp();

              if(Number(qty.value) !== Number(qty.dataset.min)){
                qty.step = 1;
              }

              //manually trigger the change event to update the displayed value since stepDown && stepUp don't do it
              qty.dispatchEvent(new Event('change'));

              this.extras[group.dataset.extraId] = qty.value;

              this.toggleDisableContinueToPaymentButtons(true);
              this.debouncedAddExtras();
              this.updateConfirmExtrasButtonText();
            }
          });
        }
      });
    }
  }

  /**
   * Adds extras to the form data, toggles the disable state of the continue to payment buttons,
   * sends an AJAX request to add extras to the retail cart, and updates the retail cart wrapper
   * with the received HTML.
   *
   * @param {type} paramName - description of parameter
   * @return {type} description of return value
   */
  addExtras(){
    const formData = new FormData();
    formData.append('extras', JSON.stringify(this.extras));

    this.retailCartWrapper.classList.add('loading-spinner');
    ajax(this.wrapper.dataset.addExtrasUrl, (html) => {
      this.retailCartWrapper.innerHTML = html;
      this.retailCartWrapper.classList.remove('loading-spinner');

      this.toggleDisableContinueToPaymentButtons(false);

      //reapply the logic to apply/remove a discount/gc code
      initCheckoutCode();
    }, 'POST', formData,
    { "X-CSRF-Token": document.querySelector('meta[name="csrfToken"]')?.content }, true, false);
  }

  /**
   * Toggles the disabled state of the continue to payment buttons.
   *
   * No parameters.
   *
   * No return value.
   */
  toggleDisableContinueToPaymentButtons(disabled){
    this.continueToPaymentButtons.forEach(button => {
      button.disabled = disabled;
    });

    //in case it was a departure point extra, remove the mobile info text saying to choose a departure point
    if(this.addDeparturePointMobileNotice){
      this.addDeparturePointMobileNotice.classList.add('hidden');
    }
  }

/**
 * Update the text of the confirm extras button depending whether or not there are extras in the cart.
 */
  updateConfirmExtrasButtonText(){
    if(this.confirmExtrasButton){
      let extrasAdded = false;

      //check in this.extrasInputs if there are extras in the cart
      this.extrasInputs.forEach(input => {
          if(input.value > 0){
            extrasAdded = true;
            return;
          }
      });

      this.confirmExtrasButton.textContent = extrasAdded ? this.confirmExtrasButton.dataset.extrasAddedText
                                                         : this.confirmExtrasButton.dataset.noExtrasText;
    }
  }

  initChooseDeparturePoint(){
    this.addDeparturePointMobileNotice = this.wrapper.querySelector('#add-departure-point-mobile-notice');
    this.departurePointChoices = this.wrapper.querySelectorAll("input[name='departure_point']");
    if(this.departurePointChoices){
      this.departurePointChoices.forEach(choice => {
        choice.addEventListener('change', () => {
          if(choice.checked){
            //x = decided on the backend for safety
            this.extras[choice.dataset.extraId] = 'x';

            //remove any other departure points
            const uncheckedDeparturePoints = this.wrapper.querySelectorAll("input[name='departure_point']:not(:checked)");
            uncheckedDeparturePoints.forEach(uncheckedDP => {
              this.extras[uncheckedDP.dataset.extraId] = 0;
            });
          }

          this.toggleDisableContinueToPaymentButtons(true);
          this.debouncedAddExtras();
        });
      });
    }
  }
}

export { ExtraCheckout }
