import { initMaplibre } from "../../modules/frontv2/Maplibre";
import { ajax } from "../utils";
import { buildBookingAutoIcon, mapTilesStyle, removeMarkerHighlight } from './map-utils';
import Glide, { Breakpoints, Controls, Swipe } from "@glidejs/glide/dist/glide.modular.esm";
import ArrowDisabler from "./ArrowDisabler";

export default class WishlistMap {
    constructor() {
      initMaplibre();
      this.wishlist = document.querySelector('#wishlist-show');
      if (this.wishlist) {
        this.importMaplibregl();
        this.maplibregl;
      }
    }

    importMaplibregl(){
      import('maplibre-gl').then(maplibregl => {
        this.maplibregl = maplibregl;
        this.initMap();
      });
    }

    initMap(){
      this.mapContainer = this.wishlist.querySelector('#map-container');
      this.triggerManualSearchButton = this.mapContainer.querySelector('#triggerSearch');

      this.zoomInButton = this.mapContainer.querySelector('#zoomIn');
      this.zoomOutButton = this.mapContainer.querySelector('#zoomOut');

      this.hostsIds = this.getHostsIds();
      this.experiencesIds = this.getExperiencesIds();

      this.getExperiencesAndHostsInfos();

      this.currencyInfos = { //all currency infos needed to build prices on list/popups
          locale: document.body.dataset.currencyLocale.replace("_", "-"),
          rate: document.body.dataset.currencyRate,
          iso: document.body.dataset.currencyIso
      };

      this.map = new this.maplibregl.Map({
          accessToken: 'pk.eyJ1Ijoid2luYWxpc3QiLCJhIjoiY2p0azd3b2Q5MTQzMjN5bzZidmU5cDF6biJ9.65pAPF4tbn5Geqg7JhvCFQ',
          name: 'Winalist',
          container: 'map-container',
          style: mapTilesStyle,
          zoom: 3,
          center: [9.548222, 47.164712], // center of Europe, lichtenstein
      });

      this.map.scrollZoom.disable();

      this.bindZoomButtons();

      this.map.on('click', () => {
          removeMarkerHighlight();
      });
    }

    getExperiencesAndHostsInfos(){
      // ajax to get hosts and experiences infos
      let formData = new FormData();
      formData.append('hostsIds', JSON.stringify(this.hostsIds));
      formData.append('experiencesIds', JSON.stringify(this.experiencesIds));

      ajax(this.wishlist.dataset.urlMap,
          (data) => {
              this.hosts = data.hosts;
              this.experiences = data.experiences;

              this.updateMarkersOnMap();
          },
          'POST',
          formData,
          {
              "X-CSRF-Token": document.querySelector('meta[name="csrfToken"]')?.content,
          },
          true,
          true,
      );
    }

    updateMarkersOnMap(){
      // ? Experiences and Hosts with same coordinates
      const result = this.groupByExperiencesAndHostsByCoordinates();
      result.forEach((obj) => {
          let popupOptions = {
              offset: 30,
              closeButton: false
          };
          let popup = new this.maplibregl.Popup(popupOptions).setHTML(this.getPopupMultipleElements(obj.items));
          popup.on("open", () => {
              this.initializeGlider();
          });

          let marker = document.createElement('div');
          marker.className = 'marker cluster-markers';
          marker.textContent = obj.items.length;

          new this.maplibregl.Marker({element: marker})
              .setLngLat([obj.coordinates[1], obj.coordinates[0]])
              .setPopup(popup)
              .addTo(this.map);
      });

      this.hosts.forEach((host) => {
          // if l'host est pas dans le result
          if(result.find((obj) => obj.items.find((item) => item.id === host.id && item.type === 'host'))) return;

          let marker = document.createElement('div');
          marker.className = 'marker single-marker';

          if(host.currency_iso != this.currencyInfos.iso)
            var amount = Math.ceil(host.lowest_experience_price_in_euros * this.currencyInfos.rate);
          else
            var amount = host.lowest_experience_price_in_euros * this.currencyInfos.rate;

          //format the host lowest price with the current currency
          let formattedPrice = new Intl.NumberFormat(this.currencyInfos.locale, {
              style: 'currency',
              currency: this.currencyInfos.iso,
              minimumFractionDigits: 0
          }).format(amount);
          marker.textContent = formattedPrice;

          if (host.can_book_auto) { //if host has an exp that has booking auto mode, add the booking auto icon
              let icon = buildBookingAutoIcon();
              marker.appendChild(icon);
          }

          marker.addEventListener('click', () => {
              //setTimeout is used to make sure this is done after map click event (which removes highlighted markers)
              setTimeout( () => {
                  marker.classList.toggle("highlighted");
              }, 100);
          });

          let popupOptions = {
              offset: 30,
              closeButton: false
          };
          let popup = new this.maplibregl.Popup(popupOptions).setHTML(this.getHostPopup(host));

          //when the popup opens, scroll to the host card on the list
          popup.on("open", () => {
              const cardOnList = this.wishlist.querySelector(`[data-type="Host"][data-id="${host.id}"]`);
              if(cardOnList){
                  const alreadyHighlightedCard = this.wishlist.querySelector('.highlighted');
                  if(alreadyHighlightedCard) alreadyHighlightedCard.classList.remove('highlighted');

                  let blockOption = 'center';
                  if(cardOnList.nextElementSibling === null) blockOption = 'end';
                  cardOnList.scrollIntoView({ behavior: 'smooth', block: blockOption});

                  cardOnList.classList.add('highlighted');
              }
          });


          new this.maplibregl.Marker({element: marker})
              .setLngLat([host.address.lng, host.address.lat])
              .setPopup(popup)
              .addTo(this.map);
      });

      this.experiences.forEach((experience) => {
          // if l'experience est pas dans le result
          if(result.find((obj) => obj.items.find((item) => item.id === experience.id && item.type === 'experience'))) return;

          let marker = document.createElement('div');
          marker.className = 'marker single-marker';

          if(experience.host.currency_iso != this.currencyInfos.iso)
              var amount = Math.ceil(experience.price_in_euros * this.currencyInfos.rate);
          else
              var amount = experience.price_in_euros * this.currencyInfos.rate;

          //format the experience price with the current currency
          let formattedPrice = new Intl.NumberFormat(this.currencyInfos.locale, { style: 'currency', currency: this.currencyInfos.iso, minimumFractionDigits: 0 }).format(amount);
          marker.textContent = formattedPrice;
          if (experience.booking_auto) { //if experience is in booking_auto mode, add the booking auto icon
              let icon = buildBookingAutoIcon();
              marker.appendChild(icon);
          }

          marker.addEventListener('click', () => {
              //setTimeout is used to make sure this is done after map click event (which removes highlighted markers)
              setTimeout( () => {
                  marker.classList.toggle("highlighted");
              }, 100);
          });

          let popupOptions = {
              offset: 30,
              closeButton: false
          };
          let popup = new this.maplibregl.Popup(popupOptions).setHTML(this.getExperiencePopup(experience));

          //when the popup opens, scroll to the host card on the list
          popup.on("open", () => {
              const cardOnList = this.wishlist.querySelector(`[data-type="Experience"][data-id="${experience.id}"]`);
              if(cardOnList){
                  const alreadyHighlightedCard = this.wishlist.querySelector('.highlighted');
                  if(alreadyHighlightedCard) alreadyHighlightedCard.classList.remove('highlighted');

                  let blockOption = 'center';
                  if(cardOnList.nextElementSibling === null) blockOption = 'end';
                  cardOnList.scrollIntoView({ behavior: 'smooth', block: blockOption});

                  cardOnList.classList.add('highlighted');
              }
          });


          new this.maplibregl.Marker({element: marker})
              .setLngLat([experience.address.lng, experience.address.lat])
              .setPopup(popup)
              .addTo(this.map);
      });
    }

    getHostPopup(host){
        let priceFormat;
        // let rawPriceFormat;
        if(host.lowest_experience_price_in_euros > 0){
            if(host.currency_iso != this.currencyInfos.iso)
                var amount = Math.ceil(host.lowest_experience_price_in_euros * this.currencyInfos.rate);
            else
                var amount = host.lowest_experience_price_in_euros * this.currencyInfos.rate;

            let price = new Intl.NumberFormat(this.currencyInfos.locale, { style: 'currency', currency: this.currencyInfos.iso }).format(amount);
            priceFormat = this.wishlist.dataset.from.replace("{0}", " <span class='text-me-red md:text-black md:font-semibold'>" + price + "</span> ");
        } else {
            priceFormat = "<span class='text-me-red md:text-black md:font-semibold'>" + this.wishlist.dataset.free + "</span>";
        }

        let html = `<article class="card-host on-popup bg-transparent max-w-xs overflow-hidden text-left" data-host-id="${host.id}">
        <header class="h-48 mb-3">
            <a href="${host.url}" class="block h-full w-full bg-transparent rounded overflow-hidden" target="_blank">
                <img class="twic w-full h-full object-cover"
                    src="${host.media_cdn_url}/resize=2p"
                    data-src="${host.media_cdn_path}"
                    data-src-transform="cover">
            </a>
            <div class="p-4 w-full whitespace-normal flex justify-center" style="transform: translateY(-50%);">
                <img class="object-contain bg-white rounded-full w-12 h-12 mr-2 border border-champagne twic"
                        style="outline: 4px solid white;"
                        src="${host.brand_cdn_url}/cover=600x420"
                        alt="${host.name}"
                        data-src="${host.brand_cdn_path}"
                        data-src-transform="contain">
            </div>
        </header>
        <div class="p-4 pt-6">
        <a href="${host.url}" class="host-click-from-map font-semibold text-xl mt-1 stretched-link text-black visible focus:outline-none"
            target="_blank"
            title="${host.name}">${host.name}</a>
        <p class="lg:font-normal text-gray-500 my-2">${priceFormat}</p>`;

        if(host.average_review > 0 || host.can_book_auto){
            html += `<div class="flex flex-wrap items-center mt-2 justify-end">`;
            if(host.average_review > 0){
                html += `<div class="rounded border uppercase text-xs px-2 py-1 mr-2 mt-1">
                            <i class="icon icon-star-alt text-yellow -mt-1"></i>
                            <span class="font-semibold">${host.average_review}</span>
                            <span class="text-gray-500"> (${host.count_review})</span>
                        </div>`;
            }
            if(host.can_book_auto){
                html += `<div class="rounded border uppercase text-xs px-2 py-1 mt-1 font-semibold ">
                                <i class="icon icon-bolt text-me-red -mt-1"></i>
                                ${this.wishlist.dataset.bookingAuto}
                            </div>`;
            }

            html += `</div>`;
        }

        html += `</div>
        </article>`;

        return html;
    }

    getExperiencesIds() {
        let experiencesIds = [];
        let articles = document.querySelectorAll('article');
        articles.forEach((article) => {
            if (article.dataset.type === "Experience") experiencesIds.push(parseInt(article.dataset.id));
        });

        return experiencesIds;
    }

    getHostsIds() {
        let hostsIds = [];
        let articles = document.querySelectorAll('article');
        articles.forEach((article) => {
            if (article.dataset.type === "Host") hostsIds.push(parseInt(article.dataset.id));
        });

        return hostsIds;
    }

    bindZoomButtons(){
        if(this.map && this.zoomInButton && this.zoomOutButton){
            this.zoomInButton.addEventListener('click', () => {
                this.map.zoomIn();
            });
            this.zoomOutButton.addEventListener('click', () => {
                this.map.zoomOut();
            });
        }
    }

    getExperiencePopup(experience) {
        let components = this.getExperienceComponents(experience);

        if (experience.activity_level_one.name) {
            var activityLevel = `<span class="badge badge-champagnelight text-gray-700 mr-2">${experience.activity_level_one.name}</span>`;
        }

        let html = `
            <div class="flex flex-col rounded popup">
                <header class="mb-1 h-48">
                    <a href="${experience.url}" class="block h-full w-full bg-transparent rounded overflow-hidden" target="_blank">
                        <img class="twic h-full w-full object-cover" alt="${experience.name}" draggable="false" src="${experience.cover_cdn_url}/resize=2p" data-src="${experience.cover_cdn_path}"/>
                    </a>
                </header>
                <div class="p-4 pt-8 relative bg-white">
                    <div class="flex flex-wrap items-center mb-2">
                        ${activityLevel ?? ''}
                        ${components.reviews}
                        <span class="text-xs text-gray-500 ${experience.average_review ? 'ml-3' : ''}"><i class="icon icon-time"></i> ${experience.duration_format}</span>
                    </div>
                    <a href="${experience.url}" class="mt-1 font-semibold text-base text-black hover:no-underline">${experience.name}</a>
                    <p class="mt-2 text-sm">${components.fromTrad}</p>
                    <a href="${experience.host.url}" class="text-gray-700 text-xs relative z-50">${components.byTrad}</a>
                    ${components.booking_auto}
                </div>
            </div>
        `;

        return html;
    }

    getExperienceComponents(experience) {
        let price = new Intl.NumberFormat(this.currencyInfos.locale, { style: 'currency', currency: this.currencyInfos.iso }).format(experience.ticket.adult_price_with_tax * this.currencyInfos.rate);

        let fromTrad = this.wishlist ? this.wishlist.dataset.from.replace("{0}", price) : '';
        let byTrad = this.wishlist ? this.wishlist.dataset.by.replace("{0}", experience.host.name) : '';

        let booking_auto = ``;
        if (experience.booking_auto) {
            booking_auto =
                `
                <div class="bg-blue absolute right-0 bottom-0 mr-2 mb-2 flex justify-center items-center w-5 h-5 rounded-full">
                <i class="icon icon-bolt text-white text-xs"></i>
                </div>
            `;
        }

        let reviews = ``;
        if (experience.count_review > 0) {
            reviews = `
                <div class="star-rating mr-1" title="${experience.average_review}">
                    <div class="back-stars">
                        <i class="icon icon-star-alt text-gray-300" aria-hidden="true"></i>
                        <i class="icon icon-star-alt text-gray-300" aria-hidden="true"></i>
                        <i class="icon icon-star-alt text-gray-300" aria-hidden="true"></i>
                        <i class="icon icon-star-alt text-gray-300" aria-hidden="true"></i>
                        <i class="icon icon-star-alt text-gray-300" aria-hidden="true"></i>
                        <div class="front-stars" style="width: ${experience.average_review * 20}%">
                            <i class="icon icon-star-alt text-yellow" aria-hidden="true"></i>
                            <i class="icon icon-star-alt text-yellow" aria-hidden="true"></i>
                            <i class="icon icon-star-alt text-yellow" aria-hidden="true"></i>
                            <i class="icon icon-star-alt text-yellow" aria-hidden="true"></i>
                            <i class="icon icon-star-alt text-yellow" aria-hidden="true"></i>
                        </div>
                    </div>
                </div>
                <span class="text-gray-500 text-xs mr-2">(${experience.count_review})</span>
            `;
        }

        return {
            price: price,
            fromTrad: fromTrad,
            byTrad: byTrad,
            booking_auto: booking_auto,
            reviews: reviews
        };
    }

    groupByExperiencesAndHostsByCoordinates() {
        const mergedArray = [
            ...this.experiences.map((experience) => ({ ...experience, type: 'experience' })),
            ...this.hosts.map((host) => ({ ...host, type: 'host' }))
        ];

        const mergedArrayByCoordinates = this.groupBy(mergedArray, (item) => [item.address.lat, item.address.lng]);
        return mergedArrayByCoordinates.filter((group) => group.length > 1).map((group) => {
            const items = group.map((item) => ({ id: item.id, type: item.type, entity: item }));
            return {
                coordinates: [group[0].address.lat, group[0].address.lng],
                items
            };
        });
    }

    groupBy(array, f) {
        let groups = {};
        array.forEach(function (o) {
            let group = JSON.stringify(f(o));
            groups[group] = groups[group] || [];
            groups[group].push(o);
        });
        return Object.keys(groups).map(function (group) {
            return groups[group];
        });
    };

    getPopupMultipleElements(items) {
        let html = `
            <div class="glide arrows_in glide-popup opacity-0" >
                <div class="glide__track pl-0" data-glide-el="track">
                    <ul class="glide__slides">
        `;

        items.forEach((item, index) => {
            html += `<li class="glide__slide" data-index=${index}>`;
            if (item.type === "host") {
                html += this.getHostPopup(item.entity);
            } else {
                html += this.getExperiencePopup(item.entity);
            }
            html += `</li>`;
        });

        html += `
                    </ul>
                </div>
                <div class="glide__arrows" data-glide-el="controls">
                    <button class="glide__arrow glide__arrow--left text-black bg-white rounded-full border w-10 h-10 text-center shadow-regular" data-glide-dir="<" aria-label="Go back to previous pictures" title="Go back to previous pictures"><i class="icon icon-caret-left"></i></button>
                    <button class="glide__arrow glide__arrow--right text-black bg-white rounded-full border w-10 h-10 text-center shadow-regular" data-glide-dir=">" aria-label="Go to next pictures" title="Go to next pictures"><i class="icon icon-caret-right"></i></button>
                </div>
            </div>
            <div class="gallery flex ${items.length > 6 ? 'flex-wrap' : ''} bg-white">
        `;

        let width = "16.6%";
        if(items.length <= 6){
            width = 100 / items.length + "%";
        }

        items.forEach((item, index) => {
            html += `
                <button class="galleryItem p-2 outline-none border-t-2 ${index == 0 ? 'active' : ''} flex justify-center bg-white" style="width:${width}" data-index="${index}">
                    <img class="h-10 w-10 rounded-lg object-cover" alt="${item.entity.name}" draggable="false" src="${item.type === "host" ? item.entity.media_cdn_url : item.entity.cover_cdn_url }/cover=70x70"/>
                </button>
            `;
        });

        html += `
            </div>
        `;

        return html;
    }

    initializeGlider() {
        const glideEl = document.querySelector(".glide-popup");
        if (glideEl) {
            let glide = new Glide(glideEl, {
                perView: 1,
                type: 'carousel',
                rewind: false,
                bound: true,
            });

            glide.on('build.after', () => {
                if (glideEl.classList.contains("opacity-0")) {
                    glideEl.classList.toggle("opacity-0");
                    glideEl.classList.toggle("opacity-100");
                }

                const galleryItems = document.querySelectorAll(".galleryItem");
                if(galleryItems){
                    galleryItems.forEach(function(item){
                        item.addEventListener("click", function(){
                        glide.go("=" + item.dataset.index);
                        });
                    })
                }
            });

            glide.on("run.after", function () {
                let currentActiveExp = document.querySelector(".galleryItem.active");
                if (currentActiveExp) {
                    currentActiveExp.classList.toggle("active");
                }

                let glideIndex = glide.index;
                let gallery = document.querySelectorAll(".gallery .galleryItem");
                if (gallery) {
                    gallery[glideIndex].classList.toggle("active");
                }
            });

            if (glide && !glideEl.classList.contains("glide--carousel")) {
                glide.mount({ Controls, Breakpoints, Swipe, ArrowDisabler });
            }
        }
    };
}
