const initHorizontalAutoscroll = () => {
  const scrollableContainers = document.querySelectorAll('.horizontal-autoscroll');
  if(scrollableContainers){
    scrollableContainers.forEach(function(scrollableContainer){
      const scrollWidth = scrollableContainer.scrollWidth;

      window.setInterval(() => {
        if (scrollableContainer.scrollLeft !== scrollWidth) {
          scrollableContainer.scrollTo(scrollableContainer.scrollLeft + 1, 0);
        }
      }, 15);
    });
  }
}

export { initHorizontalAutoscroll }

