import { mapTilesStyle } from "../map-utils";
import Modal from "../Modal";
import maplibregl from 'maplibre-gl';

class DeparturePoints {
  constructor(){
    this.wrapper = document.querySelector('.departure-points-wrapper');
    if(this.wrapper){
      this.departurePointInputs = this.wrapper.querySelectorAll("input[name='departure_point']");
      this.departurePointAddresses = this.wrapper.querySelectorAll(".departure-point-address");
      if(this.departurePointInputs && this.departurePointAddresses){
        //init map in the modal
        this.initMap();

        //trigger the modal, and move the map to the clicked address
        this.handleClickOnAddress();

        //submit form when a choice is made by the user (in case of payment link)
        this.submitFormOnChoice();
      }
    }
  }

  initMap(){
    this.map = new maplibregl.Map({
      container: 'departurePointMap',
      style: mapTilesStyle,
      zoom: 15,
      center: [this.departurePointAddresses[0].dataset.lng, this.departurePointAddresses[0].dataset.lat],
    });

    this.map.addControl(new maplibregl.NavigationControl());
    this.map.addControl(new maplibregl.FullscreenControl());

    this.departurePointAddresses.forEach(address => {
      address.addEventListener('click', () =>  {
        this.map.flyTo({
          center: [address.dataset.lng, address.dataset.lat],
          zoom: 15,
          speed: 1.7
        });
      });

      const popup = new maplibregl.Popup({ closeOnClick: false, closeButton: false, offset: 15 })
        .setHTML(`<span class="font-body">${address.dataset.text}</span>`)
        .addTo(this.map);

        let div = document.createElement('div');
        div.className = 'marker single-marker';

        const marker = new maplibregl.Marker({
          element: div
        })
        .setLngLat([address.dataset.lng, address.dataset.lat])
        .setPopup(popup)
        .addTo(this.map);

        marker.togglePopup();
    });
  }

  handleClickOnAddress(){
    this.departurePointAddresses.forEach(address => {
      address.addEventListener('click', () => {
        Modal.toggleModal('#departurePointMapModal');
      });
    });
  }

  submitFormOnChoice(){
    const form = this.wrapper.querySelector('form');
    if(form && form.classList.contains('submit-on-choice')){
      this.departurePointInputs.forEach(input => {
        input.addEventListener('change', () => {
          form.submit();

          this.departurePointInputs.forEach(innerInput => {
            if(input.id != innerInput.id){
              innerInput.disabled = true;
            }
          });
        });
      });
    }
  }
}

export default DeparturePoints;
