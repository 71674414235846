const initCountDown = (id, now, endDateTime) => {
    const countDown = document.getElementById(id);

    if(countDown) {
      let nowParsed = Date.parse(now);
      const t = getTimeRemaining(nowParsed, endDateTime);

      const days = countDown.querySelector(".days");
      const hours = countDown.querySelector(".hours");
      const minutes = countDown.querySelector(".minutes");
      const seconds = countDown.querySelector(".seconds");

      if(days) days.innerHTML = t.days;
      if(hours) hours.innerHTML = t.hours;
      if(minutes) minutes.innerHTML = t.minutes;
      if(seconds) seconds.innerHTML = t.seconds;

      if (t.total <= 0 && !countDown.classList.contains('keep-visible-when-zero')) {
          countDown.classList.add('hidden');
          clearInterval(window.countdownInterval);
      }
      else {
          countDown.classList.add('block');
          countDown.classList.remove('hidden');
      }

      if(window.countdownInterval){
        clearInterval(window.countdownInterval);
      }

      window.countdownInterval = setInterval(() => {
        nowParsed = nowParsed + 1000;
        const t = getTimeRemaining(nowParsed, endDateTime);

        const days = countDown.querySelector(".days");
        const hours = countDown.querySelector(".hours");
        const minutes = countDown.querySelector(".minutes");
        const seconds = countDown.querySelector(".seconds");

        if(days) days.innerHTML = t.days;
        if(hours) hours.innerHTML = t.hours;
        if(minutes) minutes.innerHTML = t.minutes;
        if(seconds) seconds.innerHTML = t.seconds;

        if (t.total <= 0) {
          if(!countDown.classList.contains('keep-visible-when-zero')){
            countDown.classList.add('hidden');
          }

          clearInterval(window.countdownInterval);
        }

      }, 1000);
    }

    function getTimeRemaining(nowParsed, endDateTime){
      let total = Date.parse(endDateTime) - nowParsed;
      let seconds = Math.floor( (total/1000) % 60 );
      let minutes = Math.floor( (total/1000/60) % 60 );
      let hours = Math.floor( (total/(1000*60*60)) % 24 );
      let days = Math.floor( total/(1000*60*60*24) );

      if(seconds >= 0 && seconds < 10){
        seconds = '0' + seconds;
      }

      if(minutes >= 0 && minutes < 10){
        minutes = '0' + minutes;
      }

      if(total <= 0){
          const sessionExpiredForm = document.querySelector('#refresh-cart-form form');
          if(sessionExpiredForm) sessionExpiredForm.submit();
      }

      seconds = seconds < 0 ? '00' : seconds;
      minutes = minutes < 0 ? '00' : minutes;
      hours = hours < 0 ? '00' : hours;
      days = days < 0 ? '00' : days;

      return {
          total,
          days,
          hours,
          minutes,
          seconds
      };
    }
}

export { initCountDown }
