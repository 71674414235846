import Glide, { Breakpoints, Controls, Swipe } from "@glidejs/glide/dist/glide.modular.esm";
import ArrowDisabler from "./ArrowDisabler";
import { onVisible } from "../utils";
import 'maplibre-gl/dist/maplibre-gl.css';
import { mapTilesStyle } from "./map-utils";
import HostsAround from "./HostsAround/HostsAround";

var currencyInfos = {
  locale: document.body.dataset.currencyLocale.replace("_", "-"),
  rate: document.body.dataset.currencyRate,
  iso: document.body.dataset.currencyIso
};

const initMaplibre = () => {
  const groupBy = (array, f) => {
    var groups = {};
    array.forEach(function (o) {
      var group = JSON.stringify(f(o));
      groups[group] = groups[group] || [];
      groups[group].push(o);
    });
    return Object.keys(groups).map(function (group) {
      return groups[group];
    })
  }

  const getExperienceComponents = (experience) => {
    let price = new Intl.NumberFormat(currencyInfos.locale, { style: 'currency', currency: currencyInfos.iso }).format(experience.ticket.adult_price_with_tax * currencyInfos.rate);

    var trads = document.getElementById("trads");
    let fromTrad = trads ? trads.dataset.from.replace("{0}", price) : '';
    let byTrad = trads ? trads.dataset.by.replace("{0}", experience.host.name) : '';
    // let onMobile = window.matchMedia("(max-width: 768px)").matches;

    let booking_auto = ``;
    if (experience.instant) {
      booking_auto =
        `
        <div class="bg-blue absolute right-0 bottom-0 mr-2 mb-2 flex justify-center items-center w-5 h-5 rounded-full">
          <i class="icon icon-bolt text-white text-xs"></i>
        </div>
      `;
    }

    let reviews = ``;
    if (experience.count_review > 0) {
      reviews = `
      <div class="star-rating mr-1" title="${experience.average_review}">
        <div class="back-stars">
            <i class="icon icon-star-alt text-gray-300" aria-hidden="true"></i>
            <i class="icon icon-star-alt text-gray-300" aria-hidden="true"></i>
            <i class="icon icon-star-alt text-gray-300" aria-hidden="true"></i>
            <i class="icon icon-star-alt text-gray-300" aria-hidden="true"></i>
            <i class="icon icon-star-alt text-gray-300" aria-hidden="true"></i>
            <div class="front-stars" style="width: ${experience.average_review * 20}%">
                <i class="icon icon-star-alt text-yellow" aria-hidden="true"></i>
                <i class="icon icon-star-alt text-yellow" aria-hidden="true"></i>
                <i class="icon icon-star-alt text-yellow" aria-hidden="true"></i>
                <i class="icon icon-star-alt text-yellow" aria-hidden="true"></i>
                <i class="icon icon-star-alt text-yellow" aria-hidden="true"></i>
            </div>
        </div>
      </div>
      <span class="text-gray-500 text-xs mr-2">(${experience.count_review})</span>
      `;
    }

    return {
      price: price,
      fromTrad: fromTrad,
      byTrad: byTrad,
      booking_auto: booking_auto,
      reviews: reviews
    };
  }

  const getPopupHTML = (experiences) => {
    let html = "";
    if (experiences.length > 1) {
      html = `
      <div class="glide arrows_in glide-popup opacity-0" >
          <div class="glide__track" data-glide-el="track">
              <ul class="glide__slides">
      `;

      for (let i = 0; i < experiences.length; i++) {
        let experience = experiences[i];

        html += getPopupComponent(experience, true, i);
      }

      html += `
          </ul>
        </div>
        <div class="glide__arrows" data-glide-el="controls">
            <button class="glide__arrow glide__arrow--left text-black bg-white rounded-full border w-10 h-10 text-center" data-glide-dir="<" aria-label="Go back to previous pictures" title="Go back to previous pictures"><i class="icon icon-caret-left"></i></button>
            <button class="glide__arrow glide__arrow--right text-black bg-white rounded-full border w-10 h-10 text-center" data-glide-dir=">" aria-label="Go to next pictures" title="Go to next pictures"><i class="icon icon-caret-right"></i></button>
        </div>
      </div>
      <div class="gallery flex ${experiences.length > 6 ? 'flex-wrap' : ''} bg-white">
      `;

      let width = "16.6%";
      if(experiences.length <= 6){
        width = 100 / experiences.length + "%";
      }
      for (let i = 0; i < experiences.length; i++) {
        html += `
          <button class="galleryItem p-2 outline-none border-t-2 ${i == 0 ? 'active' : ''} flex justify-center bg-white" style="width:${width}" data-index="${i}">
            <img class="h-10 w-10 rounded-lg object-cover" alt="${experiences[i].name}" draggable="false" src="${experiences[i].cover_cdn_url}/cover=70x70"/>
          </button>
        `;
      }

      html += `
      </div>
      `;

    }
    else {
      let experience = experiences[0];
      html += getPopupComponent(experience, false, 0);
    }

    return html;
  }

  const getPopupComponent = (experience, isSlider, index) => {
    let components = getExperienceComponents(experience, currencyInfos);

    let html = isSlider ? `<li class="glide__slide" data-index=${index}>` : ``;
    html += `
      <div class="flex flex-col rounded popup">
        <a href="${experience.url}" class="block h-32 w-full bg-transparent rounded-t overflow-hidden">
          <img class="h-full w-full object-cover" alt="${experience.name}" draggable="false" src="${experience.cover_cdn_url}/cover=600x420"/>
        </a>
        <div class="p-4 relative bg-white">
          <div class="flex flex-wrap items-center mb-2">
            <span class="badge badge-champagnelight text-gray-700 mr-2">${experience.activity_level_one.name}</span>
            ${components.reviews}
            <span class="text-xs text-gray-500 ${experience.average_review ? 'ml-3' : ''}"><i class="icon icon-time"></i> ${experience.duration_format}</span>
          </div>
          <a href="${experience.url}" class="mt-1 font-semibold text-base text-black hover:no-underline">${experience.name}</a>
          <p class="mt-2 text-sm">${components.fromTrad}</p>
          <a href="${experience.host.url}" class="text-gray-700 text-xs relative z-50">${components.byTrad}</a>
          ${components.booking_auto}
        </div>
      </div>
    `;

    if (isSlider) {
      html += `</li>`;
    }

    return html;
  }

  var currentGlide;
  const initializeGlider = () => {
    const glideEl = document.querySelector(".glide-popup");
    if (glideEl) {
      let glide = new Glide(glideEl, {
        perView: 1,
        type: 'carousel',
        rewind: false,
        bound: true,
      });

      glide.on('build.after', () => {
        if (glideEl.classList.contains("opacity-0")) {
          glideEl.classList.toggle("opacity-0");
          glideEl.classList.toggle("opacity-100");
        }

        const galleryItems = document.querySelectorAll(".galleryItem");
        if(galleryItems){
          galleryItems.forEach(function(item){
            item.addEventListener("click", function(){
              glide.go("=" + item.dataset.index);
            });
          })
        }
      });

      glide.on("run.after", function () {
        let currentActiveExp = document.querySelector(".galleryItem.active");
        if (currentActiveExp) {
          currentActiveExp.classList.toggle("active");
        }

        let glideIndex = glide.index;
        let gallery = document.querySelectorAll(".gallery .galleryItem");
        if (gallery) {
          gallery[glideIndex].classList.toggle("active");
        }
      });

      if (glide && !glideEl.classList.contains("glide--carousel")) {
        glide.mount({ Controls, Breakpoints, Swipe, ArrowDisabler });
        currentGlide = glide;
      }
    }
  };

  const initMap = () => {
    const maps = document.querySelectorAll('div[data-map]');
    if (maps) {
      // const mapStyle = 'alidade_smooth'; // You can change this to other available styles

      maps.forEach(function (div) {
        // const staticImage = div.querySelector('img');
        // const lng = div.dataset.lng;
        // const lat = div.dataset.lat;
        // const zoom = div.dataset.zoom ? div.dataset.zoom : 13;
        // const encodedMarkerURI = encodeURIComponent('https://media.winalist.com/prod/img/assets/mapbox-icon.png?twic=v1/cover=75');
        // let wxhStaticImage = '1280x720';
        // if(div.classList.contains('fit-image')){
        //   wxhStaticImage = (div.offsetWidth < 1280 ? div.offsetWidth : '1280') + 'x' + (div.offsetHeight < 720 ? div.offsetHeight : '720');
        // }

        //load img src from Stadia Maps static api
        const handler = onVisible(div, function() {
          // if(staticImage){
          //   staticImage.src = `https://tiles.stadiamaps.com/static/${mapStyle}.png?center=${lat},${lng}&zoom=${zoom}&markers=${lat},${lng},custom:${encodedMarkerURI}&size=${wxhStaticImage}@2x`;

            //then load maplibre lib and the dynamic map if the static map is clicked
            // div.addEventListener('click', () => {
              //we use dynamic import
              if(!div.classList.contains('binded-map')){
                if(typeof window.maplibregl === 'undefined'){
                  import('maplibre-gl').then(maplibregl => {
                    initLogic(div, maplibregl);
                  });
                }
                else{
                  initLogic(div, window.maplibregl);
                }
              }
            // });
          // }
        });

        window.addEventListener('DOMContentLoaded', handler, false);
        window.addEventListener('load', handler, false);
        window.addEventListener('scroll', handler, false);
        window.addEventListener('resize', handler, false);
      });
    }
  }

  const initLogic = (div, maplibregl) => {
    initMapLogic(div, maplibregl);

    if(document.getElementById('host-map')){
      //init the hosts around modal (host page)
      //this is a map with pins of other hosts around the current host, and searchable input / movable map
      new HostsAround(maplibregl);
    }
  }

  const initMapLogic = (div, maplibregl) => {
    // staticImage.classList.add('hidden');
    if(!div.classList.contains('binded-map')){
      const map = new maplibregl.Map({
        // accessToken: 'pk.eyJ1Ijoid2luYWxpc3QiLCJhIjoiY2p0azd3b2Q5MTQzMjN5bzZidmU5cDF6biJ9.65pAPF4tbn5Geqg7JhvCFQ',
        container: div,
        style: mapTilesStyle,
        zoom: div.dataset.zoom ? div.dataset.zoom : 13,
        center: [div.dataset.lng, div.dataset.lat],
        doubleClickZoom: false
      });

      if(!div.hasAttribute('data-hide-navigation-controls')){
        map.addControl(new maplibregl.NavigationControl({
          showCompass: false
        }));
      }

      map.scrollZoom.disable();
      map.touchZoomRotate.disable();

      map.on("dragstart", event => {
        const isTouchScreenDevice =
          event.originalEvent && "touches" in event.originalEvent;
        if (isTouchScreenDevice) {
          const isTwoFingerTouch = event.originalEvent.touches.length >= 2;
          if (!isTwoFingerTouch) {
            map.dragPan.disable();
          }
        }
      });

      map.on("touchstart", event => {
        const isTouchScreenDevice =
          event.originalEvent && "touches" in event.originalEvent;
        if (isTouchScreenDevice) {
          const isTwoFingerTouch = event.originalEvent.touches.length >= 2;
          if (isTwoFingerTouch) {
            map.dragPan.enable();
          }
        }
      });

      const elMarker = document.createElement('div');
      elMarker.className = 'pin-marker cursor-pointer';

      // if there are multiple markers to set (like in a case of a guided tours with multiple departure points)
      if(div.hasAttribute('data-lat-lng-points') && div.hasAttribute('data-content')){
        const points = JSON.parse(div.dataset.latLngPoints);

        points.forEach(function(point){
          let marker = new maplibregl.Marker({element: elMarker})
            .setLngLat([point.lng, point.lat])
            .addTo(map);

          let popup = new maplibregl.Popup({ closeOnClick: false, closeButton: false, offset: 30 })
            .setHTML('<p class="text-sm p-4 font-semibold">'+ div.dataset.content +'</p>')
            .setLngLat([point.lng, point.lat])
            .addTo(map);

            marker.setPopup(popup);
        });
      }

      // make a marker for each feature and add to the map
      let marker = new maplibregl.Marker({element: elMarker})
        .setLngLat([div.dataset.lng, div.dataset.lat])
        .addTo(map);

      if(div.dataset.content){
        let popup = new maplibregl.Popup({ closeOnClick: false, closeButton: false, offset: 30 })
          .setHTML('<p class="text-base p-4 font-semibold">'+ div.dataset.content +'</p>')
          .setLngLat([div.dataset.lng, div.dataset.lat])
          .addTo(map);

          marker.setPopup(popup);
      }
      else{
        let experiences = document.querySelector("input[name='experiences']");
        if(experiences){
          experiences = JSON.parse(experiences.value);
          if(experiences != null){
            if(div.dataset.setPopup){
              let experiencesSortedByGeoLoc = groupBy(experiences, function (experience) {
                if (experience.address.lat && experience.address.lng) {
                  return [experience.address.lat, experience.address.lng];
                }
              });

              experiencesSortedByGeoLoc.forEach(function(experiences){
                let popupOptions = {
                  offset: 30,
                  closeButton: false
                };
                let popup = new maplibregl.Popup(popupOptions).setHTML(getPopupHTML(experiences));
                popup.on("open", function () {
                  if (experiences.length > 1) {
                    initializeGlider();
                  }
                });

                const elMarkerTemp = document.createElement('div');
                elMarkerTemp.className = 'pin-marker';

                new maplibregl.Marker({element: elMarkerTemp})
                .setLngLat([experiences[0].address.lng, experiences[0].address.lat])
                .setPopup(popup)
                .addTo(map);
              });
            }
            else{
              experiences.forEach(function(experience){
                const elMarkerTemp = document.createElement('div');
                elMarkerTemp.className = 'pin-marker';

                new maplibregl.Marker({element: elMarkerTemp})
                .setLngLat([experience.address.lng, experience.address.lat])
                .addTo(map);
              });
            }
          }
        }
      }

      div.classList.add('binded-map');
    }
  }

  if(document.querySelectorAll('div[data-map]').length > 0 && document.body.dataset.isBot === 'false'){
    initMap();
  }
}


export { initMaplibre }
