import Message from "../Message";
import { ajax } from "../utils";

const initCheckoutCode = () => {
  const applyCodeWrappers = document.querySelectorAll('.apply-code-wrapper');
  if(applyCodeWrappers){
    applyCodeWrappers.forEach((wrapper) => {
      const applyCodeButton = wrapper.querySelector('.checkout-apply-code');
      const inputCode = wrapper.querySelector('.checkout-input-code');
      if(applyCodeButton && inputCode){
        applyCodeButton.addEventListener('click', (e) => {
          e.stopPropagation();

          if(inputCode.value === '') return;

          applyCodeButton.disabled = true;
          applyCodeButton.classList.add('loading');

          //logic to remove a discount / gc code
          if(applyCodeButton.classList.contains('remove-code')){
            let formData = new FormData();
            if(applyCodeButton.hasAttribute('data-booking-reference')){
              formData.append('reference', applyCodeButton.dataset.bookingReference);
            }

            ajax(applyCodeButton.dataset.removeUrl, (data) => {
              //then reload the page if it's a success
              if(data.success){
                window.location.reload();
              }
              else{
                //otherwise, show a message error
                Message.error(data.message);

                applyCodeButton.disabled = false;
                applyCodeButton.classList.remove('loading');
              }
            }, 'POST', formData, { "X-CSRF-Token": document.querySelector('meta[name="csrfToken"]')?.content },
              true, true);
          }
          //logic to apply a discount / gc code
          else{
            //ajax request to apply discount/gc ID (IF VALID) to the current cart
            let formData = new FormData();
            formData.append('code', inputCode.value);
            if(applyCodeButton.hasAttribute('data-booking-reference')){
              formData.append('reference', applyCodeButton.dataset.bookingReference);
            }

            ajax(applyCodeButton.dataset.applyUrl, (data) => {
              //then reload the page if it's a success
              if(data.success){
                window.location.reload();
              }
              else{
                //otherwise, show a message error
                Message.error(data.message);
              }

              applyCodeButton.disabled = false;
              applyCodeButton.classList.remove('loading');
            }, 'POST', formData, { "X-CSRF-Token": document.querySelector('meta[name="csrfToken"]')?.content },
              true, true);
          }
        });

        inputCode.addEventListener('keypress', (e) => {
          if(e.key === 'Enter' || e.keyCode === 13){
            applyCodeButton.click();
          }
        });
      }
    });
  }
}

/**
 * Init the apply existing GC buttons
 * to apply a redeemed gift card to the current cart
 */
const initApplyExistingGC = () => {
  const applyCodeContainers = document.querySelectorAll('.apply-code-container');
  if(applyCodeContainers){
    applyCodeContainers.forEach((container) => {
      const useExistingGCButtons = container.querySelectorAll('.use-existing-gc');
      const checkoutCodeInput = container.querySelector('.checkout-input-code');
      const checkoutApplyCodeButton = container.querySelector('.checkout-apply-code');
      if(useExistingGCButtons && checkoutCodeInput && checkoutApplyCodeButton){
        useExistingGCButtons.forEach((button) => {
          button.addEventListener('click', (e) => {
            checkoutCodeInput.value = button.dataset.code;
            checkoutApplyCodeButton.click();

            button.disabled = true;
          });
        });
      }
    });
  }
}

export { initCheckoutCode, initApplyExistingGC }
