import Message from "../Message";
import { ajax } from "../utils";

class TicketDigitValidation{
  constructor(){
    this.wrapper = document.querySelector('[data-digit-validation]');
    if(this.wrapper){
      const _this = this;

      this.form = this.wrapper.querySelector('form');
      this.submitButton = this.wrapper.querySelector('[type=submit]');
      this.toHideOnSuccess = this.wrapper.querySelectorAll('.to-hide-on-success');
      this.successDiv = this.wrapper.querySelector('#success');
      if(this.form && this.submitButton && this.successDiv){
        this.form.addEventListener('submit', (e) => _this.performValidation(e));
      }

      this.digitInputs = this.wrapper.querySelectorAll('.digit');
      this.digits = [];

      if(this.digitInputs){
        this.digitInputs.forEach(input => {
          this.digits[input.dataset.digit] = input;

          input.addEventListener('keyup', (e) => _this.onDigitKeyup(e, input));
          input.addEventListener('keydown', _this.preventNonDigit);
        });
      }

      this.pinLost = this.wrapper.querySelector('#pin-lost');
      this.bookingRefInput = this.wrapper.querySelector("input[name='booking_reference']");
      this.loader = this.pinLost.querySelector('#loader');
      this.pinText = this.pinLost.querySelector('#inner-text');
      if(this.pinLost && this.bookingRefInput && this.loader && this.pinText){
        this.pinLost.addEventListener('click', () => {
          this.sendForgotMail();
        });
      }
    }
  }

  onDigitKeyup(e, input){
    //Only digits regex
    let regex = /^\d+$/;

    if(e.key.match(regex) || e.key === 'Backspace'){
      //Focus the next digit
      if(input.value != '' && this.digits[input.dataset.nextDigit])
        this.digits[input.dataset.nextDigit].focus();

      if(input.value === '' && this.digits[input.dataset.previousDigit]){
        this.digits[input.dataset.previousDigit].focus();
      }

      //Perform validation if all digits are filled and we're on the last one
      if(input.hasAttribute('data-last-digit')){
        //Check if all digits are filled
        let allInputsOk = true;
        this.digits.forEach((digitInput) => {
          if(digitInput.value === ''){
            allInputsOk = false;
            return;
          }
        });

        if(allInputsOk){
          this.form.dispatchEvent(new Event('submit', { cancelable: true }));
        }
      }
    }
  }

  //Prevent any non digit character
  preventNonDigit(e){
    let regex = /^\d+$/;
    if(!e.key.match(regex)){
      e.preventDefault();
    }

    if(e.key.match(regex) || e.key === 'Backspace'){
      e.target.value = '';
    }
  }

  performValidation(e){
    const _this = this;

    //prevent form from submitting since we when to do it in ajax
    e.preventDefault();
    this.submitButton.disabled = true;

    const formData = new FormData(this.form);

    ajax(this.form.action, function (data) {
      if(data.success){
        _this.toHideOnSuccess.forEach(elt => {
          elt.classList.add('hidden');
        });
        _this.successDiv.classList.remove('hidden');
      }
      else {
        Message.error(data.message);
        _this.submitButton.disabled = false;
      }
    },
    'POST', formData, {
            "X-CSRF-Token": document.querySelector('meta[name="csrfToken"]')?.content
    }, true, true);
  }

  sendForgotMail(){
    const _this = this;

    if(this.pinLost.dataset.disabled != 'true'){
      this.pinLost.dataset.disabled = 'true';
      this.pinText.classList.add('hidden');
      this.loader.classList.remove('hidden');

      const formData = new FormData();
      formData.append('booking', this.bookingRefInput.value);

      ajax(this.pinLost.dataset.url, function (data) {
        if(data.success){
          Message.success(_this.pinLost.dataset.successMessage);
        }
        else {
          Message.error(_this.pinLost.dataset.errorMessage);
        }

        _this.pinText.classList.remove('hidden');
        _this.loader.classList.add('hidden');

        _this.pinLost.dataset.disabled = 'false';
      },
      'POST', formData, {
              "X-CSRF-Token": document.querySelector('meta[name="csrfToken"]')?.content
      }, true, true);
    }
  }

}

export default TicketDigitValidation;
