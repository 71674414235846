import intlTelInput from 'intl-tel-input';

const initPhoneValidation = () => {
    function phoneValidation(phoneInput, phoneError, errorMap, iti){
      const phoneErrorMessage = document.getElementById('phone-error-message');

      if (phoneInput.value == '') {
          phoneError.innerHTML = '';
          phoneInput.classList.remove("error");
      }
      else if (phoneInput.value.trim()) {
        if (iti.isValidNumber()) {
          phoneError.innerHTML = '';
          phoneInput.classList.remove("error", 'is-invalid');
          phoneInput.classList.add("intl-success");
          phoneInput.value = iti.getNumber();

          if(phoneErrorMessage){
            phoneErrorMessage.classList.add('hidden');
          }
        } else {
          phoneInput.classList.add("error", 'is-invalid');
          phoneInput.classList.remove("intl-success");
          var errorCode = iti.getValidationError();
          phoneError.innerHTML = errorMap[errorCode];

          if(phoneErrorMessage){
            phoneErrorMessage.classList.remove('hidden');
          }
        }
      }

      // if you are using Melchior input
      const melchiorInput = phoneInput.closest('.melchior-input');
      if (!melchiorInput) return;

      if (phoneInput.value.trim() !== "") {
        if (!iti.isValidNumber()) {
          phoneInput.classList.remove("error", 'is-invalid');
          melchiorInput.classList.add("text-me-red");
        }
      }
    }

    const phoneInputs = document.querySelectorAll(".phone-js:not(.phone-js-binded)");
    if (phoneInputs.length) {
        for (var k = 0; k < phoneInputs.length; k++) {
            let phoneInput = phoneInputs[k];
            let errorMap = winalist_vars.error_messages.phoneInput;
            let iti = intlTelInput(phoneInput, {
                preferredCountries: ['fr', 'us', 'ca'],
                utilsScript: '//cdnjs.cloudflare.com/ajax/libs/intl-tel-input/15.0.0/js/utils.js',
                initialCountry: 'fr'
            });
            phoneInput.classList.add('phone-js-binded');

            let phoneError = document.createElement('div');
            phoneError.classList.add('invalid-feedback');
            phoneInput.parentElement.appendChild(phoneError);
            // phoneInput.parentNode.insertBefore(phoneError, phoneInput.nextSibling);

            phoneInput.addEventListener('blur', function() {
                phoneValidation(phoneInput, phoneError, errorMap, iti);
            });

            // when page is loaded and the input is not empty
            if (phoneInput.value.trim()) {
                iti.promise.then(() => {
                  phoneValidation(phoneInput, phoneError, errorMap, iti);
                })
            }

            let debounce;
            phoneInput.addEventListener('keyup', () => {
                // clear style errors
                let melchiorInput = phoneInput.closest('.melchior-input');
                if (melchiorInput) melchiorInput.classList.remove("text-me-red");

                clearTimeout(debounce);
                debounce = setTimeout(() => {
                  phoneValidation(phoneInput, phoneError, errorMap, iti)
                }, 900);
            })
        }
    }
}

export {initPhoneValidation};

