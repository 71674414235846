const initNps = () => {
  const wrapper = document.getElementById("nps-page");
  if (wrapper) {
    const onScoreClick = (npsScoreButtons, button, event) => {
        npsScoreButtons.forEach((nestedButton) => {
          if (Number(nestedButton.dataset.note) <= Number(button.dataset.note)) {
              nestedButton.style.backgroundColor = button.dataset.color;
              nestedButton.classList.add('color-filled');
          } else {
              nestedButton.style.backgroundColor = "white";
              nestedButton.classList.remove('color-filled');
          }
        });

        if (event === "click") {
          const activeButton = document.querySelector(".nps-score.active");
          if (activeButton) {
              activeButton.classList.remove("active");
          }

          button.classList.add("active");
        }
    }

    const npsScoreButtons = document.querySelectorAll(".nps-score");
    const npsInput = document.querySelector("input[name='nps']");
    if(npsInput && npsScoreButtons){
      npsScoreButtons.forEach((button) => {
        ["mouseenter", "click"].forEach((event) => {
          button.addEventListener(event, () => {
              onScoreClick(npsScoreButtons, button, event);

              if(event === 'click'){
                npsInput.value = button.dataset.note;
              }
          });

          button.addEventListener("mouseleave", () => {
              const activeButton = document.querySelector(".nps-score.active");
              if (activeButton) {
                  onScoreClick(npsScoreButtons, activeButton, 'mouseleave');
              }
          });
        });
      });

      const activeButtonAtPageLoad = document.querySelector(".nps-score.active");
      if (activeButtonAtPageLoad) {
        onScoreClick(npsScoreButtons, activeButtonAtPageLoad, 'click');
      }
    }
  }
};

export { initNps };
