import { ajax } from "../utils";
import Message from "../Message";
import flatpickr from "flatpickr";
import flatpickrLanguages from "flatpickr/dist/l10n";
import { initCopyLinkToClipboard } from "../common/init-copy-link-to-clipboard";

export default class Wishlist {
    constructor() {
        let wishlistsPage = document.querySelector('#wishlists');
        if (wishlistsPage) {
            this.initWishlistsPage();
        }

        this.wishlist = document.querySelector('#wishlist-show');
        if (this.wishlist) {
            this.initInWishlist();
        }
    }

    initInWishlist() {
        // Save wishlist name
        let buttonEditWishlistName = document.querySelector('#edit-wishlist-name');
        if (buttonEditWishlistName) {
            buttonEditWishlistName.addEventListener('click', () => {
                this.saveWishlistName();
            });
        }

        this.initBackPage();

        this.initPeopleAvatars();

        this.eventChangePermission();

        // Copy link to share wishlist
        initCopyLinkToClipboard();

        this.initDeleteWishlist();

        this.initDeleteUserFromWishlist();

        // Filters
        this.filter = {
            date: null,
            adults: null,
            children: null,
            experienceIds: null,
        }
        this.initDate();
        this.initPersonsActiveFilter();

        this.initDisplayMap();
    }

    initBackPage() {
        let backPage = document.querySelector('#back-page');
        if (backPage) {
            backPage.addEventListener('click', () => {
                window.history.back();
            });
        }
    }

    initDate() {
        flatpickr.localize(flatpickrLanguages[document.body.dataset.lang.substr(0, 2)]);
    
        this.flatpickrInstance = null;
        this.inlineDate = this.wishlist.querySelector("#inlineDate");
    
        //init flatpickr when clicking on the filter that displays the calendar
        //otherwise, the init doesn't work correctly since the calendar is not visible
        let defaultDate = new Date();
        if(this.wishlist.dataset.date && this.wishlist.dataset.date !== 'false'){
            defaultDate = new Date(this.wishlist.dataset.date);
        }
        if (this.inlineDate) {
            this.dateWrapper = this.inlineDate.parentElement;
    
            this.initFlatpickrInstance(defaultDate);
            this.flatpickrInitedAtClick = false;
            this.inlineDate.addEventListener("click", () => {
                if(!this.flatpickrInitedAtClick){
                    this.initFlatpickrInstance(defaultDate);
                    this.flatpickrInitedAtClick = true;
                }
            });
        }
    }

    initFlatpickrInstance(defaultDate){
        const _this = this;

        const flatpickrElt = _this.wishlist.querySelector(".flatpickr");
        if (flatpickrElt) {
            let minDate = new Date();
            minDate.setHours(0, 0, 0);

            _this.flatpickrInstance = flatpickr(flatpickrElt, {
                inline: true,
                minDate: minDate,
                dateFormat: "Y-m-d",
                defaultDate: defaultDate,
                showMonths: flatpickrElt.dataset.showMonths,
                onChange: function (selectedDates) {
                    if (selectedDates.length && selectedDates[0]) {
                        _this.currentDateSelected = selectedDates[0];

                        const locale = document.body.dataset.lang.replace("_", "-");
                        const options = { year: 'numeric', month: 'short', day: 'numeric' };
                        const date = new Date(selectedDates[0]).toLocaleDateString(locale, options);

                        let daysOfWeek = JSON.stringify([selectedDates[0].getDay()]);
                        _this.dateWrapper.dataset.activeDays = daysOfWeek;

                        if(_this.inlineDate){
                            _this.inlineDate.textContent = date;
                            _this.inlineDate.classList.add("active");
                        }

                        // convert date to iso format
                        _this.filter.date = selectedDates[0].toISOString().split('T')[0];
                        _this.requestFilter();
                    } else {
                        _this.filter.date = null;
                    }
                },
                onReady: function (a, b, f) {
                    if (_this.wishlist.hasAttribute('data-date') && _this.wishlist.dataset.date !== 'false'){
                        f.setDate(f.config.defaultDate, true);
                    }

                    _this.saveDate = _this.wishlist.querySelector('#save-date');
                    if(_this.saveDate){
                        _this.saveDate.addEventListener('click', () => {
                            _this.saveDateParent = _this.saveDate.closest('.dropdown');
                            if(_this.saveDateParent){
                                _this.saveDateParent.classList.remove('active');
                            }
                        });
                    }

                    _this.clearDate = _this.wishlist.querySelector('#clear-date');
                    if(_this.clearDate){
                        _this.clearDate.addEventListener('click', () => {
                            f.clear();

                            if(_this.inlineDate){
                                _this.inlineDate.textContent = _this.inlineDate.dataset.initialText;
                                _this.inlineDate.classList.remove("active");
                            }
    
                            delete _this.dateWrapper.dataset.activeDays;
    
                            _this.clearDateParent = _this.clearDate.closest('.dropdown');
                            if(_this.clearDateParent){
                                _this.clearDateParent.classList.remove('active');
                            }
    
                            _this.currentDateSelected = null;
                            const dropdownFilters = _this.wishlist.querySelectorAll('.dropdown-filter.z-50');
                            if(dropdownFilters) dropdownFilters.forEach(dropdownFilter => dropdownFilter.classList.remove('z-50'));

                            if (_this.filter.adults === null && _this.filter.children === null) {
                                _this.clearTextAvailabilities();
                            }

                            _this.filter.date = null;
                            _this.requestFilter();
                        });

                    }
                }
            });
        }
    }

    requestFilter(){
        // experienceIds array
        this.filter.experienceIds = this.getExperiencesIds();

        if ((
            this.filter.date === null 
                && this.filter.adults === null 
                && this.filter.children === null
            ) || this.filter.experienceIds.length <= 0) return this.clearTextAvailabilities();
        
        // Send ajax request to get availability for the selected filters
        const formData = new FormData();
        formData.append('filter', JSON.stringify(this.filter));

        this.toggleOverlayLoader();

        ajax(this.wishlist.dataset.urlFilter,
            (data) => {
                this.initAvailabilityExperiences(data);
                this.initAvailabilityHosts(data);

                this.toggleOverlayLoader();
            },
            'POST',
            formData,
            {
                "X-CSRF-Token": document.querySelector('meta[name="csrfToken"]')?.content,
            },
            true,
            true,
        );
    }

    initAvailabilityExperiences(data) {
        let articles = document.querySelectorAll('article[data-type="Experience"]');
        articles.forEach((article) => {
            let divs = article.querySelectorAll('#available-experience');
            divs.forEach((div) => {
                if (data.experiences_available.includes(parseInt(article.dataset.id))) {
                    div.classList.add('available');
                    div.classList.remove('hidden');
                    div.classList.remove('not-available');

                    div.innerHTML = div.dataset.available;
                } else {
                    div.classList.remove('available');
                    div.classList.remove('hidden');
                    div.classList.add('not-available');

                    div.innerHTML = div.dataset.notAvailable;
                }
            });
        });
    }

    initAvailabilityHosts(data) {
        let articles = document.querySelectorAll('article[data-type="Host"]');
        articles.forEach((article) => {
            let divs = article.querySelectorAll('#available-host');
            divs.forEach((div) => {
                const found = data.experiences_available.some(r => article.dataset.experiencesId.includes(r));
                if (found) {
                    div.classList.add('available');
                    div.classList.remove('hidden');
                    div.classList.remove('not-available');

                    div.innerHTML = div.dataset.available;
                } else {
                    div.classList.remove('available');
                    div.classList.remove('hidden');
                    div.classList.add('not-available');

                    div.innerHTML = div.dataset.notAvailable;
                }
            });
        });
    }

    clearTextAvailabilities() {
        // hide all available text
        let divs = document.querySelectorAll('[id^=available-]');
        divs.forEach((div) => {
            div.classList.add('hidden');
        });
    }

    getExperiencesIds() {
        let experienceIds = [];
        let articles = document.querySelectorAll('article');
        articles.forEach((article) => {
            if (article.dataset.type === "Host") experienceIds = [...experienceIds, ...JSON.parse(article.dataset.experiencesId)]
            if (article.dataset.type === "Experience") experienceIds.push(parseInt(article.dataset.id));
        });

        // remove duplicates item in array
        return [...new Set(experienceIds)];
    }

    initPersonsActiveFilter(){
        this.adults = 0;
        this.children = 0;
        this.adultsInput = this.wishlist.querySelector('#number-adults');
        this.childrenInput = this.wishlist.querySelector('#number-children');

        let debounceTimeout;

        this.personsText = this.wishlist.querySelector('#inlinePersons');
        this.personsInput = this.wishlist.querySelectorAll('.personsInput');
        if(this.personsInput){
            this.personsInput.forEach(input => {
                input.addEventListener('change', () => { 
                    clearTimeout(debounceTimeout);

                    debounceTimeout = setTimeout(() => {
                        if(this.personsText){
                            let text = "";
                            if (this.adultsInput.value > 0) {
                                let adultsTrad = this.personsText.dataset.adults.replace("{0}", this.adultsInput.value);
                                let adultTrad = this.personsText.dataset.adult;
                                text += this.adultsInput.value > 1 ? adultsTrad : adultTrad;
                            }

                            if (this.childrenInput.value > 0) {
                                if (this.adultsInput.value > 0) {
                                    text += ", ";
                                }
                                let childrenTrad = this.personsText.dataset.children.replace("{0}", this.childrenInput.value);
                                let childTrad = this.personsText.dataset.child;
                                text += this.childrenInput.value > 1 ? childrenTrad : childTrad;
                            }

                            if (this.adultsInput.value == 0 && this.childrenInput.value == 0) {
                                text = this.personsText.dataset.noValue;
                                this.personsText.classList.remove("active");
                            } else {
                                this.personsText.classList.add("active");
                            }

                            this.personsText.textContent = text;
                        }

                        if (this.adultsInput.value != 0 || this.childrenInput.value != 0) {
                            this.filter.adults = this.adultsInput.value;
                            this.filter.children = this.childrenInput.value;
                            this.requestFilter();
                        } else {
                            this.filter.adults = null;
                            this.filter.children = null;
                            this.requestFilter();
                            if (this.filter.date === null) {
                                this.clearTextAvailabilities();
                            }
                        }
                    }, 500);
                });

                input.dispatchEvent(new Event('change'));
            });

            let saveVisitor = this.wishlist.querySelector('#save-visitor');
            if(saveVisitor){
                saveVisitor.addEventListener('click', () => {
                    let saveVisitorParent = saveVisitor.closest('.dropdown');
                    if(saveVisitorParent) {
                        saveVisitorParent.classList.remove('active');
                    }
                });
            }
        }
    }

    toggleOverlayLoader() {
        const filterOverlay = document.querySelector(".filter-overlay");
        if (filterOverlay) filterOverlay.classList.toggle("hidden");
    }

    initWishlistsPage() {
        this.initCreateWishlist();
        this.initDeleteWishlists();
        this.initEditWishlistName();
    }

    initCreateWishlist() {
        let buttonCreateWishlist = document.querySelector('#add-wishlist');
        if (buttonCreateWishlist) {
            buttonCreateWishlist.addEventListener('click', () => {
                this.createWishlist();
            });

            let input = document.querySelector('input[name="wishlistName"]');
            input.addEventListener('keydown', (event) => {
                if (event.key === 'Enter') {
                    event.preventDefault();
                    buttonCreateWishlist.click();
                }
            });
        }
    }

    createWishlist() {
        let input = document.querySelector('input[name="wishlistName"]');
        if (!input || input.value.length <= 0) {
            let invalidInput = document.querySelector('#invalid-input');
            invalidInput.classList.remove('hidden');
    
            setTimeout(() => {
                invalidInput.classList.add('hidden');
            }, 2000);
    
            return; 
        }

        document.querySelector('#addWishlistModal').querySelector('.modal-close').click();
        document.querySelector('#invalid-input').classList.add('hidden');

        let formData = new FormData();
        formData.append('name', input.value);

        ajax(input.dataset.url,
            (data) => {
                if (data.success) {
                    Message.success(input.dataset.success);
                    window.location.reload();
                }
            },
            'POST',
            formData,
            {
                "X-CSRF-Token": document.querySelector('meta[name="csrfToken"]')?.content,
            },
            true,
            true,
        );
    }

    initDeleteWishlists() {
        let buttonsDeleteWishlist = document.querySelectorAll('[data-target="#deleteWishlistModal"]');
        if (!buttonsDeleteWishlist) return;
        buttonsDeleteWishlist.forEach((button) => {
            button.addEventListener('click', () => {
                let buttonDeleteWishlist = document.querySelector('#delete-wishlist');
                buttonDeleteWishlist.dataset.wishlistId = button.dataset.wishlistId;
                
                if (buttonDeleteWishlist.classList.contains('binded-delete')) return;

                buttonDeleteWishlist.classList.add('binded-delete');

                buttonDeleteWishlist.addEventListener('click', () => {
                    let wishlistDiv = document.querySelector(`#wishlist-${buttonDeleteWishlist.dataset.wishlistId}`);
                    wishlistDiv.remove();

                    let formData = new FormData();
                    formData.append('wishlistId', buttonDeleteWishlist.dataset.wishlistId);

                    ajax(buttonDeleteWishlist.dataset.url, 
                        (data) => {
                            if (data.success) {
                                Message.success(buttonDeleteWishlist.dataset.success);
                            }
                        }, 
                        'POST', 
                        formData, 
                        {
                            "X-CSRF-Token": document.querySelector('meta[name="csrfToken"]')?.content,
                        },
                        true,
                        true,            
                    );
                });
            });
        });
    }

    initEditWishlistName() {
        // init button and input
        let buttonEdit = null;
        let input = null;

        // button edit wishlist name
        let buttonsEditWishlistName = document.querySelectorAll('[data-target="#editWishlistModal"]');
        if (!buttonsEditWishlistName) return;
        buttonsEditWishlistName.forEach((button) => {
            button.addEventListener('click', () => {
                // modal container
                let containerEditWishlistName = document.querySelector('#editWishlistModal');

                // fill input with current wishlist name
                input = containerEditWishlistName.querySelector('input[name="wishlistName"]');
                input.value = button.dataset.wishlistName;

                buttonEdit = button;
            });
        });

        // button save wishlist name
        let buttonSaveWishlistName = document.querySelector('#edit-wishlist-name');
        buttonSaveWishlistName.addEventListener('click', () => {
            this.requestToEditWishlistName(buttonEdit, input);
        });
    }

    requestToEditWishlistName(button, input) {
        if (!button || !input) return;

        // change wishlist name in the page
        let wishlistName = document.querySelector(`#wishlist-name-${button.dataset.wishlistId}`);
        wishlistName.innerHTML = input.value;
        button.dataset.wishlistName = input.value;
        
        let formData = new FormData();
        formData.append('wishlistId', button.dataset.wishlistId);
        formData.append('wishlistName', input.value);

        ajax(input.dataset.url,
            (data) => {
                if (data.success) {
                    Message.success(input.dataset.success);
                }
            },
            'POST',
            formData,
            {
                "X-CSRF-Token": document.querySelector('meta[name="csrfToken"]')?.content,
            },
            true,
            true,
        );
    }

    saveWishlistName() {
        let wishlistId = this.wishlist.dataset.wishlistId;
        let input = document.querySelector('input[name="wishlistName"]');

        if (wishlistId && input) {
            let formData = new FormData();
            formData.append('wishlistId', wishlistId);
            formData.append('wishlistName', input.value);

            let wishlistNames = document.querySelectorAll('.wishlist-name');
            wishlistNames.forEach((element) => {
                element.innerHTML = input.value;
            });

            ajax(input.dataset.url, 
                (data) => {
                    if (data.success) {
                        Message.success(input.dataset.success);
                    }
                }, 
                'POST', 
                formData, 
                {
                    "X-CSRF-Token": document.querySelector('meta[name="csrfToken"]')?.content,
                },
                true,
                true,            
            );
        }
    }

    initPeopleAvatars() {
        let containersPeoples = document.querySelectorAll('#pp-peoples');

        if (!containersPeoples) return;

        containersPeoples.forEach((container) => {
            let photos = container.querySelectorAll('[id="pp-people"]');

            // 10 colors for users avatars
            let colorsArray = ["#009688", "#2196f3", "#00bcd4", "#3f51b5", "#777777", "#ffc107", "#f44336",  "#9c27b0", "#673ab7", "#03a9f4"];
    
            if (photos.length <= 0) return;
    
            photos.forEach((photo, index) => {
                // if parent node has id pp-peoples
                if (photo.parentNode.id === 'pp-peoples') {
                    photo.style.transform = 'translateX(-' + (index * 18) + 'px)';
                }
                
                // random color
                photo.style.backgroundColor = 
                    index < colorsArray.length ? 
                        colorsArray[index] : 
                        colorsArray[Math.floor(Math.random() * colorsArray.length)];
            });
        });

    }

    eventChangePermission() {
        let radios = document.querySelectorAll('input[name="radioPermission"]');
        if(!radios.length) return;
        let tokenView = document.querySelector('#token-view').dataset.token;
        let tokenEdit = document.querySelector('#token-edit').dataset.token;
        radios.forEach((radio) => {
            radio.addEventListener('change', () => {
                let links = document.querySelectorAll('[id^="share-wishlist-link"]');
                if(!links.length) return;
                links.forEach((element) => {
                    if (element.tagName.toLowerCase() === 'input') {
                        element.value = element.value.indexOf(tokenView) !== -1 ? 
                                        element.value.replace(tokenView, tokenEdit) : 
                                        element.value.replace(tokenEdit, tokenView);
                    } else {
                        element.href = element.href.indexOf(tokenView) !== -1 ? 
                                        element.href.replace(tokenView, tokenEdit) : 
                                        element.href.replace(tokenEdit, tokenView);
                    }
                });
            });
        });
    }

    initDeleteWishlist() {
        let wishlistId = this.wishlist.dataset.wishlistId;
        let buttonDeleteWishlist = document.querySelector('#delete-wishlist');

        if (!buttonDeleteWishlist) return;

        buttonDeleteWishlist.addEventListener('click', () => {
            let formData = new FormData();
            formData.append('wishlistId', wishlistId);

            ajax(buttonDeleteWishlist.dataset.url, 
                (data) => {
                    if (data.success) {
                        window.location.href = buttonDeleteWishlist.dataset.redirectUrl;
                        Message.success(buttonDeleteWishlist.dataset.success);
                    }
                }, 
                'POST', 
                formData, 
                {
                    "X-CSRF-Token": document.querySelector('meta[name="csrfToken"]')?.content,
                },
                true,
                true,            
            );
        });
    }

    initDeleteUserFromWishlist() {
        let wishlistId = this.wishlist.dataset.wishlistId;
        let buttonDeleteUser = document.querySelectorAll('#delete-user');

        if (!buttonDeleteUser.length) return;

        buttonDeleteUser.forEach((button) => {
            button.addEventListener('click', () => {
                let formData = new FormData();
                formData.append('wishlistId', wishlistId);
                formData.append('userId', button.dataset.userId);

                let user = document.querySelector('#user-' + button.dataset.userId);
                user.classList.add('hidden');

                ajax(button.dataset.url, 
                    (data) => {
                        if (data.success) {
                            // remove user in list users
                            user.parentNode.removeChild(user);
                            Message.success(button.dataset.success);
                        }
                    }, 
                    'POST', 
                    formData, 
                    {
                        "X-CSRF-Token": document.querySelector('meta[name="csrfToken"]')?.content,
                    },
                    true,
                    true,            
                );
            });
        });
    }

    initDisplayMap() {
        let tabMap = document.querySelector('#tab-wishlist-map');
        let tabWishes = document.querySelector('#tab-wishlist-wishes');
        let divFilters = document.querySelector('#filters-wishes');
        let firstLoad = true;

        let toggleMap = document.querySelector('#toggle-map');
        toggleMap.addEventListener('change', () => {
            if (toggleMap.checked) {
                tabMap.click();

                divFilters.classList.add('opacity-0', 'invisible');
                divFilters.parentElement.classList.add('justify-end');
                divFilters.parentElement.classList.remove('justify-between');

                if (firstLoad) {
                    window.setTimeout(() => {
                        window.dispatchEvent(new Event('resize'));
                    }, 100);
                    firstLoad = false;
                }       
            } else {
                tabWishes.click();

                divFilters.classList.remove('opacity-0', 'invisible');
                divFilters.parentElement.classList.remove('justify-end');
                divFilters.parentElement.classList.add('justify-between');
            }
        });
    }
}