import Modal from "../frontv2/Modal";
import Message from "../Message";
import {ajax} from "../utils";

class Kiosk {
  constructor() {
    this.payWrapper = document.getElementById('kiosk-pay');
    if(this.payWrapper){
      this.emailInput = this.payWrapper.querySelector('#email-payment');
      this.sendEmailButton = this.payWrapper.querySelector('#send-button');
      if(this.emailInput && this.sendEmailButton){
        this.initMailSending();
      }

      window.startCountdown = 30; //countdown that redirect user to start of kiosk process starts at 30
      this.warningMessageAppearsAt = 15; //a message to tell the user that he will be redirected in ...
      this.initCountdown();
      this.initNeedMoreTimeButton();
    }
  }

  initMailSending(){
    this.sendEmailButton.addEventListener('click', () => {
      let email = this.emailInput.value;
      if(email === '') return false;

      if(!this.emailInput.checkValidity()){
        Message.error(this.emailInput.dataset.incorrectEmail);
        return false;
      }

      let formData = new FormData();
      formData.append('email', email);
      formData.append('reference', this.emailInput.dataset.reference);

      this.sendEmailButton.disabled = true;
      ajax(this.emailInput.dataset.url, () => {
        Message.success(this.emailInput.dataset.mailSent);

        //clear the email input
        this.emailInput.value = '';
        this.sendEmailButton.disabled = false;
      },
      'POST', formData,
      { "X-CSRF-Token": document.querySelector('meta[name="csrfToken"]')?.content },
      true, false);
    });
  }

  //init a countdown to 30 seconds
  //at 15 seconds, a popup appears to tell the visitor to click on a button to reset the countdown and prevent going back on home0
  initCountdown(){
    this.countdownElt = this.payWrapper.querySelector('#countdown');

    //store it in window object so we can access it through Modal.js
    window.kioskCountdown = window.startCountdown;
    this.countdownElt.textContent = window.kioskCountdown;
    let interval = window.setInterval(() => {
      if(window.kioskCountdown > 0){
        window.kioskCountdown--;
        this.countdownElt.textContent = window.kioskCountdown;
      }

      if(window.kioskCountdown === this.warningMessageAppearsAt){
        Modal.toggleModal('#timerModal');
      }
      else if(window.kioskCountdown === 0){
        window.location.href = this.payWrapper.dataset.redirectToSearch;
        window.clearInterval(interval);
      }
    }, 1000);
  }

  //reset the timer that closes the page and redirect to the start of the kiosk process
  initNeedMoreTimeButton(){
    const needMoreTimeButton = this.payWrapper.querySelector('#need-more-time');
    if(needMoreTimeButton){
      needMoreTimeButton.addEventListener('click', () => {
        window.kioskCountdown = window.startCountdown;
        Modal.closeAllModals();
      });
    }
  }
}

export default Kiosk;
