import {ajax} from "../utils";

function countryPickerDelivery(selector, options) {
  const select = document.querySelector(selector);
  const totalPriceContainer = document.querySelector(options.totalPriceContainer);
  const deliveryPriceContainer = document.querySelector(options.deliveryPriceContainer);
  const amountInput = document.querySelector(options.amountInput);
  const radios = document.querySelectorAll(options.radio);

  let deliveryAmount = 0;
  if(!select || !totalPriceContainer || !amountInput || !deliveryPriceContainer) return;
  select.addEventListener('change', function() {
    ajax(`/api/countrydelivery/${this.value}`, function (data) {
      deliveryAmount = data.data[0].amount / 100;
      deliveryPriceContainer.innerHTML = deliveryAmount.toLocaleString(document.body.dataset.lang.replace('_', '-'), {
        style: 'currency',
        currency: 'EUR'
      });
      if(amountInput.value > 0) {
        totalPriceContainer.innerHTML = (deliveryAmount + Number(amountInput.value)).toLocaleString(document.body.dataset.lang.replace('_', '-'), {
          style: 'currency',
          currency: 'EUR'
        });
      }
    });
  });

  radios.forEach(function(radio) {
    radio.addEventListener('change', function() {
      if(deliveryAmount > 0) {
        totalPriceContainer.innerHTML = (deliveryAmount + Number(amountInput.value)).toLocaleString(document.body.dataset.lang.replace('_', '-'), {
          style: 'currency',
          currency: 'EUR'
        });
      }
    });
    radio.dispatchEvent(new Event('change'));
  });

  amountInput.addEventListener('change', function() {
    if(deliveryAmount > 0) {
      totalPriceContainer.innerHTML = (deliveryAmount + Number(amountInput.value)).toLocaleString(document.body.dataset.lang.replace('_', '-'), {
        style: 'currency',
        currency: 'EUR'
      });
    }
  });
  amountInput.dispatchEvent(new Event('change'));
}

function toggleBillingAddress(selector, options) {
  const checkbox = document.querySelector(selector);
  if(!checkbox) return;

  const form = document.querySelector(options.form);
  if(form){
    const toggleClassName = options.hasOwnProperty('class') ? options.class : 'hidden';
    const inputs = form.querySelectorAll('input');

    checkbox.addEventListener('change', function() {
      form.classList.toggle(toggleClassName);
      inputs.forEach(function(input) {
        if(input.id != 'gift-billing-adress-line-2'){
          input.required = !input.required;
        }
      });

      // const address = form.querySelector('input[name="address_line_2"]');
      // if(address){
      //   form.querySelector('input[name="address_line_2"]').required = false;
      // }
    });
  }
}

function hideExpressOnCountryChange(selector, expressSelector, classicSelector){
  const country = document.querySelector(selector);
  const expressChoice = document.querySelector(expressSelector);
  const classicChoice = document.querySelector(classicSelector);
  if(!country || !expressChoice || !classicChoice) return;

  country.addEventListener('change', function(){
    if(this.value == 1){
      expressChoice.classList.remove('hidden');
    } else {
      expressChoice.classList.add('hidden');
      classicChoice.checked = true;
      classicChoice.dispatchEvent(new Event('change'));
    }
  });
  country.dispatchEvent(new Event('change'));
}

//Gift Card BOX only
function computeTotal(options){
  const giftCardAmount = document.querySelector(options.giftCardAmount);
  const subTotalContainer = document.querySelector(options.subTotalContainer);
  const totalContainer = document.querySelector(options.totalContainer);
  const deliveryPriceContainer = document.querySelector(options.deliveryPriceContainer);
  const deliveryLabelContainer = document.querySelector(options.deliveryLabelContainer);
  const amountInput = document.querySelector(options.amountInput);
  const radios = document.querySelectorAll(options.radio);
  const deliveryTypes = document.querySelectorAll(options.deliveryType);

  if(!giftCardAmount || !amountInput || !deliveryPriceContainer || !deliveryLabelContainer || !subTotalContainer || !totalContainer || !deliveryTypes) return;
  let deliveryAmount = 3.90;
  let giftCardTotal = 50;

  radios.forEach(function(radio) {
    radio.addEventListener('change', function() {
      if(this.checked){
        giftCardTotal = amountInput.value;
        giftCardAmount.innerHTML = (Number(giftCardTotal)).toLocaleString(document.body.dataset.lang.replace('_', '-'), {
          style: 'currency',
          currency: 'EUR'
        });
        subTotalContainer.innerHTML = (Number(giftCardTotal) + 15).toLocaleString(document.body.dataset.lang.replace('_', '-'), {
          style: 'currency',
          currency: 'EUR'
        });

        if(giftCardTotal > 0){
          totalContainer.innerHTML = (Number(giftCardTotal) + 15 + Number(deliveryAmount)).toLocaleString(document.body.dataset.lang.replace('_', '-'), {
            style: 'currency',
            currency: 'EUR'
          });
        }
      }
    });
  });

  amountInput.addEventListener('change', function() {
    giftCardTotal = amountInput.value;
    giftCardAmount.innerHTML = (Number(giftCardTotal)).toLocaleString(document.body.dataset.lang.replace('_', '-'), {
      style: 'currency',
      currency: 'EUR'
    });
    subTotalContainer.innerHTML = (Number(giftCardTotal) + 15).toLocaleString(document.body.dataset.lang.replace('_', '-'), {
      style: 'currency',
      currency: 'EUR'
    });
    if(giftCardTotal > 0){
      totalContainer.innerHTML = (Number(giftCardTotal) + 15 + Number(deliveryAmount)).toLocaleString(document.body.dataset.lang.replace('_', '-'), {
        style: 'currency',
        currency: 'EUR'
      });
    }
  });

  deliveryTypes.forEach(function(deliveryType){
    deliveryType.addEventListener('change', function() {
      if(this.checked){
        deliveryAmount = this.dataset.amount;
        deliveryPriceContainer.innerHTML = (Number(this.dataset.amount)).toLocaleString(document.body.dataset.lang.replace('_', '-'), {
          style: 'currency',
          currency: 'EUR'
        });
        deliveryLabelContainer.innerHTML = this.value === 'classic' ? this.dataset.classic : this.dataset.express;

        if(giftCardTotal > 0){
          totalContainer.innerHTML = (Number(giftCardTotal) + 15 + Number(deliveryAmount)).toLocaleString(document.body.dataset.lang.replace('_', '-'), {
            style: 'currency',
            currency: 'EUR'
          });
        }
      }
    });
    deliveryType.dispatchEvent(new Event('change'));
  })
}

export { countryPickerDelivery, toggleBillingAddress, hideExpressOnCountryChange, computeTotal };
