import {ajax, dateToString, debounced} from "../utils";
import flatpickr from "flatpickr";

class GlobalFilters {
  constructor() {
    this.wrapper = document.getElementById('global-filters');

    if(this.wrapper) {
      const _this = this;
      this.session = JSON.parse(document.body.dataset.session);
      this.adultsPicker = this.wrapper.querySelector('input[name=adults]');
      this.childrenPicker = this.wrapper.querySelector('input[name=children]');
      this.datepicker = this.wrapper.querySelector('input[name=date]');
      this.dropdownLangs = this.wrapper.querySelector('div[data-langs]');
      this.checkboxes = this.dropdownLangs.querySelectorAll('input[type=checkbox]');
      this.langs = [];
      this.from = null;
      this.to = null;

      for(let i = 0; i < this.checkboxes.length; i++) {
        this.checkboxes[i].addEventListener('change', function() {
          const index = _this.langs.indexOf(this.value);

          // if language checked AND not already in array
          if(this.checked && index === -1) {
            _this.langs.push(this.value);
          }
          // remove it from langs
          else {
            _this.langs.splice(index, 1);
          }

          _this.updateSession();
        });

        if(this.checkboxes[i].checked) {
          this.langs.push(this.checkboxes[i].value);
        }
      }

      this.dropdownLangs.querySelector('button.clear').addEventListener('click', (e) => {
        this.clearLanguagesSelection();
        this.closeDropdown(e);
      });

      this.adultsPicker.parentElement.parentElement.querySelector('button.clear').addEventListener('click', (e) => {
        this.clearPeople();
        this.closeDropdown(e);
      });

      this.datepicker.parentElement.querySelector('button.clear').addEventListener('click', (e) => {
        this.clearDate();
        this.closeDropdown(e);
      });

      // init flatppickr
      this.flatpickr = this.initDatePicker(this.datepicker);
      this.setDates();

      // const myHandler = (event) => {
      //   _this.updateSession();
      // };
      const dHandler = debounced(300, function() {
        _this.updateSession();
      });

      // bind onChange adults and children
      this.adultsPicker.addEventListener('change', dHandler);
      this.childrenPicker.addEventListener('change', dHandler);

      this.displayCurrentValues();
      this.checkExperiencesAvailability();
    }
  }

  initDatePicker(input) {
    const _this = this;

    let defaultDate = false;
    if(this.session && this.session.from !== 'null') {
      // defaultDate = [this.session.from, this.session.to];
      defaultDate = this.session.from;
    }

    return flatpickr(input, {
      altInput: true,
      altFormat: "F j, Y",
      dateFormat: "Y-m-d",
      inline: true,
      // mode: 'range',
      minDate: new Date(),
      defaultDate: defaultDate,
      // disableMobile: true,
      allowInput: true,
      onChange: function(selectedDates, dateStr) {
        if(selectedDates.length > 0) {
          _this.setDates();
          _this.updateSession();
        }
      },
    });
  }

  displayCurrentValues() {
    if(this.adultsPicker.value > 0) {
      const btn = this.adultsPicker.parentElement.parentElement.parentElement.querySelector('button[data-toggle=dropdown]');
      btn.classList.add('active');
      if(this.adultsPicker.value === '1') {
        btn.innerText = btn.dataset.adult.replace('{0}', this.adultsPicker.value);
      } else {
        btn.innerText = btn.dataset.adults.replace('{0}', this.adultsPicker.value);
      }
    } else {
      const btn = this.adultsPicker.parentElement.parentElement.parentElement.querySelector('button[data-toggle=dropdown]');
      btn.classList.remove('active');
      btn.innerText = btn.dataset.inactiveText;
    }

    if(this.datepicker.value) {
      const btn = this.datepicker.parentElement.parentElement.querySelector('button[data-toggle=dropdown]');
      btn.classList.add('active');
      // const humanDate = new Date(this.datepicker.value).toLocaleDateString();
      // btn.innerText = btn.dataset.activeText.replace('{x}', humanDate);
      if(this.from && this.to) {
        if (dateToString(this.from) === dateToString(this.to)) {
          btn.innerText = this.from.toLocaleDateString();
        } else {
          btn.innerText = this.from.toLocaleDateString() + ' - ' + this.to.toLocaleDateString();
        }
      }
      else if(this.from) {
        btn.innerText = this.from.toLocaleDateString();
      }
    } else {
      const btn = this.datepicker.parentElement.parentElement.querySelector('button[data-toggle=dropdown]');
      btn.classList.remove('active');
      btn.innerText = btn.dataset.initialText;
    }

    if(this.langs.length) {
      const btn = this.dropdownLangs.querySelector('button[data-toggle=dropdown]');
      btn.classList.add('active');
      btn.innerText = btn.dataset.activeText.replace('{x}', this.langs.length.toString());
    } else {
      const btn = this.dropdownLangs.querySelector('button[data-toggle=dropdown]');
      btn.classList.remove('active');
      btn.innerText = btn.dataset.inactiveText;
    }
  }

  checkExperiencesAvailability() {
    const _this = this;
    const cards = document.querySelectorAll('[data-experience-check-availability]');
    // check timeslots only if datepicker has a value
    if(this.from) {
      if (this.to) {
        cards.forEach(function (card) {
          card.classList.add('loading');
          ajax(`/api/experience/${card.dataset.experienceId}/days?from=${dateToString(_this.from)}&to=${dateToString(_this.to)}&languages=${_this.langs.join()}&adults=${_this.adultsPicker.value}&children=${_this.childrenPicker.value}`, function (data) {
            let isClosed = true;
            for(let i = 0; i < data.days.length; i++) {
              // check if at least one day is not closed
              if(!data.days[i].close) {
                isClosed = false;
                break;
              }
            }
            if(isClosed) {
              card.classList.add('not-available');
            } else {
              card.classList.remove('not-available');
            }
            card.classList.remove('loading');
          });
        });
      }
      else {
        cards.forEach(function (card) {
          card.classList.add('loading');
          ajax(`/api/experience/${card.dataset.experienceId}/timeslots?date=${dateToString(_this.from)}&languages=${_this.langs.join()}&adults=${_this.adultsPicker.value}&children=${_this.childrenPicker.value}`, function (data) {
            if (data.timeslots.length === 0) {
              card.classList.add('not-available');
            } else {
              card.classList.remove('not-available');
            }
            card.classList.remove('loading');
          });
        });
      }
    }
    else if(this.adultsPicker.value > 0 || this.childrenPicker.value > 0 || this.langs.length > 0) {
      // no date
      cards.forEach(function (card) {
        // check number of people and language
        card.classList.add('loading');

        ajax(`/api/experience/${card.dataset.experienceId}/specifications`, function (data) {
          card.classList.remove('not-available');
          // check languages
          if(_this.langs.length > 0) {
            let langAvailable = false;
            _this.langs.forEach(function (lang) {
              if (data.specs.languages.indexOf(lang) !== -1) {
                langAvailable = true;
              }
            });
            if (!langAvailable) {
              card.classList.add('not-available');
            }
          }

          // check if adults number and child number are in range for this experience
          // and check if selected languages are available for this experience
          if ((parseInt(_this.adultsPicker.value) + parseInt(_this.childrenPicker.value)) < data.specs.booking_min || (parseInt(_this.adultsPicker.value) + parseInt(_this.childrenPicker.value)) > data.specs.booking_max ) {
            card.classList.add('not-available');
          }
          // else {
          //   card.classList.add('not-available');
          // }
          card.classList.remove('loading');
        });
      });
    }
    else {
      cards.forEach(function (card) {
        card.classList.remove('not-available');
      });
    }
  }

  setDates() {
    if(this.flatpickr.selectedDates.length > 0) {
      if (this.flatpickr.selectedDates.length === 2) {
        this.from = this.flatpickr.selectedDates[0];
        this.to = this.flatpickr.selectedDates[1];
      } else {
        this.from = this.flatpickr.selectedDates[0];
        this.to = null;
      }
    }
  }

  updateSession() {
    const _this = this;
    const data = new FormData();
    data.append('adults', this.adultsPicker.value);
    data.append('children', this.childrenPicker.value);
    data.append('from', dateToString(this.from));
    data.append('to', dateToString(this.to));
    data.append('langs', JSON.stringify(this.langs));
    // ajax call with current values to update session on server side
    ajax(this.wrapper.dataset.url, function(data) {
      _this.displayCurrentValues();
      _this.checkExperiencesAvailability();
    }, 'POST', data,{
      "X-CSRF-Token": document.querySelector('meta[name="csrfToken"]')?.content,
    });
  }

  clearLanguagesSelection(event) {
    for(let i = 0; i < this.checkboxes.length; i++) {
      const index = this.langs.indexOf(this.checkboxes[i].value);
      if(index !== -1)
        this.langs.splice(index, 1);
      this.checkboxes[i].checked = false;
    }
    this.updateSession();
    this.displayCurrentValues();
    this.checkExperiencesAvailability();
  }

  clearPeople() {
    this.adultsPicker.value = 0;
    this.childrenPicker.value = 0;
    this.adultsPicker.dispatchEvent(new Event('change'));
    this.childrenPicker.dispatchEvent(new Event('change'));
    this.displayCurrentValues();
    this.updateSession();
    this.checkExperiencesAvailability();
  }

  clearDate() {
    this.flatpickr.clear();
    this.from = null;
    this.to = null;
    this.updateSession();
    this.displayCurrentValues();
    this.checkExperiencesAvailability();
  }

  closeDropdown(e){
    const dropdown = e.target.closest('.dropdown');
    if(dropdown) dropdown.classList.remove('active');
  }
}

export default GlobalFilters;
