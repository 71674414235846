import { ajax, debounced } from "../utils";

class Faq{
  constructor(){
    this.wrapper = document.getElementById('faq');
    this.search = document.querySelector('.faq-search');
    if(this.wrapper){
      const _this = this;
      this.questions = this.wrapper.querySelectorAll('.selectQuestion');
      this.activeQuestion = this.wrapper.querySelector('.selectQuestion.active');
      this.activeQuestionContent = this.wrapper.querySelector('div.question:not(.hidden)');
      this.summary = this.wrapper.querySelector('#summary');
      this.triggerSummary = this.wrapper.querySelector('#trigger-summary');

      if(this.questions){
        this.questions.forEach(function(question){
          question.addEventListener("click", () => _this.onQuestionClick(question));
        });
      }

      if(this.triggerSummary){
        this.triggerSummary.addEventListener("click", () => _this.onTriggerSummaryClick());
      }
    }

    if(this.search){
      const _this = this;
      this.input = this.search.querySelector('input');
      this.results = this.search.querySelector('.search-results');
      if(this.input && this.results){
        ['keyup', 'focus'].forEach((event) => {
          this.input.addEventListener(event, debounced(300, () => _this.searchQuery()));
        });
      }

      document.addEventListener("click", (event) => this.closeQueryResults(event), false);
    }
  }

  onQuestionClick(question){
    //Hide summary if it's visible
    if(!this.summary.classList.contains('hidden')){
      this.summary.classList.add('hidden');
    }

    //Hide previous question
    if(this.activeQuestion && this.activeQuestionContent){
      this.activeQuestion.classList.remove('active');
      this.activeQuestionContent.classList.add('hidden');
    }

    //Show question clicked
    if(question.classList.contains('fromSummary')){
      let menuQuestion = this.wrapper.querySelector(".selectQuestion[data-id='"+ question.dataset.id +"']");
      menuQuestion.classList.add('active');
      this.activeQuestion = menuQuestion;
    } else{
      this.activeQuestion = question;
      question.classList.add('active');
    }
    const content = this.wrapper.querySelector("div.question[data-id='"+ question.dataset.id +"']");
    if(content){
      content.classList.remove("hidden");
    }

    //Set new current question
    this.activeQuestionContent = content;
  }

  onTriggerSummaryClick(){
    //Hide potential question
    if(this.activeQuestion && this.activeQuestionContent){
      this.activeQuestion.classList.remove('active');
      this.activeQuestionContent.classList.add('hidden');
    }

    //Show summary
    this.summary.classList.remove('hidden');
  }

  searchQuery(){
    let query = this.input.value;
    if(query.trim().length === 0){
      return;
    }

    const _this = this;
    let html = ``;
    ajax(this.input.dataset.url + `?query=${query}`, (data) => {
      if(data.questions.length === 0){
        html += `
          <div class="flex items-center py-5 pl-12 pr-4 text-gray-500 border-b border-gray-200">
            ${_this.input.dataset.noResults}
          </div>
      `;
      } else{
        data.questions.forEach(function(question){
          let questionUrl = _this.input.dataset.questionUrl.replace('replaceSubject', question.subject.slug).replace('replaceQuestion', question.id);

          html += `
            <a href="${questionUrl}" class="flex items-center py-2 px-4 border-b border-gray-200 result">
              <i class="icon icon-question w-4 mr-4"></i>
              <div class="flex flex-col">
                <span class="font-semibold text-base">${question._translations[document.body.dataset.lang].question}</span>
                <span class="text-gray-500 text-sm">${question.subject.title}</span>
              </div>
            </a>
          `;
        });
      }

      let searchUrl = _this.input.dataset.searchUrl.replace('replaceQuery', query);
      html += `
      <a href="${searchUrl}" class="flex items-center py-5 pl-12 pr-4 text-me-red result">
        ${_this.input.dataset.see}
      </a>
    `;

    _this.results.innerHTML = html;
    _this.results.classList.remove('hidden');
    });
  }

  closeQueryResults(e){
    // if click is outside query results
    if (!e.target.classList.contains('search-results') && e.target.closest('.search-results') === null) {
      this.results.classList.add('hidden');
    }
  }
}

export default Faq;
