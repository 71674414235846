import {ajax} from "../utils";
import { initSelects } from "../common/selects";
import { toggleBillingAddress } from "./PhysicalGiftCard";
import intlTelInput from 'intl-tel-input';
import Message from "../Message";
import Dob from "../common/Dob";
import { initMelchiorInput } from "../common/init-melchior-input";
import { initStripe } from "../stripe_v2";

const initApplyDiscountCodesGC = () => {
  const displayDiscountInput = document.getElementById('display-discount-input');
  const toHideOnDiscountAdd = document.querySelectorAll('.to-hide-on-discount-add');
  const applyDiscountWrapper = document.getElementById('apply-discount-wrapper');
  const buttonApply = document.getElementById('applyDiscount');
  const discountInput = document.getElementById('discount-code');
  const checkout = document.getElementById('checkout');
  if(toHideOnDiscountAdd && applyDiscountWrapper && displayDiscountInput && buttonApply && discountInput && checkout){
    displayDiscountInput.addEventListener('click', () => {
      applyDiscountWrapper.classList.remove('hidden');
      displayDiscountInput.classList.add('hidden');

      toHideOnDiscountAdd.forEach((el) => {
        el.classList.add('hidden');
      });

      discountInput.focus();
    });

    buttonApply.addEventListener('click', () => {
      if(discountInput.value.trim() === '') return;

      let discountCode = discountInput.value;
      let formData = new FormData();
      formData.append('code', discountCode);

      //DISABLE LES BOUTONS DE PAIEMENT
      checkout.classList.add('loading');

      ajax(buttonApply.dataset.applyUrl, (data) => {
        try {
          let response = JSON.parse(data);

          if(response.invalid){
            Message.error(response.invalid);
          }

          //otherwise, redirect for CHOOSE
          if(response.redirect_url){
            window.location.href = response.redirect_url;
          }
        } catch (e) {
          //LOAD LA NOUVELLE PAGE
          checkout.innerHTML = data;

          //INIT STRIPE
          initStripe();

          //Init selects for langs (down the page)
          initSelects();

          // load intl tel input
          const telInput = document.querySelector('input[name="phone"]');
          if(telInput){
            const iti = intlTelInput(telInput, {
              initialCountry: telInput.dataset.country,
              utilsScript: "//cdnjs.cloudflare.com/ajax/libs/intl-tel-input/16.0.4/js/utils.js"
            });
            telInput.addEventListener('change', function() {
              if(iti.isValidNumber()) {
                this.value = iti.getNumber();
              } else {
                this.value = '';
              }
            });
          }

          //init JS for inputs
          initMelchiorInput();
          new Dob();

          toggleBillingAddress("[name='billing-different']", {
            form: '#form-billing-address'
          });

          let applyDiscountWrapper = document.getElementById('apply-discount-wrapper');
          if(applyDiscountWrapper){
            applyDiscountWrapper.classList.add('hidden');
          }
        }

        checkout.classList.remove('loading');
      }, 'POST', formData, {
        "X-CSRF-Token": document.querySelector('meta[name="csrfToken"]')?.content,
      }, true, false);
    });

    discountInput.addEventListener('keyup', (e) => {
      if (e.key === 'Enter') {
          buttonApply.dispatchEvent(new Event('click'));
      }
    });
  }
}

export { initApplyDiscountCodesGC }
