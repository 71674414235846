import Drawer from "../../common/Drawer";
import Message from "../../Message";
import { canDefineAPassword, validatePasswordSetup } from "./checkout-utils";

const initFreeCheckout = () => {
  const freeForm = document.getElementById('freeForm');
  const freeFormSubmit = document.getElementById('free-form-submit');
  if(freeForm) {
    freeForm.addEventListener('submit', async (e) => {
      e.preventDefault();
      freeFormSubmit.disabled = true;

      //check if user decided to setup password and if so, check if passwords match
      if(!validatePasswordSetup(freeForm)){
        freeFormSubmit.disabled = false;
        freeFormSubmit.classList.remove('loading');
        return false;
      }

      // check if user can define a password
      const setPasswordCheckbox = document.querySelector('#set-password');
      if(setPasswordCheckbox && setPasswordCheckbox.checked){
        let userCanDefineAPassword = await canDefineAPassword(freeForm);
        if(!userCanDefineAPassword){
          Message.error(setPasswordCheckbox.dataset.cantDefineAPasswordErrorMessage);

          //open drawer so user can login
          window.setTimeout(() => {
            Drawer.openDrawer(document.querySelector('.login-drawer'));
          }, 500);

          freeFormSubmit.disabled = false;
          freeFormSubmit.classList.remove('loading');
          return false;
        }
      }

      if(!freeForm.checkValidity()) {
        freeFormSubmit.disabled = false;
        freeFormSubmit.classList.remove('loading');
        return false;
      }

      //GA4 - Experience purchase
      try{
        const cookiesBlocked = document.querySelector('[name=cookies_blocked]');
        const gtmBlocked = document.querySelector('[name=gtm_blocked]');
        if(cookiesBlocked && gtmBlocked){
            if(window.navigator && !window.navigator.cookieEnabled){
                cookiesBlocked.value = '1';
            }
            if(window && window.google_tag_manager === undefined){
                gtmBlocked.value = '1';
            }
        }

        if (typeof dataLayer != 'undefined') {
          const expAnalyticsBlock = document.getElementById('experience-data');
          if(expAnalyticsBlock){
            dataLayer.push({ ecommerce: null }); // Clear the previous ecommerce object.
            dataLayer.push({
              event: "purchase",
              ecommerce: {
                transaction_id: expAnalyticsBlock.dataset.transactionId,
                items: [{
                  item_id: expAnalyticsBlock.dataset.experienceId,
                  item_name: expAnalyticsBlock.dataset.experienceName,
                  item_brand: expAnalyticsBlock.dataset.hostName,
                  item_category: expAnalyticsBlock.dataset.categoryOne,
                  item_category2: expAnalyticsBlock.dataset.categoryTwo,
                  item_category3: expAnalyticsBlock.dataset.categoryThree,
                  item_category4: expAnalyticsBlock.dataset.categoryFour,
                  quantity: 1
                }]
              }
            });
          }
        }
      } catch(e){
        console.error(e);
      }

      freeForm.submit();
    });
  }
}

export { initFreeCheckout };
