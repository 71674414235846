const initCopyLinkToClipboard = () => {
    let containersShareLink = document.querySelectorAll('.share-link-clipboard:not(.binded)');

    if(!containersShareLink) return console.error('Missing containers element');

    containersShareLink.forEach(container => {
        let button = container.querySelector('button.button-copy-link');
        let input = container.querySelector('input.input-link');

        if(!button || !input) return console.error('Missing button or input element');

        button.addEventListener('click', () => {    
            input.select();
            input.setSelectionRange(0, 99999);
    
            navigator.clipboard.writeText(input.value);
    
            button.querySelector('svg').classList.add('hidden');
            button.querySelector('i').classList.remove('hidden');
            setTimeout(() => {
                button.querySelector('i').classList.add('hidden');
                button.querySelector('svg').classList.remove('hidden');
            }, 1000);
        });

        container.classList.add('binded');
    })
}

export { initCopyLinkToClipboard }