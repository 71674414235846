const initContactForm = () => {
    const contactFormWrapper = document.getElementById('contactFormWrapper');

    if(contactFormWrapper){
        //Inputs
        const selectVisitorSubject = contactFormWrapper.querySelector('#select-visitor-subject');
        const selectHostSubject = contactFormWrapper.querySelector('#select-host-subject');
        const selectOtherSubject = contactFormWrapper.querySelector('#select-other-subject');

        //Wrappers
        const selectBookingWrapper = contactFormWrapper.querySelector('#selectBookingWrapper');
        const selectSubjectHostWrapper = contactFormWrapper.querySelector('#selectSubjectHostWrapper');
        const selectSubjectVisitorWrapper = contactFormWrapper.querySelector('#selectSubjectVisitorWrapper');
        const selectLinkedHostWrapper = contactFormWrapper.querySelector('#selectLinkedHostWrapper');
        const selectSubjectOtherWrapper = contactFormWrapper.querySelector('#selectSubjectOtherWrapper');
        const selectHostAttachment = contactFormWrapper.querySelector('#host-attachment-file');

        //Radio conctact type
        const contact_type_host = contactFormWrapper.querySelector('#contact_type_host');
        const contact_type_other = contactFormWrapper.querySelector('#contact_type_other');
        const contact_type_visitor = contactFormWrapper.querySelector('#contact_type_visitor');

        //Faq
        const faqWrapper = contactFormWrapper.querySelector('#faqWrapper');
        const visitorQuestionsWrapper = contactFormWrapper.querySelector('#visitorQuestionsWrapper');
        const hostQuestionsWrapper = contactFormWrapper.querySelector('#hostQuestionsWrapper');
        const managementQuestionsWrapper = contactFormWrapper.querySelector('#managementQuestionsWrapper');
        const modifyCancelBookingsQuestionsWrapper = contactFormWrapper.querySelector('#modifyCancelBookingsQuestionsWrapper');
        const functionningQuestionsWrapper = contactFormWrapper.querySelector('#functionningQuestionsWrapper');
        const experienceQuestionsWrapper = contactFormWrapper.querySelector('#experienceQuestionsWrapper');

        //Faq images
        const hostFaqImage = contactFormWrapper.querySelector('#hostFaqImage');
        const visitorFaqImage = contactFormWrapper.querySelector('#visitorFaqImage');

        const bookingRelatedSubjects = [
            'modifyBooking',
            'cancelBooking',
            'experienceIssue',
            'other'
        ];

        if(selectVisitorSubject && selectBookingWrapper){
            selectVisitorSubject.addEventListener('change', () =>{
                if(bookingRelatedSubjects.includes(selectVisitorSubject.value)) {
                    selectBookingWrapper.classList.remove('invisible');
                } else {
                    selectBookingWrapper.classList.add('invisible');
                }
            });
        }

        if(selectSubjectHostWrapper && selectSubjectVisitorWrapper && selectSubjectOtherWrapper
            && selectHostAttachment
            && contact_type_host && contact_type_other && contact_type_visitor
            && selectVisitorSubject && selectHostSubject && selectOtherSubject
        ){
            contact_type_visitor.addEventListener('change', () => {
                if(selectBookingWrapper) selectBookingWrapper.classList.remove('hidden');
                selectSubjectVisitorWrapper.classList.remove('hidden');
                selectSubjectHostWrapper.classList.add('hidden');
                selectSubjectOtherWrapper.classList.add('hidden');
                if(selectLinkedHostWrapper) selectLinkedHostWrapper.classList.add('hidden');
                if(selectHostAttachment) selectHostAttachment.classList.add('hidden');

                selectVisitorSubject.required = true;
                selectHostSubject.required = false;
                selectOtherSubject.required = false;

                faqWrapper.classList.remove('hidden');
                if(hostQuestionsWrapper) hostQuestionsWrapper.classList.add('hidden');
                if(visitorQuestionsWrapper) visitorQuestionsWrapper.classList.remove('hidden');
                if(managementQuestionsWrapper) managementQuestionsWrapper.classList.add('hidden');
                if(modifyCancelBookingsQuestionsWrapper) modifyCancelBookingsQuestionsWrapper.classList.add('hidden');
                if(functionningQuestionsWrapper) functionningQuestionsWrapper.classList.add('hidden');
                if(experienceQuestionsWrapper) experienceQuestionsWrapper.classList.add('hidden');

                hostFaqImage.classList.add('hidden');
                visitorFaqImage.classList.remove('hidden');
            });

            contact_type_other.addEventListener('change', () => {
                if(selectBookingWrapper) selectBookingWrapper.classList.add('hidden');
                selectSubjectOtherWrapper.classList.remove('hidden');
                selectSubjectVisitorWrapper.classList.add('hidden');
                selectSubjectHostWrapper.classList.add('hidden');
                if(selectLinkedHostWrapper) selectLinkedHostWrapper.classList.add('hidden');
                if(selectHostAttachment) selectHostAttachment.classList.add('hidden');

                selectVisitorSubject.required = false;
                selectHostSubject.required = false;
                selectOtherSubject.required = true;

                faqWrapper.classList.add('hidden');
                if(hostQuestionsWrapper) hostQuestionsWrapper.classList.add('hidden');
                if(visitorQuestionsWrapper) visitorQuestionsWrapper.classList.add('hidden');
                if(managementQuestionsWrapper) managementQuestionsWrapper.classList.add('hidden');
                if(modifyCancelBookingsQuestionsWrapper) modifyCancelBookingsQuestionsWrapper.classList.add('hidden');
                if(functionningQuestionsWrapper) functionningQuestionsWrapper.classList.add('hidden');
                if(experienceQuestionsWrapper) experienceQuestionsWrapper.classList.add('hidden');
            });

            contact_type_host.addEventListener('change', () => {
                if(selectBookingWrapper) selectBookingWrapper.classList.add('hidden');
                selectSubjectVisitorWrapper.classList.add('hidden');
                selectSubjectOtherWrapper.classList.add('hidden');
                selectSubjectHostWrapper.classList.remove('hidden');
                if(selectLinkedHostWrapper) selectLinkedHostWrapper.classList.remove('hidden');
                if(selectHostAttachment) selectHostAttachment.classList.remove('hidden');

                selectVisitorSubject.required = false;
                selectHostSubject.required = true;
                selectOtherSubject.required = false;

                faqWrapper.classList.remove('hidden');
                if(hostQuestionsWrapper) hostQuestionsWrapper.classList.remove('hidden');
                if(visitorQuestionsWrapper) visitorQuestionsWrapper.classList.add('hidden');
                if(managementQuestionsWrapper) managementQuestionsWrapper.classList.add('hidden');
                if(modifyCancelBookingsQuestionsWrapper) modifyCancelBookingsQuestionsWrapper.classList.add('hidden');
                if(functionningQuestionsWrapper) functionningQuestionsWrapper.classList.add('hidden');
                if(experienceQuestionsWrapper) experienceQuestionsWrapper.classList.add('hidden');

                hostFaqImage.classList.remove('hidden');
                visitorFaqImage.classList.add('hidden');
            });

            selectHostSubject.addEventListener('change', () => {
                switch(selectHostSubject.value){
                    case 'accountManagement':
                        visitorQuestionsWrapper.classList.add('hidden');
                        hostQuestionsWrapper.classList.add('hidden');
                        managementQuestionsWrapper.classList.remove('hidden');
                        modifyCancelBookingsQuestionsWrapper.classList.add('hidden');
                        functionningQuestionsWrapper.classList.add('hidden');
                        experienceQuestionsWrapper.classList.add('hidden');
                        break;
                    case 'bookingManagement':
                        visitorQuestionsWrapper.classList.add('hidden');
                        hostQuestionsWrapper.classList.add('hidden');
                        managementQuestionsWrapper.classList.add('hidden');
                        modifyCancelBookingsQuestionsWrapper.classList.remove('hidden');
                        functionningQuestionsWrapper.classList.add('hidden');
                        experienceQuestionsWrapper.classList.add('hidden');
                        break;
                    case 'winalistFunctioning':
                        visitorQuestionsWrapper.classList.add('hidden');
                        hostQuestionsWrapper.classList.add('hidden');
                        managementQuestionsWrapper.classList.add('hidden');
                        modifyCancelBookingsQuestionsWrapper.classList.add('hidden');
                        functionningQuestionsWrapper.classList.remove('hidden');
                        experienceQuestionsWrapper.classList.add('hidden');
                        break;
                    case 'experienceProblem':
                        visitorQuestionsWrapper.classList.add('hidden');
                        hostQuestionsWrapper.classList.add('hidden');
                        managementQuestionsWrapper.classList.add('hidden');
                        modifyCancelBookingsQuestionsWrapper.classList.add('hidden');
                        functionningQuestionsWrapper.classList.add('hidden');
                        experienceQuestionsWrapper.classList.remove('hidden');
                        break;
                    case 'other':
                        visitorQuestionsWrapper.classList.add('hidden');
                        hostQuestionsWrapper.classList.remove('hidden');
                        managementQuestionsWrapper.classList.add('hidden');
                        modifyCancelBookingsQuestionsWrapper.classList.add('hidden');
                        functionningQuestionsWrapper.classList.add('hidden');
                        experienceQuestionsWrapper.classList.add('hidden');
                        break;
                }
            });
        }

        //toggle display of warning message depending on conditions
        const userTypeRadios = document.querySelectorAll('[name=contact_type]');
        const visitorRadio = document.getElementById('contact_type_visitor');
        const typeOfProblem = document.getElementById('select-visitor-subject');
        const warningMessage = document.getElementById('warning-message-booking');
        if(userTypeRadios && visitorRadio && typeOfProblem && warningMessage){
          userTypeRadios.forEach(radio => {
            radio.addEventListener('change', () => {
              if(radio.value == 'visitor' && radio.checked && ['bookingIssue', 'other'].indexOf(typeOfProblem.value) !== -1){
                warningMessage.classList.remove('hidden');
              }
              else warningMessage.classList.add('hidden');
            });
          });

          typeOfProblem.addEventListener('change', () => {
            if(visitorRadio.checked && ['bookingIssue', 'other'].indexOf(typeOfProblem.value) !== -1){
              warningMessage.classList.remove('hidden');
            }
            else warningMessage.classList.add('hidden');
          });
        }
    }
}

export { initContactForm }
