import { ajax, splitFullname } from "./utils";

const initQuotationStripe = () => {
  console.log('Quotation Stripe initialized');

  (async () => {
    'use strict';

    // Create references to the main form and its submit button.
    const form = document.getElementById('payment-form');
    const submitButton = form.querySelector('button[type=submit]');
    const checkouta = document.getElementById('quotation-checkout');
    const amount = parseInt(checkouta.dataset.amountStripe);
    const formSelectCountry = form.querySelector('select[name=country]');

    const paymentMethodsSupported = [checkouta.dataset.paymentMethod];

    // Retrieve the configuration for the store.
    const config = {
      // Default country for the checkout form.
      country: formSelectCountry.value,

      paymentRequestCountriesAvailable: [
        'AE', 'AT', 'AU', 'BE', 'BR', 'CA', 'CH', 'CZ', 'DE', 'DK', 'EE', 'ES', 'FI', 'FR', 'GB', 'GR', 'HK', 'IE', 'IN', 'IT', 'JP', 'LT', 'LU', 'LV', 'MX', 'MY', 'NL', 'NO', 'NZ', 'PH', 'PL', 'PT', 'RO', 'SE', 'SG', 'SI', 'SK', 'US'
      ],

      // Store currency.
      // currency: 'eur',
      currency: checkouta.dataset.currency ?? 'eur',

      // Supported payment methods for the store.
      // Some payment methods support only a subset of currencies.
      // Make sure to check the docs: https://stripe.com/docs/sources
      paymentMethods: paymentMethodsSupported,

      // Configuration for Stripe.
      // API Keys: https://dashboard.stripe.com/account/apikeys
      // Webhooks: https://dashboard.stripe.com/account/webhooks
      // Storing these keys and secrets as environment variables is a good practice.
      // You can fill them in your own `.env` file.
      stripe: {
        // The two-letter country code of your Stripe account (required for Payment Request).
        country: 'FR',
        // API version to set for this app (Stripe otherwise uses your default account version).
        // apiVersion: '2019-03-14',
        // Use your test keys for development and live keys for real charges in production.
        // For non-card payments like iDEAL, live keys will redirect to real banking sites.
        publishableKey: checkouta.dataset.stripePublishKey,
      },
    };

    const getPaymentIntent = (callback) => {
      let xmlhttp = new XMLHttpRequest();

      xmlhttp.onreadystatechange = function() {
        if (this.readyState === XMLHttpRequest.DONE) {   // XMLHttpRequest.DONE == 4
          if (xmlhttp.status === 200) {
            callback(JSON.parse(this.responseText));
          }
          else if (xmlhttp.status === 400) {
            console.error('There was an error 400');
          }
          else {
            console.error('something else other than 200 was returned');
          }
        }
      };

      const getParam = checkouta.hasAttribute('data-host-id') ? `?host_id=${checkouta.dataset.hostId}`
                                                              : '';

      xmlhttp.open("GET", checkouta.dataset.paymentIntentUrl + getParam, false);
      xmlhttp.setRequestHeader("X-CSRF-Token", document.querySelector('meta[name="csrfToken"]')?.content);
      xmlhttp.send();
    };

    let paymentIntent;
    await getPaymentIntent(function(data) {
      paymentIntent = data;
    });

    /**
     * Implement a Stripe Card Element that matches the look-and-feel of the app.
     *
     * This makes it easy to collect debit and credit card payments information.
     */

    const stripe = Stripe(config.stripe.publishableKey);

    let cardPayment = checkouta.dataset.paymentMethod === 'card';
    if(cardPayment){
      // Create a Stripe client.
      // Create an instance of Elements.
      var elements = stripe.elements();
      // Prepare the styles for Elements.
      const style = {
        base: {
          // iconColor: '#666ee8',
          // color: '#31325f',
          // fontWeight: 400,
          fontFamily: 'Averta, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif',
          fontSmoothing: 'antialiased',
          fontSize: '16px',
          '::placeholder': {
              color: '#a0aec0',
          },
          // ':-webkit-autofill': {
          //     color: '#666ee8',
          // },
        },
      };

      var cardNumber = elements.create('cardNumber', {
        style: style,
        showIcon: true
        // classes: elementClasses,
      });
      cardNumber.mount('#card-number-element');

      var cardExpiry = elements.create('cardExpiry', {
        style: style,
        // classes: elementClasses,
      });
      cardExpiry.mount('#card-expiry-element');

      var cardCvc = elements.create('cardCvc', {
        style: style,
        // classes: elementClasses,
      });
      cardCvc.mount('#card-cvc-element');

      // Monitor change events on the Card Element to display any errors.
      cardNumber.on('change', ({error, complete}) => {
        const cardErrors = document.getElementById('card-number-error');
        if (error) {
          cardErrors.textContent = error.message;
          cardErrors.classList.add('visible');
        } else {
          cardErrors.classList.remove('visible');
        }
        // Re-enable the Pay button.
        submitButton.disabled = false;

        if(complete){
          cardExpiry.focus();
        }
      });

      cardExpiry.on('change', ({error, complete}) => {
        const cardErrors = document.getElementById('card-expiry-error');
        if (error) {
          cardErrors.textContent = error.message;
          cardErrors.classList.add('visible');
        } else {
          cardErrors.classList.remove('visible');
        }
        // Re-enable the Pay button.
        submitButton.disabled = false;

        if(complete){
          cardCvc.focus();
        }
      });

      cardCvc.on('change', () => {
        submitButton.disabled = false;
      });
    }
    else{ //otherwise, it is a bank transfer payment
      const options = {
        clientSecret: paymentIntent.client_secret,
        // Fully customizable with appearance API.
        appearance: {
          variables: {
            fontFamily: 'Averta, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen-Sans, Ubuntu, Cantarell, "Helvetica Neue", sans-serif',
          },
          rules:{
            '.Label': {
              fontWeight: '600'
            }
          }
        },
      };

      // Set up Stripe.js and Elements to use in checkout form, passing the client secret obtained in step 3
      var elements = stripe.elements(options);

      // Create and mount the Payment Element
      var paymentElement = elements.create('payment', {
        fields: {
          billingDetails: {
            email: 'never'
          }
        }
      });
      paymentElement.mount('#bank-payment-element');
      paymentElement.on('ready', () => {
        submitButton.disabled = false;
      });
    }


    /**
     * Handle the form submission.
     *
     * This uses Stripe.js to confirm the PaymentIntent using payment details collected
     * with Elements.
     *
     * Please note this form is not submitted when the user chooses the "Pay" button
     * or Apple Pay, Google Pay, and Microsoft Pay since they provide name and
     * shipping information directly.
     */

    // Listen to changes to the user-selected country.
    formSelectCountry
      .addEventListener('change', event => {
        event.preventDefault();
        selectCountry(event.target.value);
      });


    // Submit handler for our payment form.
    form.addEventListener('submit', async event => {
      event.preventDefault();

      if(!form.checkValidity()) {
        return false;
      }

      //create a guest user if guest checkout
      if(checkouta.hasAttribute('data-guest-checkout')){
        await createGuestUser();
      }

      // Retrieve the user information from the form.
      let payment = '';
      payment = form.querySelector('input[name=payment]:checked')?.value;
      const name = form.querySelector('input[name=firstname]').value + ' ' + form.querySelector('input[name=lastname]').value;
      const phone = form.querySelector('input[name=phone]').value;
      const country = form.querySelector('select[name=country] option:checked')
        .value;

      const emailInput = form.querySelector('input[name=mail]');
      let email = '';
      if(emailInput){
        email = emailInput.value;
      }

      // Disable the Pay button to prevent multiple click events.
      if(submitButton){
        submitButton.disabled = true;

        submitButton.textContent = submitButton.hasAttribute('data-processing-text')
                                    ? submitButton.dataset.processingText
                                    : 'Processing...';
      }

      if (payment === 'card') {
        // Let Stripe.js handle the confirmation of the PaymentIntent with the card Element.
        const params = {
          payment_method: {
            card: cardNumber,
            billing_details: {
              name: name,
              phone: phone
            }
          }
        };

        if(email !== ''){
          params.payment_method.billing_details.email = email;
        }

        const response = await stripe.confirmCardPayment(paymentIntent.client_secret, params);
        handlePayment(response);
      }
      else {
        const response = await stripe.confirmPayment({
          //`Elements` instance that was used to create the Payment Element
          elements,
          confirmParams: {
            return_url: checkouta.dataset.returnUrl,
            payment_method_data: {
              billing_details: {
                name: name,
                phone: phone,
                email: email,
              }
            }
          },
        });
        handlePayment(response);

        // if (error) {
        //   // This point will only be reached if there is an immediate error when
        //   // confirming the payment. Show error to your customer (for example, payment
        //   // details incomplete)
        //   // const messageContainer = document.querySelector('#error-message');
        //   // if(messageContainer)
        //   //   messageContainer.textContent = error.message;
        //   if(submitButton){
        //     submitButton.disabled = false;
        //     submitButton.textContent = submitButton.dataset.initialText;
        //   }
        // } else {
        //   // Your customer will be redirected to your `return_url`. For some payment
        //   // methods like iDEAL, your customer will be redirected to an intermediate
        //   // site first to authorize the payment, then redirected to the `return_url`.
        // }
      }
      // }
    });

    //in case it is a guest checkout, create a guest user with all
    const createGuestUser = () => {
      return new Promise(resolve => {
        let formData = new FormData();

        const firstnameInput = form.querySelector('input[name=firstname]');
        const lastnameInput = form.querySelector('input[name=lastname]');
        const phoneInput = form.querySelector('input[name=phone]');
        const emailInput = form.querySelector('input[name=mail]');
        const countryInput = form.querySelector('select[name=country]');
        const langId = checkouta.dataset.langId;
        const origin = 'website';
        if(firstnameInput && lastnameInput && phoneInput && emailInput && countryInput && langId){
          formData.append('firstname', firstnameInput.value);
          formData.append('lastname', lastnameInput.value);
          formData.append('phone', phoneInput.value);
          formData.append('email', emailInput.value);
          formData.append('country_iso', countryInput.value);
          formData.append('langId', langId);
          formData.append('origin', origin);
          formData.append('stripeId', paymentIntent.id);
          formData.append('hostId', checkouta.dataset.hostId);

          const newsletter = form.querySelector('input[name=newsletter]');
          if(newsletter) formData.append('newsletter', newsletter.checked);

          submitButton.disabled = true;
          ajax(form.dataset.createGuestUserUrl, (data) => {
            submitButton.disabled = false;
            resolve(data.success);
          },
          'POST', formData, {}, true, true);
        }
        else{
          resolve(false);
        }
      });
    }

    // Handle new PaymentIntent result
    const handlePayment = paymentResponse => {
      const {paymentIntent, error} = paymentResponse;

      const mainElement = document.getElementById('main');
      const confirmationElement = document.getElementById('confirmation');

      if (error) {
        mainElement.classList.remove('processing');
        mainElement.classList.remove('receiver');
        confirmationElement.querySelector('.error-message').innerText =
          error.message;
        mainElement.classList.add('error');

        if(submitButton){
          submitButton.disabled = false;
          submitButton.textContent = submitButton.dataset.initialText;
        }
      } else if (paymentIntent.status === 'succeeded') {
        // Success! Payment is confirmed. Update the interface to display the confirmation screen.
        mainElement.classList.remove('processing');
        mainElement.classList.remove('receiver');
        // Update the note about receipt and shipping (the payment has been fully confirmed by the bank).
        // confirmationElement.querySelector('.note').innerText =
        //     'We just sent your receipt to your email address, and your items will be on their way shortly.';
        mainElement.classList.add('success');
        let cart_informations = document.querySelector('.booking-cart .informations');
        if(cart_informations){
          cart_informations.classList.add('hidden');
        }

        let imageCheers = document.getElementById('cheers-image');
        if(imageCheers) {
          imageCheers.classList.remove('invisible');
        }
      }
      else if (paymentIntent.status === 'processing') {
        // Success! Now waiting for payment confirmation. Update the interface to display the confirmation screen.
        mainElement.classList.remove('processing');
        // Update the note about receipt and shipping (the payment is not yet confirmed by the bank).
        // confirmationElement.querySelector('.note').innerText =
        //     'We’ll send your receipt and ship your items as soon as your payment is confirmed.';
        mainElement.classList.add('success');
      }
      else if (paymentIntent.status === 'requires_capture') {
        // in case of manual booking and waiting for host approval
        mainElement.classList.remove('processing');
        mainElement.classList.add('success');
      }
      else {
        // Payment has failed.
        mainElement.classList.remove('success');
        mainElement.classList.remove('processing');
        mainElement.classList.remove('receiver');
        mainElement.classList.add('error');
      }

      window.scrollTo(0, 0);
    };

    const retry = () => {
      const mainElement = document.getElementById('main');
      // const confirmationElement = document.getElementById('confirmation');
      init();
      mainElement.classList.remove('error');
    };

    // bind retry to retry payment button
    document.getElementById('retry-payment-button').addEventListener('click', retry);

    // Handle activation of payment sources not yet supported by PaymentIntents
    // const handleSourceActivation = source => {
    //   switch (source.flow) {
    //     case 'none':
    //       // Normally, sources with a `flow` value of `none` are chargeable right away,
    //       // but there are exceptions, for instance for WeChat QR codes just below.
    //       if (source.type !== 'wechat') {
    //         console.log('Unhandled none flow.', source);
    //       }
    //       break;
    //     default:
    //       // Customer's PaymentIntent is received, pending payment confirmation.
    //       break;
    //   }
    // };

    /**
     * Monitor the status of a source after a redirect flow.
     *
     * This means there is a `source` parameter in the URL, and an active PaymentIntent.
     * When this happens, we'll monitor the status of the PaymentIntent and present real-time
     * information to the user.
     */

    const pollPaymentIntentStatus = async (
      paymentIntent,
      timeout = 30000,
      interval = 500,
      start = null
    ) => {
      start = start ? start : Date.now();
      const endStates = ['succeeded', 'processing', 'canceled'];
      // Retrieve the PaymentIntent status from our server.
      const fetchUrl = checkouta.dataset.paymentIntentStatusUrl;
      const getParam = checkouta.hasAttribute('data-host-id') ? `?host_id=${checkouta.dataset.hostId}`
                                                              : '';
      const rawResponse = await fetch(`${fetchUrl}/${paymentIntent}${getParam}`);
      const response = await rawResponse.json();
      if (
        !endStates.includes(response.paymentIntent.status) &&
        Date.now() < start + timeout
      ) {
        // Not done yet. Let's wait and check again.
        setTimeout(
          pollPaymentIntentStatus,
          interval,
          paymentIntent,
          timeout,
          interval,
          start
        );
      } else {
        handlePayment(response);
        if (!endStates.includes(response.paymentIntent.status)) {
          // Status has not changed yet. Let's time out.
          console.warn(new Error('Polling timed out.'));
        }
      }
    };

    const url = new URL(window.location.href);
    const mainElement = document.getElementById('main');
    if (url.searchParams.get('payment_intent_client_secret') && url.searchParams.get('payment_intent')) {
      // Update the interface to display the processing screen.
      mainElement.classList.add('checkout', 'success', 'processing');

      // const {source} = await stripe.retrieveSource({
      //   id: url.searchParams.get('source'),
      //   client_secret: url.searchParams.get('client_secret'),
      // });

      // console.log(source);
      // Poll the PaymentIntent status.
      pollPaymentIntentStatus(paymentIntent.id);
      // pollPaymentIntentStatus(source.metadata.paymentIntent);
    } else {
      // Update the interface to display the checkout form.
      mainElement.classList.add('checkout');

      // Create the PaymentIntent with the cart details.
      // const response = await store.createPaymentIntent(
      //     config.currency,
      //     store.getLineItems()
      // );
      // paymentIntent = response.paymentIntent;
    }
    document.getElementById('main').classList.remove('loading');

    /**
     * Display the relevant payment methods for a selected country.
     */

      // List of relevant countries for the payment methods supported in this demo.
      // Read the Stripe guide: https://stripe.com/payments/payment-methods-guide
    const paymentMethods = {
        card: {
          name: 'Card',
          flow: 'none',
        },
      };

    // Update the main button to reflect the payment method being selected.
    const updateButtonLabel = (paymentMethod, bankName) => {
      submitButton.innerText = submitButton.dataset.initialText;
    }

    const selectCountry = country => {
      // Trigger the methods to show relevant fields and payment methods on page load.
      showRelevantPaymentMethods();
    };

    // Show only the payment methods that are relevant to the selected country.
    const showRelevantPaymentMethods = country => {
      if (!country) {
        country = form.querySelector('select[name=country] option:checked').value;
      }
      const paymentInputs = form.querySelectorAll('input[name=payment]');
      for (let i = 0; i < paymentInputs.length; i++) {
        let input = paymentInputs[i];
        input.parentElement.classList.toggle(
          'visible',
          input.value === 'card' ||
          (config.paymentMethods.includes(input.value) &&
            paymentMethods[input.value].countries.includes(country) &&
            paymentMethods[input.value].currencies.includes(config.currency))
        );
      }

      // Hide the tabs if card is the only available option.
      const paymentMethodsTabs = document.getElementById('payment-methods');
      if(paymentMethodsTabs){
        paymentMethodsTabs.classList.toggle(
          'visible',
          paymentMethodsTabs.querySelectorAll('li.visible').length > 1
        );
      }

      if(paymentInputs.length){
        // Check the first payment option again.
        paymentInputs[0].checked = 'checked';
        updateButtonLabel(paymentInputs[0].value, undefined, amount);
      }

      const cardChoice = form.querySelector('.payment-info.card');
      if(cardChoice){
        cardChoice.classList.add('visible');
      }
    };

    // Listen to changes to the payment method selector.
    for (let input of document.querySelectorAll('input[name=payment]')) {
      input.addEventListener('change', event => {
        event.preventDefault();
        const payment = form.querySelector('input[name=payment]:checked').value;
        const flow = paymentMethods[payment].flow;

        // Update button label.
        updateButtonLabel(event.target.value);

        // Show the relevant details, whether it's an extra element or extra information for the user.
        form
          .querySelector('.payment-info.card')
          .classList.toggle('visible', payment === 'card');

        const importantNoticeText = document.querySelector(".importantnotice");
        const captureNoticeText = document.querySelector(".captureNotice");
        const noCaptureNoticeText = document.querySelector(".noCaptureNotice");
        const captureNoticeAfterPaymentText = document.querySelector(".captureNoticeAfterPayment");
        const noCaptureNoticeAfterPaymentText = document.querySelector(".noCaptureNoticeAfterPayment");
        if(captureNoticeText && noCaptureNoticeText && importantNoticeText && captureNoticeAfterPaymentText && noCaptureNoticeAfterPaymentText){
          if(payment === 'alipay' || payment === 'wechat'){
            captureNoticeText.classList.add('hidden');
            noCaptureNoticeText.classList.remove('hidden');

            captureNoticeAfterPaymentText.classList.add('hidden');
            noCaptureNoticeAfterPaymentText.classList.remove('hidden');
          } else {
            captureNoticeText.classList.remove('hidden');
            noCaptureNoticeText.classList.add('hidden');
          }

          if(payment === 'card'){
            importantNoticeText.classList.remove('hidden');

            captureNoticeAfterPaymentText.classList.remove('hidden');
            noCaptureNoticeAfterPaymentText.classList.add('hidden');
          } else {
            importantNoticeText.classList.add('hidden');
          }
        }
      });
    }

    let init = () => {
      // Select the default country from the config on page load.
      let country = config.country;
      // Override it if a valid country is passed as a URL parameter.
      const urlParams = new URLSearchParams(window.location.search);
      let countryParam = urlParams.get('country')
        ? urlParams.get('country').toUpperCase()
        : config.country;
      if (form.querySelector(`option[value="${countryParam}"]`)) {
        country = countryParam;
      }
      selectCountry(country);
    };

    init();
  })();
};

export { initQuotationStripe };
