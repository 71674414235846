import { debounced, getMapRadius } from "../../utils";
import { mapTilesStyle } from "../map-utils";
import polyline from "@mapbox/polyline";

class HostsAround {
  constructor(maplibregl) {
    this.maplibregl = maplibregl;

    this.hostsAroundMapInited = false;
    this.markers = [];
    this.layersIDs = [];

    this.miniMapContainer = document.getElementById('host-map');
    this.hostsAroundList = document.getElementById('hosts-around-list');
    this.hostsAroundListContainer = document.getElementById('hosts-around-list-container');
    this.hostsAroundMap = document.getElementById('hosts-around-map');
    this.sortWrapper = document.getElementById('sort-wrapper');
    this.hostsFetchingAllowed = true;

    this.initialHostName = this.miniMapContainer.dataset.hostName;
    this.initialHostLat = this.miniMapContainer.dataset.lat;
    this.initialHostLng = this.miniMapContainer.dataset.lng;

    if(this.miniMapContainer && this.hostsAroundList && this.hostsAroundMap && this.hostsAroundListContainer && this.sortWrapper) {
      this.init();
    }
  }

  init() {
    //first, init the map when the modal opens (when the minimap is clicked)
    this.miniMapContainer.addEventListener('click', () => {
      if(!this.hostsAroundMapInited) {
        //init the map
        this.initMap();

        //then, init the search bar
        this.initSearchBar();

        //init the sort select
        this.initSortSelect();

        //init the find more hosts button to zoom out and find hosts if none were found
        this.findMoreHostsButtonClick();

        this.hostsAroundMapInited = true;
      }
    });
  }

  //init the map with the host's location
  initMap() {
    this.map = new this.maplibregl.Map({
      container: this.hostsAroundMap,
      style: mapTilesStyle,
      center: [this.hostsAroundMap.dataset.lng, this.hostsAroundMap.dataset.lat],
      zoom: this.hostsAroundMap.dataset.zoom
    });

    //add the navigation control
    this.map.addControl(new this.maplibregl.NavigationControl({
      showCompass: false
    }));

    const geolocate = new this.maplibregl.GeolocateControl({
      fitBoundsOptions: {
        maxZoom: 13
      },
      positionOptions: {
          enableHighAccuracy: true
      },
      trackUserLocation: true,

    });

    this.map.addControl(geolocate);

    //debounce the fetchHosts function to avoid too many requests
    this.debounceFetchHosts = debounced(1200, () => this.fetchHosts());

    const events = ['dragend', 'zoomend'];
    events.forEach(event => this.map.on(event, this.debounceFetchHosts));

    //make the initial request
    this.debounceFetchHosts();
  }

  //init the search bar
  initSearchBar() {
    this.searchBar = this.hostsAroundMap.querySelector('.hosts-around-search-bar');
    this.resultsContainer = this.hostsAroundMap.querySelector('.hosts-around-search-results');
    if(this.searchBar) {
      // Use keyup event and debounce the handleSearchChange function
      const debounceHandleSearchChange = debounced(500, (e) => this.handleSearchChange(e));
      this.searchBar.addEventListener('keyup', debounceHandleSearchChange);

      this.bindClearSearchButton();
    }
  }

  //bind the clear search button
  bindClearSearchButton() {
    const clearSearchButton = this.hostsAroundMap.querySelector('.hosts-around-search-clear');
    if(clearSearchButton) {
      clearSearchButton.addEventListener('click', () => {
        //clear the search bar input and the results container
        this.searchBar.value = '';
        this.resultsContainer.innerHTML = '';

        //and focus the search bar
        this.searchBar.focus();

        //clean the route itinerary
        this.cleanRouteItinerary();
      });
    }
  }

  //handle the search bar change
  handleSearchChange(e) {
    const searchValue = this.searchBar.value;
    fetch(`https://api.stadiamaps.com/geocoding/v1/autocomplete?text=${searchValue}&size=5&layers=address,locality,venue`)
      .then(response => response.json())
      .then(data => this.displaySearchResults(data))
      .catch(error => console.error('Error fetching search results:', error));
  }

  //display the search results
  displaySearchResults(data) {
    this.resultsContainer.innerHTML = '';
    data.features.forEach(feature => {
      const result = document.createElement('button');
      result.classList.add('hosts-around-search-result');
      result.type = 'button';
      result.dataset.lat = feature.geometry.coordinates[1];
      result.dataset.lng = feature.geometry.coordinates[0];

      let resultHTML = `
        <span class="hosts-around-search-result-name">${feature.properties.name ?? ''}</span>
      `;

      if(feature.properties.localadmin) {
        resultHTML += `<span class="hosts-around-search-result-city">${feature.properties.localadmin}</span>`;
      }

      result.innerHTML = resultHTML;

      result.addEventListener('click', () => {
        this.onResultClick(feature);
      });

      this.resultsContainer.appendChild(result);
      this.resultsContainer.classList.remove('hidden');
    });
  }

  //what happens when a search result is clicked
  onResultClick(feature){
    //jump to the result location
    this.map.jumpTo({
      center: [feature.geometry.coordinates[0], feature.geometry.coordinates[1]],
      speed: 2,
      zoom: 14
    });

    //set the search bar value to the search result clicked and hide the results container
    this.searchBar.value = feature.properties.name + ' ' + (feature.properties.localadmin ?? '');
    this.resultsContainer.classList.add('hidden');

    //fetch the hosts around the new map center
    this.debounceFetchHosts();

    //and also put a marker at the location of the result (and clean the previous one if it exists)
    if(this.addressMarker) {
      this.addressMarker.remove();
    }

    this.addressMarker = new this.maplibregl.Marker({
      scale: 1.1,
      color: '#D00328'
    })
    .setLngLat([feature.geometry.coordinates[0], feature.geometry.coordinates[1]])
    .addTo(this.map);

    //also add a route itinerary to the result location
    this.addRouteItinerary({lng: feature.geometry.coordinates[0], lat: feature.geometry.coordinates[1]});
  }

  /**
   * Clean any existing route itinerary
   */
  cleanRouteItinerary(){
    this.layersIDs.forEach(layerID => {
      this.map.removeLayer(layerID);
      this.map.removeSource(layerID);
    });

    //remove the route popup
    if(this.routePopup){
      this.routePopup.remove();
    }

    this.layersIDs = [];
  }

  /**
   * Add a route itinerary from the initial host to the result location
   * @param {*} feature
   */
  addRouteItinerary(toCoords){
    //clean any existing route itinerary
    this.cleanRouteItinerary();

    const url = `https://api.stadiamaps.com/route/v1`;
    const data = {
        locations: [
            {
                lon: Number(this.initialHostLng),
                lat: Number(this.initialHostLat),
                type: "break"
            },
            {
                lon: toCoords.lng,
                lat: toCoords.lat,
                type: "break"
            }
        ],
        costing: "auto",
        costing_options: {
          auto: {
            shortest: true
          }
        },
        units: document.body.dataset.lang == 'en_US' ? "miles" : "kilometers"
    };

    fetch(url, {
        method: "POST",
        headers: {
            "Content-Type": "application/json"
        },
        body: JSON.stringify(data)
    })
    .then( response => response.json() )
    .then(result => {
      //if result.trip doesn't exist, it means there is no route between the two points
      if(!result.trip){
        return;
      }

      // Construct a bounding box in the sw, ne format required by MapLibre. Note the lon, lat order.
      var sw = [result.trip.summary.min_lon, result.trip.summary.min_lat];
      var ne = [result.trip.summary.max_lon, result.trip.summary.max_lat];

      // Zoom to the new bounding box to focus on the route,
      // with a 50px padding around the edges. See https://maplibre.org/maplibre-gl-js/docs/API/classes/Map/#fitbounds
      this.map.fitBounds([sw, ne], {padding: 80});

      // For each leg of the trip...
      result.trip.legs.forEach((leg, idx) => {
        // Add a layer with the route polyline as an overlay on the map
        var layerID = "leg-" + idx;  // Unique ID with request ID and leg index
        this.layersIDs.push(layerID);
        // Note: Our polylines have 6 digits of precision, not 5
        var geometry = polyline.toGeoJSON(leg.shape, 6);
        this.map.addLayer({
          "id": layerID,
          "type": "line",
          "source": {
            "type": "geojson",
            "data": {
              "type": "Feature",
              "properties": {},
              "geometry": geometry
            }
          },
          "layout": {
            "line-join": "round",
            "line-cap": "round"
          },
          "paint": {
            "line-color": "#12312A",
            "line-opacity": 0.8,
            "line-width": 5
          }
        });
      });

      //add the route popup between the two points
      const start = [Number(this.initialHostLng), Number(this.initialHostLat)]; // lng, lat
      const end = [Number(toCoords.lng), Number(toCoords.lat)]; // lng, lat
      const midPoint = [
        (start[0] + end[0]) / 2,
        (start[1] + end[1]) / 2
      ];

      //place the popup
      this.routePopup = new this.maplibregl.Popup({
        closeButton: false,
        closeOnClick: false,
        className: 'route-popup'
      })
        .setLngLat(midPoint)
        .setHTML(this.buildRoutePopupDiv(result.trip.summary.time, result.trip.summary.length, result.trip.units))
        .addTo(this.map);
      })
      .catch(error => console.error("Error:", error));
  }

  /**
   * Build the route popup
   * @param {*} time in seconds
   * @param {*} distance in distanceUnit
   * @param {*} distanceUnit kilometers or miles
   * @returns
   */
  buildRoutePopupDiv(time, distance, distanceUnit){
    //round the distance to 1 decimal places
    distance = distance.toFixed(1);

    //increase the time by 40% to account for traffic
    time = time * 1.4;

    //format the time to hours and minutes or just minutes if less than 1 hour
    const hours = Math.floor(time / 3600);
    const minutes = Math.floor((time % 3600) / 60);

    let distanceText = this.hostsAroundMap.dataset.distanceKmText.replace('{0}', distance);
    let timeText = this.hostsAroundMap.dataset.minutesText.replace('{0}', minutes);
    if(hours > 0){
      timeText = this.hostsAroundMap.dataset.hoursMinutesText.replace('{0}', hours).replace('{1}', minutes);
    }

    if(distanceUnit === 'miles'){
      distanceText = this.hostsAroundMap.dataset.distanceMilesText.replace('{0}', distance);
    }

    return `
      <div class="flex flex-col p-4 rounded-2xl border leading-none">
        <span>${distanceText}</span>
        <span class="mt-2">${timeText}</span>
      </div>
    `;
  }

  //fetch the hosts around the map center
  fetchHosts() {
    if(!this.hostsFetchingAllowed) {
      return false;
    }

    //show the overlay
    this.hostsAroundListContainer.innerHTML = '';
    this.hostsAroundList.classList.add('loading-spinner');

    const center = this.map.getCenter();
    const bounds = this.map.getBounds();

    //radius in meters
    const radius = getMapRadius(bounds._ne, bounds._sw);

    fetch(`${this.hostsAroundMap.dataset.getHostsUrl}?lat=${center.lat}&lng=${center.lng}&radius=${radius}&sort=${this.sortType}&initial_host_name=${this.initialHostName}&initial_host_lat=${this.initialHostLat}&initial_host_lng=${this.initialHostLng}`)
      .then(response => response.text())
      .then(html => {
        this.hostsAroundListContainer.innerHTML = html;

        this.hosts = this.hostsAroundListContainer.querySelectorAll('.host-result');
        this.placeMarkers();
        this.setHoverEffectOnHostsList();
      })
      .catch(error => console.error('Error fetching hosts:', error))
      .finally(() => this.hostsAroundList.classList.remove('loading-spinner'));
  }

  //place the markers on the map
  placeMarkers() {
    this.coords = [];

    //then create a marker for each host
    this.hosts.forEach(host => {
      this.coords.push([host.dataset.lng, host.dataset.lat, host.dataset.id]);
    });

    // Get the initial host ID
    const initialHostId = this.miniMapContainer.dataset.hostId;

    // Clean markers that are not found on the new coords, except the initial host marker
    const existingHostIds = this.coords.map(coord => coord[2]);
    Object.keys(this.markers).forEach(hostId => {
      if (!existingHostIds.includes(hostId) && hostId !== initialHostId) {
        this.markers[hostId].remove();
        delete this.markers[hostId];
      }
    });

    //for each coordinate (an host), create a marker and add it to the map
    this.coords.forEach(coord => {
      const hostId = coord[2];

      // Only if the marker doesn't already exists
      if (!this.markers[hostId]) {
        const hostCard = this.hostsAroundListContainer.querySelector(`[data-id="${hostId}"]`);

        //if the host is the initial host (host page where the visitor is), make the marker bigger and red
        const color = hostId == this.miniMapContainer.dataset.hostId ? '#D00328' : '#12312A';
        const additionalClassName = hostId == this.miniMapContainer.dataset.hostId ? 'initial-marker' : '';

        const markerNode = document.createElement('div');
        markerNode.className = `marker ${additionalClassName}`;

        const popup = new this.maplibregl.Popup({
          offset: 25,
          closeButton: false,
          maxWidth: "none",
        })
        .on('open', () => {
          markerNode.classList.add('highlighted');
        })
        .on('close', () => {
          markerNode.classList.remove('highlighted');
        })
        .setHTML(hostCard.outerHTML);

        const marker = new this.maplibregl.Marker({
          element: markerNode,
          color: color
        })
        .setLngLat(coord)
        .setPopup(popup)
        .addTo(this.map);

        this.markers[hostId] = marker;
      }
    });
  }

  //set the hover effect on the hosts list (move the map to the host and open its popup)
  setHoverEffectOnHostsList() {
    this.hosts.forEach(host => {
      host.addEventListener('mouseenter', () => {
        this.hostsFetchingAllowed = false;
        this.map.easeTo({
          center: [host.dataset.lng, host.dataset.lat],
          speed: 1.5
        });
        this.hostsFetchingAllowed = true;

        //and hide every other popups
        this.closeEveryPopups();

        //trigger the popup marker only if it's not already open
        if(this.markers[host.dataset.id].getPopup() && !this.markers[host.dataset.id].getPopup().isOpen()) {
          this.markers[host.dataset.id].togglePopup();
        }
      });
    });
  }

  //close every popups on the map
  closeEveryPopups(){
    // Close every popups
    Object.values(this.markers).forEach((markerTemp) => {
      let markerPopup = markerTemp.getPopup();
      if (markerPopup && markerPopup.isOpen()) {
        markerTemp.togglePopup();
      }
    });
  }

  //init the sort select to change the sort type and fetch the hosts accordingly
  initSortSelect() {
    this.sortType = 'distance';
    this.sortDropdownText = this.sortWrapper.querySelector('[data-toggle="dropdown"] span');
    if(this.sortDropdownText) {
      this.sortWrapper.querySelectorAll('.sort-item-input').forEach(input => {
        input.addEventListener('change', () => {
          //change the button text to match the option chosen
          this.sortDropdownText.textContent = input.dataset.text;
          this.sortType = input.value;

          //and fetch the hosts
          this.fetchHosts();
        });
      });
    }
  }

  //init the find more hosts button to zoom out and find hosts if none were found
  findMoreHostsButtonClick(){
    this.hostsAroundList.addEventListener('click', (e) => {
      if(e.target.id === 'find-more-hosts') {
        //zoom decrement
        this.map.easeTo({
          zoom: this.map.getZoom() - 2
        });
      }
    });
  }
}

export default HostsAround;
