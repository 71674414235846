import { initCollapsible } from "./modules/collapsible";
import { initScroll } from "./modules/scrolling";
import {ajax, getUserLanguage, smoothScrollTo} from "./modules/utils";
import { initAutocomplete } from "./modules/frontv2/Autocomplete";
import { initStripe } from "./modules/stripe_v2";
import { initQuotationStripe } from "./modules/quotation-stripe";
import Modal from "./modules/frontv2/Modal";
import Wish from "./modules/frontv2/Wish";
import Wishlist from "./modules/frontv2/Wishlist";
import WishlistMap from "./modules/frontv2/WishlistMap";
import GlobalFilters from "./modules/frontv2/GlobalFilters";
import MobileMenu from "./modules/frontv2/MobileMenu";
import intlTelInput from 'intl-tel-input';
import Gift from "./modules/frontv2/Gift";
import BookingProcess from "./modules/frontv2/BookingProcess";
import flatpickr from "flatpickr";
import flatpickrLanguages from "flatpickr/dist/l10n";
import tippy from 'tippy.js';
import 'tippy.js/dist/tippy.css';
import 'tippy.js/themes/light.css';
import { initGoogleEcommerce } from "./modules/google_ecommerce";
import { initSortReviews } from "./modules/frontv2/Reviews";
import {countryPickerDelivery, toggleBillingAddress, hideExpressOnCountryChange} from "./modules/frontv2/PhysicalGiftCard";
import * as Sentry from "@sentry/browser";
import { initCommon } from "./modules/common/common";
import { initContactForm } from "./modules/frontv2/Contact";
import { initApplyDiscountCodesGC } from "./modules/frontv2/ApplyDiscountCodeGC";
import { initFormValidation } from "./modules/common/form-validation";
import { subscribeToNewsLetter } from "./modules/common/newsletter_subscribe";
import { initPhoneValidation } from './modules/phone_validation';
import Faq from "./modules/frontv2/Faq";
import ReviewProcess from "./modules/frontv2/ReviewProcess";
import UserProfile from "./modules/UserProfile";
import TicketDigitValidation from "./modules/frontv2/TicketDigitValidation";
// import { initApplyDiscountCodesExp } from "./modules/frontv2/ApplyDiscountCodeExp";
import Referral from "./modules/frontv2/Referral";
import { initRedirectIn } from "./modules/common/redirect-in";
import { initGlide } from "./modules/common/glide";
import { initElementsVisibilityOnVideoLoaded } from "./modules/common/elements-visibility-video";
import SuggestSlot from "./modules/management/SuggestSlot";
import KioskBookingProcess from "./modules/kiosk/KioskBookingProcess";
import Kiosk from "./modules/kiosk/Kiosk";
import MapHostSearch from "./modules/frontv2/MapHostSearch";
import { initCountDown } from "./modules/countdown";
import { ExtraCheckout } from "./modules/frontv2/ExtraCheckout";
import { initSwiper } from "./modules/common/init-swiper";
import { initHomeSeoBlock } from "./modules/frontv2/init-home-seo-block";
import { initTabs } from "./modules/common/initTabs";
import { initDrops } from "./modules/common/drop";
import { initGuidelines } from "./modules/frontv2/guidelines";
import { initNps } from "./modules/frontv2/initNps";
import { thankYouPage } from "./modules/frontv2/thankYouPage";
import { initHorizontalAutoscroll } from "./modules/common/horizontal-autoscroll";
import { initCardsEffect } from "./modules/frontv2/CardsEffect";
import { initApplyExistingGC, initCheckoutCode } from "./modules/frontv2/ApplyCheckoutCode";
import BookingRecap from "./modules/frontv2/Booking/BookingRecap";
import DeparturePoints from "./modules/frontv2/DeparturePoint/DeparturePoints";
import { initSeeMoreContent } from "./modules/frontv2/seeMoreContent";
import { initMaplibre } from "./modules/frontv2/Maplibre";
import LoadHostExperiences from "./modules/frontv2/LoadHostExperiences";
import { checkPasswordPattern, setupSetPasswordToggle, triggerConversion } from "./modules/frontv2/Checkout/checkout-utils";
import { initFreeCheckout } from "./modules/frontv2/Checkout/FreeCheckout";

document.addEventListener('DOMContentLoaded', () => {
  // Init Sentry JS
  if(document.body.dataset.env === 'prod') {
    Sentry.init({ dsn: 'https://b14c92e307604f4f8f454ebdd3eab1ef@sentry.io/1441102' });
  }

  flatpickr.localize(flatpickrLanguages[getUserLanguage()]);

  //common stuff
  initCommon();

  //init any glide
  initGlide();

  const allDropdowns = document.querySelectorAll('.dropdown');
  if (allDropdowns.length > 0) {
    allDropdowns.forEach(function (dropdown) {
      const buttons = dropdown.querySelectorAll('button[data-toggle="dropdown"]');
      if (buttons) {
        buttons.forEach(function (button) {
          button.addEventListener('click', function () {
            allDropdowns.forEach(function (otherDropdown) {
              if (dropdown !== otherDropdown) {
                otherDropdown.classList.remove('active');
              }
            });
            dropdown.classList.toggle('active');
          });
        });
      }
    });
  }


  const allDropups = document.querySelectorAll('.dropup');
  if (allDropups.length > 0) {
    allDropups.forEach(function (dropup) {
      const buttons = dropup.querySelectorAll('button[data-toggle="dropup"]');
      if (buttons) {
        buttons.forEach(function (button) {
          button.addEventListener('click', function () {
            allDropups.forEach(function (otherDropdown) {
              if (dropup !== otherDropdown) {
                otherDropdown.classList.remove('active');
              }
            });
            dropup.classList.toggle('active');
          });
        });
      }
    });
  }

  window.addEventListener('click', function (e) {
    // if click is outside dropdown
    if (!e.target.classList.contains('dropdown') && e.target.closest('.dropdown') === null) {
      allDropdowns.forEach(function (dropdown) {
        dropdown.classList.remove('active');
      })
    }
    // if click is outside dropup
    if (!e.target.classList.contains('dropup') && e.target.closest('.dropup') === null) {
      allDropups.forEach(function (dropup) {
        dropup.classList.remove('active');
      })
    }
  });

  Modal.bindAllModals();

  //Set Cart in session and trigger login modal (to avoid page reloading if user isn't connected)
  const btnConnect = document.querySelectorAll('[data-connect="true"]');
  if (btnConnect.length > 0) {
    for (let i = 0; i < btnConnect.length; i++) {

      btnConnect[i].addEventListener('click', function (e) {
        e.preventDefault();
        // get form
        const form = btnConnect[i].closest('form');
        if (form.checkValidity() !== false) {
          let url = btnConnect[i].dataset.url;

          const formData = new FormData(form);
          formData.append('ajax', 'true');

          ajax(url, () => {
            Modal.toggleModal('#signupModal');
          }, 'POST', formData, { "X-CSRF-Token": document.querySelector('meta[name="csrfToken"]')?.content });

          return false;
        }
      });
    }
  }

  initCollapsible();
  initScroll();

  new BookingProcess();

  initAutocomplete();

  const btnOpenMobileNav = document.getElementById('open-mobile-nav');
  const mobileNav = document.getElementById('mobile-fullscreen-nav');
  if (btnOpenMobileNav && mobileNav) {
    btnOpenMobileNav.addEventListener('click', function () {
      if (!mobileNav.classList.contains('active')) {
        mobileNav.classList.add('active');
      }
    });
    const closeMobileNav = mobileNav.querySelector('button.close');
    if (closeMobileNav) {
      closeMobileNav.addEventListener('click', function () {
        mobileNav.classList.remove('active');
      });
    }
  }


  const langSelects = document.querySelectorAll(".select-language");
  if (langSelects.length > 0) {
    langSelects.forEach(function (wrapper) {
      const select = wrapper.querySelector('select');
      if (select.options.length > 0) {
        wrapper.dataset.current = select.options[select.selectedIndex].dataset.isoFlag;
        select.addEventListener('change', function () {
          wrapper.dataset.current = select.options[select.selectedIndex].dataset.isoFlag;
        });
      }
    });
  }


  const langSwitcher = document.querySelector(".switch-lang");
  if (langSwitcher) {
    const select = langSwitcher.querySelector('select');
    langSwitcher.dataset.current = select.options[select.selectedIndex].dataset.isoFlag;
    select.addEventListener('change', function () {
      langSwitcher.dataset.current = select.options[select.selectedIndex].dataset.isoFlag;
      if (select.options[select.selectedIndex].dataset.link) {
        window.location.href = select.options[select.selectedIndex].dataset.link;
      }
    });
  }


  if (document.getElementById('checkouta')) {
    initStripe();
  }

  if(document.getElementById('quotation-checkout')){
    initQuotationStripe();
  }

  initMaplibre();

  const seeMoreWrappers = document.querySelectorAll('.see-more-wrapper');
  if (seeMoreWrappers.length > 0) {
    seeMoreWrappers.forEach(function (wrapper) {
      const seeMoreContent = wrapper.querySelector('.see-more-content');
      const maxHeight = wrapper.dataset.maxHeight ? wrapper.dataset.maxHeight : 125;
      const seeMoreTrigger = wrapper.querySelector('.see-more-trigger');

      // if no need see more trigger
      if (seeMoreContent.offsetHeight <= maxHeight) {
        seeMoreTrigger.style.display = 'none';
        return;
      }

      if (seeMoreContent && seeMoreTrigger) {
        seeMoreContent.style.overflowY = 'hidden';
        seeMoreContent.style.maxHeight = maxHeight + 'px';

        seeMoreTrigger.addEventListener('click', function () {
          if (seeMoreContent.style.maxHeight === 'none') {
            seeMoreContent.style.maxHeight = maxHeight + 'px';
            seeMoreTrigger.innerText = seeMoreTrigger.dataset.more;
          } else {
            seeMoreContent.style.maxHeight = 'none';
            seeMoreTrigger.innerText = seeMoreTrigger.dataset.less;
          }
        });
      }
    });
  }


  const scrollIntoButtons = document.querySelectorAll('[data-scroll-into-view]');
  if (scrollIntoButtons.length > 0) {
    scrollIntoButtons.forEach(function (button) {
      // console.log(button, button.dataset.to);
      if (button.dataset.to) {
        button.addEventListener('click', function () {
          //Close modal if there's a model opened
          const activeModal = document.querySelector('.modal.active');
          if(activeModal){
            document.body.classList.remove('modal-active');
            activeModal.classList.remove("active");
          }

          const el = document.querySelector(button.dataset.to);
          if (el) {
            // console.log('scrolling to ', el);
            smoothScrollTo(el);
            // el.scrollIntoView({behavior: "smooth", block: "start"});
          }
        })
      }
    });
  }

  new GlobalFilters();

  Wish.bindWishButtons();

  new Wishlist();
  new WishlistMap();

  const messages = document.querySelectorAll('div.message');
  if (messages.length > 0) {
    messages.forEach(function (message) {
      message.querySelector('button.close').addEventListener('click', function () {
        message.remove();
      });
    });
  }

  const phoneInputs = document.querySelectorAll('input.intl-phone-input');
  if (phoneInputs.length > 0) {
    phoneInputs.forEach(function (input) {
      intlTelInput(input, {
        initialCountry: input.dataset.country,
        utilsScript: "https://cdnjs.cloudflare.com/ajax/libs/intl-tel-input/16.0.4/js/utils.js"
      });
    });
  }

  initFormValidation();

  const elementsOutsideView = document.querySelectorAll('[data-show-if-outside-of-view]');
  if (elementsOutsideView.length > 0) {
    window.addEventListener('scroll', function (e) {
      elementsOutsideView.forEach(function (el) {
        const elementToShow = document.querySelector(el.dataset.showIfOutsideOfView);
        if (elementToShow) {
          const rect = el.getBoundingClientRect();
          if (rect.top < 80) {
            if (elementToShow) {
              elementToShow.classList.remove('invisible');
              elementToShow.classList.add('visible');
            }
          } else {
            elementToShow.classList.add('invisible');
            elementToShow.classList.remove('visible');
          }
        }
      })
    }, {
      capture: true,
      passive: true
    });
  }

  const updateUserReminderPreference = (userWantsToBeRemindedBySMS, cartTransactionId) => {
    let formData = new FormData();
    formData.append('wants', userWantsToBeRemindedBySMS);
    formData.append('cart_transaction_id', cartTransactionId);

    ajax("/checkout/update/user/pref", function () {
    }, 'POST', formData);
  };

  const switchRemindSms = document.querySelector('input#switch-remind-sms');
  if (switchRemindSms) {
    switchRemindSms.addEventListener('change', function () {
      updateUserReminderPreference(this.checked, switchRemindSms.dataset.transactionId);

      const containerPhoneNumber = document.querySelector('#container-phone-number');
      if(containerPhoneNumber){
        if(this.checked){
          containerPhoneNumber.classList.remove('hidden');
        } else {
          containerPhoneNumber.classList.add('hidden');
        }
      }
    });
  }

  new Gift();

  initGoogleEcommerce();

  //Only for HOME
  const changeCountryButtons = document.querySelectorAll(".changeCountry");
  if(changeCountryButtons.length > 0){
    changeCountryButtons.forEach(function(button){
      button.addEventListener("click", function(){
        if(!button.classList.contains("active")){
          const currentActiveButton = document.querySelector(".changeCountry.active");
          if(currentActiveButton){
            currentActiveButton.classList.toggle("active");
          }

          button.classList.toggle("active");

          const currentActiveRegions = document.querySelectorAll(".regionHome:not(.hidden)");
          if(currentActiveRegions){
            currentActiveRegions.forEach(function(region){
              region.classList.toggle("hidden");
            });
          }

          const regionsToActive = document.querySelectorAll(".regionHome[data-country-id='" + button.dataset.countryId + "']");
          if(regionsToActive){
            regionsToActive.forEach(function(region){
              region.classList.toggle("hidden");
            });
          }
        }
      });
    });
  }

  //ONLY FOR HOME
  const mobileBarArroundMe = document.querySelector('.mobile-fixed-bar-arround-me');
  if(mobileBarArroundMe) {
    const submitBtn = mobileBarArroundMe.querySelector('button');
    submitBtn.addEventListener('click', function() {
      // console.log('click get position', navigator.geolocation);
      if (navigator.geolocation) {
        navigator.geolocation.getCurrentPosition(function (pos) {
          console.log(pos);
          mobileBarArroundMe.querySelector('input[name=lat]').value = pos.coords.latitude;
          mobileBarArroundMe.querySelector('input[name=lng]').value = pos.coords.longitude;

          // then submit form
          mobileBarArroundMe.querySelector('form').submit();
        }, function(err) {
          console.error(err);
        }, {
          timeout: 5000
        });
      } else {
        console.warn('No geolocation available');
      }
    })
  }

  tippy('[data-tippy-content]', {
    theme: 'light'
  });

  initSortReviews();

  //GIFT PAGE
  countryPickerDelivery("[name='delivery[country_id]']", {
    totalPriceContainer: '.amount',
    deliveryPriceContainer: '.amount_delivery',
    amountInput: '[name="amount"]',
    radio: '[name="amountChoice"]'
  });

  //GIFT PAGE
  toggleBillingAddress("[name='billing-different']", {
    form: '#form-billing-address'
  });

  //GIFT PAGE - BOX
  hideExpressOnCountryChange("[name='delivery[country_id]']", ".expressD", ".classicD");

  new MobileMenu();

  new Faq();

  const closeInfoCards = document.querySelectorAll('.close-info-card');
  if(closeInfoCards){
    closeInfoCards.forEach(function(close){
      close.addEventListener('click', function(){
        close.parentNode.remove();
      });
    });
  }

  new ReviewProcess();
  // new TDVFilters();

  //Logic to apply promo codes for gift cards
  initApplyDiscountCodesGC();

  //Logic for user profile section
  new UserProfile();

  //For ticket validation
  new TicketDigitValidation();

  const newsletterForm = document.getElementById('newsletterForm');
  if(newsletterForm){
    subscribeToNewsLetter(newsletterForm);
  }

  initPhoneValidation();
  initContactForm();

  //logic for applying a discount/gc code on an experience checkout
  initCheckoutCode();
  initApplyExistingGC();

  //logic for referral code blocks
  new Referral();

  //logic to redirect user after x seconds on a page if a specific data attribute is found
  initRedirectIn();

  //Corporate page video loading logic
  initElementsVisibilityOnVideoLoaded();

  //used by hosts to suggest timeslots
  new SuggestSlot();

  if(document.getElementById('kiosk-booking')){
    new KioskBookingProcess();
  }

  if(document.getElementById('kiosk-pay')){
    new Kiosk();
  }

  //init search map with hosts
  const searchMapHostsWrapper = document.getElementById('search-map-hosts');
  if(searchMapHostsWrapper){
    if(typeof maplibregl === 'undefined'){
      import('maplibre-gl').then(maplibregl => {
        new MapHostSearch(searchMapHostsWrapper, maplibregl);
      });
    }
    //otherwise it means that maplibregl was loaded through CDN
    else{
      new MapHostSearch(searchMapHostsWrapper, maplibregl);
    }
  }

  const reservedSpotsCountdown = document.getElementById('reserved-spots-countdown');
  if(reservedSpotsCountdown){
    initCountDown('reserved-spots-countdown', reservedSpotsCountdown.dataset.now, reservedSpotsCountdown.dataset.endTime);
  }

  //init choosing of extras at cart checkout
  new ExtraCheckout();

  //logic for the departure points
  new DeparturePoints();

  //carousel js logic
  initSwiper();

  //seo black block logic
  initHomeSeoBlock();
  initTabs();

  //init dropdowns/dropups logic for gift menu items
  const giftMenu = document.getElementById('gift-menu');
  if(giftMenu){
    initDrops(giftMenu);
  }

  let iconGuidelines = document.querySelector('#icons-guidelines');
  if (iconGuidelines) {
    initGuidelines();
  }

  //Set NPS page
  initNps();

  thankYouPage();

  //slowly auto scroll horizontal (only used for brands in the brag block for now)
  initHorizontalAutoscroll();

  //init the logic for the cards effect on top of the page (/business page)
  initCardsEffect();

  //booking recap logic
  new BookingRecap();

  initSeeMoreContent();

  new LoadHostExperiences();

  //thank you page, tracking & conversion logic
  const onThankYouPage = document.getElementById('thank-you-page');
  if(onThankYouPage){
    const triggerAnalyticsDiv = document.getElementById('trigger-analytics');
    if(triggerAnalyticsDiv){
      triggerConversion(triggerAnalyticsDiv);
    }
  }

  //on checkout page, setup password toggle and check password pattern
  const setPasswordWrapper = document.getElementById('set-password-wrapper');
  if(setPasswordWrapper){
    //setup password toggle if user decided to setup password
    //shows/hide password inputs and set required attribute to true/false
    setupSetPasswordToggle();

    //check password pattern (length, symbols etc...)
    checkPasswordPattern(setPasswordWrapper, setPasswordWrapper.querySelector('input[name=password]'));
  }

  //init free checkout logic
  initFreeCheckout();
});
