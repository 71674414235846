import flatpickr from "flatpickr";
import Modal from "../frontv2/Modal";
import Message from "../Message";
import { ajax } from "../utils";

class SuggestSlot {
  constructor() {
    this.wrapper = document.getElementById('suggest-slots');
    if(this.wrapper){
      this.slotChecked = false;
      this.suggestionsErrorsWrapper = this.wrapper.querySelector('#suggestions-errors');

      this.langPicker = this.wrapper.querySelector('#lang_id');
      if(this.langPicker){
        this.langId = this.langPicker.value;
        this.initLangPicker();
      }

      this.datepicker = this.wrapper.querySelector('.flatpickr');
      this.dateInput = this.wrapper.querySelector('input[name=date][type=hidden]');
      if(this.datepicker && this.dateInput){
        this.initDatePicker();
      }

      this.lastClickedSuggestionBlock = null;
      this.suggestionSlotBlocks = this.wrapper.querySelectorAll('.slot-suggestion');
      if(this.suggestionSlotBlocks){
        this.initSuggestionBlockOnClick();
      }

      //wrapper also contains custom slot option
      this.slotsWrapper = this.wrapper.querySelector('#slots-wrapper');

      //container only contains existing slots options
      this.slotsContainer = this.wrapper.querySelector('#slots-container');

      this.customSlotButton = this.wrapper.querySelector('#custom-slot');
      this.customSlotInput = this.wrapper.querySelector('#radio-custom-slot');
      this.customSlotStartWrapper = this.wrapper.querySelector('#custom-slot-start-wrapper');
      this.customSlotStartSelect = this.wrapper.querySelector('#custom-slot-start-wrapper select');
      if(this.customSlotButton && this.customSlotInput && this.customSlotStartWrapper && this.customSlotStartSelect){
        this.initCustomSlot();
      }

      this.suggestionsWrapper = this.wrapper.querySelector('#suggestions-wrapper');
      this.form = this.wrapper.querySelector('#create-suggestion-form');
      this.suggestButton = this.wrapper.querySelector('#suggest-button');
      if(this.suggestionsWrapper && this.form && this.suggestButton){
        this.performSuggestOnSubmit();
      }

      //delete a suggestion on red cross click
      this.bindDeleteButtons();

      //disable submit button on form submit
      this.initMainForm();
    }
  }

  //init flatpickr & perform a search every time the date changes
  initDatePicker() {
    const _this = this;
    // const localeDateOptions = { month: 'short', day: 'numeric' };

    const defaultDate = _this.datepicker.hasAttribute('data-default-date') ? _this.datepicker.dataset.defaultDate : new Date();

    //min Date is 3 days before
    const minDate = _this.datepicker.dataset.minBeforeDate;

    //max Date is 3 days after
    const maxDate = _this.datepicker.dataset.maxAfterDate;

    this.flatpickrInstance = flatpickr(_this.datepicker, {
      dateFormat: "Y-m-d",
      defaultDate: defaultDate,
      minDate: minDate,
      maxDate: maxDate,
      showMonths: _this.datepicker.dataset.showMonths,
      inline: true,
      onChange: function(){
        _this.dateInput.value = _this.datepicker.value;

        //ajax call to get timeslots at the selected date
        _this.performSlotsSearch();
      },
      onReady: function(){
        _this.dateInput.value = _this.datepicker.value;

        //ajax call to get timeslots at the selected date
        _this.performSlotsSearch();
      }
    });
  }

  initSuggestionBlockOnClick(){
    this.suggestionSlotBlocks.forEach(block => {
      block.addEventListener('click', () => {
        this.lastClickedSuggestionBlock = block;
      });
    });
  }

  //performs a search every time the lang is changed
  initLangPicker(){
    this.langPicker.addEventListener('change', () => {
      this.langId = this.langPicker.value;
      this.performSlotsSearch();
    });
  }

  //search for slots at the specified date
  performSlotsSearch(){
    ajax(this.datepicker.dataset.slotsUrl + '?date=' + this.datepicker.value + '&persons=' + this.datepicker.dataset.bookingPersons + '&langId=' + this.langId, (data) => {
      const {timeslots} = data;

      let html = '';
      //build slots options
      timeslots.forEach(slot => {
        html += `
          <input id="radio-${slot.id}" name="slot_id" type="radio" class="hidden slot" value="${slot.id}">
          <label for="radio-${slot.id}" class="bg-white rounded-lg border mr-2 px-8 py-4 cursor-pointer my-2 items-center">
            ${slot.start} - ${slot.end}
          </label>`;
      });

      //add it to DOM
      this.slotsContainer.innerHTML = html;

      //hide custom slot creation when a slot is checked
      this.initSlotsClick();
    }, 'GET', {}, {
      "X-CSRF-Token": document.querySelector('meta[name="csrfToken"]')?.content
    }, true, true);
  }

  //hide custom slot creation when an existing slot is checked
  initSlotsClick(){
    const slots = this.slotsContainer.querySelectorAll("[name='slot_id']");
    slots.forEach(slot => {
      slot.addEventListener('change', () => {
        if(slot.checked){
          this.customSlotStartWrapper.classList.add('hidden');
          this.customSlotStartSelect.disabled = true;

          this.slotChecked = true;
        }
      });
    });
  }

  //shows the custom slot start option when custom slot option clicked
  initCustomSlot(){
    this.customSlotButton.addEventListener('click', () => {
      this.customSlotStartWrapper.classList.remove('hidden');
      this.customSlotStartSelect.disabled = false;

      this.slotChecked = true;
    });
  }

  //create the slot suggestion
  performSuggestOnSubmit(){
    this.form.addEventListener('submit', (e) => {
      e.preventDefault();

      //check if we have all the data we need
      if(!this.slotChecked){
        Message.error(this.slotsContainer.dataset.errorMessage);
        return false;
      }

      this.suggestButton.disabled = true;
      let formData = new FormData(this.form);

      //create the booking suggestion in database
      ajax(this.form.action, (data) => {
        if(data.success){
          //replace an empty suggestion by the timeslot previouslyfound/created
          this.fillSuggestionSlot(data.suggestion);

          //close the modal
          Modal.closeAllModals();

          //and reset the modal
          this.resetModal();

          //remove the error messages saying there are not enough suggestions
          this.suggestionsErrorsWrapper.classList.add('hidden', 'md:invisible');
        }
        else{
          Message.error(data.message);
        }

        this.suggestButton.disabled = false;
      },
      'POST', formData, {
        "X-CSRF-Token": document.querySelector('meta[name="csrfToken"]')?.content
      }, true, true);
    });
  }

  fillSuggestionSlot(suggestion){
    let suggestionBlock =  '';

    //if the last suggestion block clicked was already a suggestion, then modify this one since we assume it is to modify
    if(this.lastClickedSuggestionBlock.classList.contains('filled')){
      suggestionBlock = this.lastClickedSuggestionBlock;

      //in this case, we also want to delete in DB the suggestion that was made in this block
      this.deleteSuggestion(this.lastClickedSuggestionBlock.dataset.suggestionId);
    }
    //if the last suggestion block clicked was NOT already a suggestion, then take the first empty block for a better UI
    else{
      suggestionBlock = this.suggestionsWrapper.querySelector('.slot-suggestion:not(.filled)');
    }

    //replace the content by the suggestion data
    if(suggestionBlock){
      suggestionBlock.innerHTML = `
        <span class="font-semibold">${suggestion.day_format}</span>
        <span>${suggestion.hour_format}
          <span class="flag flag-xs "><img src="${this.wrapper.dataset.flagRoute.replace('{0}', suggestion.lang.iso_flag)}" width="28" class="flag flag-sm "></span>
        </span>

        <span class="delete-suggestion" data-suggestion-id="${suggestion.id}">
            <i class="icon icon-cross text-white text-xs px-3"></i>
        </span>
      `;

      suggestionBlock.classList.add('filled');
      suggestionBlock.dataset.suggestionId = suggestion.id;
      this.bindDeleteButtons();
    }
  }

  deleteSuggestion(suggestionId){
    let formData = new FormData();
    formData.append('suggestionId', suggestionId);

    ajax(this.wrapper.dataset.deleteSuggestionUrl, () => {
    }, 'POST', formData, {
      "X-CSRF-Token": document.querySelector('meta[name="csrfToken"]')?.content
    }, true, false);
  }

  //delete a suggestion on red cross click
  bindDeleteButtons(){
    this.deleteButtons = this.wrapper.querySelectorAll('.delete-suggestion');

    this.deleteButtons.forEach(deleteButton => {
      deleteButton.addEventListener('click', (e) => {
        e.stopPropagation();

        //delete the suggestion with ajax call
        this.deleteSuggestion(deleteButton.dataset.suggestionId);

        //reset the content of the block to empty
        this.resetSuggestBlock(deleteButton.parentElement);
      })
    });
  }

  //reset all fields in the suggestion modal
  resetModal(){
    this.flatpickrInstance.setDate(this.datepicker.dataset.defaultDate, true);

    //uncheck custom slot if checked
    this.customSlotInput.checked = false;

    //hide custom slot creation option
    this.customSlotStartWrapper.classList.add('hidden');
    this.customSlotStartSelect.disabled = true;

    this.slotChecked = false;
  }

  //reset the content of the block to empty
  resetSuggestBlock(suggestionBlock){
    suggestionBlock.innerHTML = `+ ${suggestionBlock.dataset.initialText} #${suggestionBlock.dataset.position}`;

    suggestionBlock.classList.remove('filled');
    delete suggestionBlock.dataset.suggestionId;
  }

  initMainForm(){
    const mainForm = this.wrapper.querySelector('#main-form');
    const mainFormSubmitButton = this.wrapper.querySelector('#main-form [type=submit]');
    if(mainForm && mainFormSubmitButton){
      mainForm.addEventListener('submit', (e) => {
        e.preventDefault();

        const suggestionsMade = this.wrapper.querySelectorAll('.slot-suggestion.filled').length;
        if(suggestionsMade == 0){
          this.suggestionsErrorsWrapper.textContent = this.suggestionsErrorsWrapper.dataset.noSuggestions;
          this.suggestionsErrorsWrapper.classList.remove('hidden', 'md:invisible');

          return false;
        }
        else if(suggestionsMade < 2 && this.wrapper.dataset.minSuggestions > 1){
          this.suggestionsErrorsWrapper.textContent = this.suggestionsErrorsWrapper.dataset.notEnoughSuggestions;
          this.suggestionsErrorsWrapper.classList.remove('hidden', 'md:invisible');

          return false;
        }

        this.suggestionsErrorsWrapper.classList.add('hidden', 'md:invisible');

        if(mainForm.checkValidity()){
          mainFormSubmitButton.disabled = true;
          mainForm.submit();
        }
      });
    }
  }
}

export default SuggestSlot;
