const initElementsVisibilityOnVideoLoaded = () => {
  const video = document.querySelector('video');
  const showOnVideoLoadedElts = document.querySelectorAll('.show-on-video-loaded');
  const hideOnVideoLoadedElts = document.querySelectorAll('.hide-on-video-loaded');
  if(video && (showOnVideoLoadedElts || hideOnVideoLoadedElts)){
    video.addEventListener('canplay', () => {
      showOnVideoLoadedElts.forEach(elt => {
        elt.classList.remove('hidden');
      });
      hideOnVideoLoadedElts.forEach(elt => {
        elt.classList.add('hidden');
      });
    });
  }
}

export { initElementsVisibilityOnVideoLoaded }
