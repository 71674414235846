const initGuidelines = () => {
    const iconGuidelines = document.querySelector('#icons-guidelines');
    if (iconGuidelines) {

        iconGuidelines.children.forEach(container => {
            const icon = container.querySelector('.icon');

            if (icon) {
                const iconContainer = icon.parentElement;
                iconContainer.classList.add('cursor-pointer');
                iconContainer.addEventListener('click', () => {
                    const iconHTML = icon.outerHTML;

                    const input = document.createElement('input');
                    input.setAttribute('value', iconHTML);
                    document.body.appendChild(input);
                    input.select();
                    
                    navigator.clipboard.writeText(input.value).then(() => {
                        console.log('Copied to clipboard');
                    }).catch(err => {
                        console.error('Failed to copy to clipboard', err);
                    });

                    document.body.removeChild(input);
                });
            }
        });
    }


};

export { initGuidelines };