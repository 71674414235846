import { ajax } from "../utils";
import Modal from "./Modal";
import flatpickr from "flatpickr";
import flatpickrLanguages from "flatpickr/dist/l10n";

class Gift {
  constructor() {
    this.wrapper = document.getElementById("gift-form");
    const _this = this;
    if (this.wrapper) {
      this.datepicker = this.wrapper.querySelector('#date-send-gift');
      if(this.datepicker) {
        this.flatpickr = this.initDatePicker(this.datepicker);
      }
      
      this.type = this.wrapper.querySelectorAll('input[type=radio][name=type]');
      this.amounts = this.wrapper.querySelectorAll("input[type=radio][name=amountChoice]");
      this.amountInput = this.wrapper.querySelector("input[name=amount]");
      this.otherAmount = this.wrapper.querySelector("#otherAmountText");
      this.designWrap = this.wrapper.querySelector("#designWrap");
      this.priceContainer = this.wrapper.querySelector('.amount');

      if(this.designWrap) {
        this.designCards = this.designWrap.querySelectorAll(".card");
      }
      this.stickyImage = this.wrapper.querySelector(".sticky");
      this.currentType = 'print';

      if (this.type) {
        this.type.forEach(function (radio) {
          radio.addEventListener("change", function() {
            _this.onTypeChange(this.value);
          });
          if(radio.checked) {
            _this.currentType = radio.value;
          }
        });
      }

      this.onTypeChange(this.currentType);

      if (this.amounts && this.amountInput && this.otherAmount) {
        this.amounts.forEach(function (radio) {
          radio.addEventListener("change", () => _this.onAmountChange(radio));
        });

        // this.amountInput.addEventListener("keyup", () => this.onAmountKeyUp());

        this.otherAmount.addEventListener("click", () => this.onOtherAmountClick());
        // this.amountInput.addEventListener('change', function() {
        //   _this.updatePriceDisplayed();
        // });
      }

      if (this.designWrap && this.designCards) {
        this.designCards.forEach(function (card) {
          card.addEventListener("click", () => _this.onDesignClick(card));
        });
      }
    }

    const chooseWrapper = document.getElementById("gift-choose");
    if (chooseWrapper) {
      this.initRedirectionModal();
    }

    this.landingWrapper = document.getElementById("gift-landing");
    if(this.landingWrapper){
      const designWrap = this.landingWrapper.querySelector("#designWrap");
      const designCards = this.landingWrapper.querySelectorAll(".card");
      const codeInput = this.landingWrapper.querySelector("input[name=gift_code]");

      if (designWrap && designCards) {
        designCards.forEach(function (card) {
          card.addEventListener("click", () => _this.onDesignClickLanding(card));
        });
      }

      if(codeInput){
        codeInput.addEventListener("focus", () => codeInput.placeholder = "");
        codeInput.addEventListener("blur", () => codeInput.placeholder = codeInput.dataset.placeholder);
      }
    }
  }

  initDatePicker(input) {
    // flatpickr.localize(flatpickrLanguages[document.body.dataset.lang.substr(0, 2)]);

    return flatpickr(input, {
      altInput: true,
      altFormat: "l, M J, Y",
      dateFormat: "Y-m-d",
      minDate: new Date(),
      defaultDate: new Date(),
      disableMobile: true
    });
  }

  onTypeChange(value) {
    const inputFromName = this.wrapper.querySelector('input[name=from_name]');
    const inputForName = this.wrapper.querySelector('input[name=for_name]');
    const inputSendTo = this.wrapper.querySelector('input[name=send_to]');

    if(inputFromName && inputForName && inputSendTo) {
      if (value === 'egift') {
        inputFromName.required = true;
        inputForName.required = true;
        inputSendTo.required = true;
      } else {
        inputFromName.required = false;
        inputForName.required = false;
        inputSendTo.required = false;
      }
    }

    const elementsEgift = this.wrapper.querySelectorAll('.hideOnPrint');
    elementsEgift.forEach(function(item) {
      if(value === 'print') {
        item.classList.add('hidden');
      } else {
        item.classList.remove('hidden');
      }
    });
    const elementsPrint = this.wrapper.querySelectorAll('.hideOnEgift');
    elementsPrint.forEach(function(item) {
      if(value === 'egift') {
        item.classList.add('hidden');
      } else {
        item.classList.remove('hidden');
      }
    });

    // const elementsToToggle = this.wrapper.querySelectorAll(".hideOnPrint, .hideOnEgift");
    // elementsToToggle.forEach((element) => {
    //   const input = element.querySelector('input');
    //   if(value === 'egift') {
    //     if(element.classList.contains('hideOnEgift')) {
    //       element.classList.add('hidden');
    //       if (input) {
    //         input.required = false;
    //       }
    //     } else {
    //       element.classList.remove('hidden');
    //       if (input) {
    //         input.required = true;
    //       }
    //     }
    //   } else {
    //     if(element.classList.contains('hideOnPrint')) {
    //       element.classList.add('hidden');
    //       if (input) {
    //         input.required = false;
    //       }
    //     } else {
    //       element.classList.remove('hidden');
    //       if (input) {
    //         input.required = true;
    //       }
    //     }
    //   }
    //   // element.classList.toggle("hidden");
    // });

    if(this.designWrap) {
      const card = this.designWrap.querySelector(".card.active");
      const type = this.wrapper.querySelector('input[type=radio][name=type]:checked');

      this.changeStickyImage(card.dataset.design, type.value);
    }
  }

  onAmountChange(radio) {
    this.amountInput.value = radio.value;

    this.amountInput.parentNode.classList.add("hidden");
    this.otherAmount.classList.remove("hidden");
    // this.updatePriceDisplayed();
  }

  updatePriceDisplayed() {
    if(this.priceContainer) {
      this.priceContainer.innerHTML = Number(this.amountInput.value).toLocaleString(undefined, {
        style: 'currency',
        currency: 'EUR'
      });
    }
  }

  // onAmountKeyUp(){
  //   const amountChoice = this.wrapper.querySelector("input[name=amountChoice][value='"+ this.amountInput.value + "']");
  //   if(amountChoice){
  //     amountChoice.checked = true;
  //   }
  //   else{
  //     const amountChoiceSelected = this.wrapper.querySelector("input[name=amountChoice]:checked");
  //     if(amountChoiceSelected){
  //       amountChoiceSelected.checked = false;
  //     }
  //   }
  // }

  onDesignClick(card) {
    this.changeDesign(card, this.wrapper);

    const type = this.wrapper.querySelector('input[type=radio][name=type]:checked');

    this.changeStickyImage(card.dataset.design, type.value);
  }

  onDesignClickLanding(card){
    this.changeDesign(card, this.landingWrapper);
  }

  changeDesign(card, wrapper){
    const currentChoice = wrapper.querySelector(".card.active");
    if (currentChoice) {
      currentChoice.classList.remove("active");
    }

    card.classList.add("active");

    const design = wrapper.querySelector("input[name=design]");
    if (design) {
      design.value = card.dataset.design;
    }
  }

  onOtherAmountClick() {
    this.amountInput.parentNode.classList.toggle("hidden");
    this.otherAmount.classList.toggle("hidden");
    this.amountInput.focus()
    // setTimeout(() => this.amountInput.focus(), 200);


    const amountChecked = this.wrapper.querySelector("input[type=radio][name=amountChoice]:checked");
    if (amountChecked) {
      amountChecked.checked = false;
    }
  }

  initRedirectionModal() {
    //Set Cart in session and trigger login modal (to avoid page reloading if user isn't connected)
    const btnConnect = document.querySelectorAll('[data-redirection="true"]');
    if (btnConnect) {
      for (let i = 0; i < btnConnect.length; i++) {
        btnConnect[i].addEventListener('click', function (e) {
          e.preventDefault();
          let url = btnConnect[i].dataset.url;

          ajax(url, () => {
            Modal.toggleModal('#signupModal');
          }, 'POST', {}, { "X-CSRF-Token": document.querySelector('meta[name="csrfToken"]')?.content }, true, false);

          return false;
        });
      }
    }
  }

  changeStickyImage(design, type) {
    if (this.stickyImage) {
      // switch (design) {
      //   case 'cellars':
      //     this.stickyImage.src = this.stickyImage.dataset.cellars;
      //     break;
      //   case 'connoisseur':
      //     this.stickyImage.src = this.stickyImage.dataset.connoisseur;
      //     break;
      //   case 'colorBlock':
      //     this.stickyImage.src = this.stickyImage.dataset.colorBlock;
      //     break;
      // }
      if(design === "cellars" && type === "print"){
        this.stickyImage.src = this.stickyImage.dataset.cellarsPrint;
      }
      else if(design === "connoisseur" && type === "print"){
        this.stickyImage.src = this.stickyImage.dataset.connoisseurPrint;
      }
      else if(design === "colorBlock" && type === "print"){
        this.stickyImage.src = this.stickyImage.dataset.colorBlockPrint;
      }
      else if(design === "cellars" && type === "egift"){
        this.stickyImage.src = this.stickyImage.dataset.cellarsEgift;
      }
      else if(design === "connoisseur" && type === "egift"){
        this.stickyImage.src = this.stickyImage.dataset.connoisseurEgift;
      }
      else if(design === "colorBlock" && type === "egift"){
        this.stickyImage.src = this.stickyImage.dataset.colorBlockEgift;
      }
    }
  }
}

export default Gift;
