import flatpickr from "flatpickr";
import flatpickrLanguages from "flatpickr/dist/l10n";

class DateVisitor {
    constructor(wrapper = document) {
        this.wrapper = wrapper;

        this.delayBeforeSwitchingTab = 250;

        this.visitorsText = this.wrapper.querySelector('.container-calendar-visitor__visitor-text');
        this.adultspicker = this.wrapper.querySelector('.container-calendar-visitor__adults-input');
        this.childrenpicker = this.wrapper.querySelector('.container-calendar-visitor__children-input');
        this.infantspicker = this.wrapper.querySelector('.container-calendar-visitor__infants-input');
        // this.overlay = this.wrapper.querySelector('.overlay-date-visitor');

        this.initDateAndVisitor();
        this.initCloseComponent();
        this.initVisitorsTextOnInputsChange();

        //show a notice (to open the winalist business drawer) to the user when the max number of visitors is reached
        this.initShowNoticeOnMaxReached();
    }

    /**
     * Initializes the date and visitor.
     *
     * @return {undefined} This function does not return anything.
     */
    initDateAndVisitor() {
        this.btnDate = this.wrapper.querySelector('.container-calendar-visitor__date-section');
        this.btnVisitor = this.wrapper.querySelector('.container-calendar-visitor__visitor-section');

        // if (!this.btnDate || !this.btnVisitor) return console.error('date or visitor button not found');

        this.container = this.wrapper.querySelector('.container-calendar-visitor');

        this.content = this.wrapper.querySelector('.content-calendar-visitor');

        // Get separator between btn date and btn visitor
        this.separator = this.wrapper.querySelector('.separator');

        this.contentVisitor = this.wrapper.querySelector('.content-visitor');
        this.contentCalendar = this.wrapper.querySelector('.content-calendar');

        this.focusClass = ['fade-border--active'];

        // Focus on btn date
        this.btnDate?.addEventListener('click', () => {
          this.btnDate?.classList.add(...this.focusClass);
          this.btnVisitor.classList.remove(...this.focusClass);
          this.separator?.classList.add('hidden');

          this.content.classList.remove('hidden');
          this.container.classList.add('shadow-2xl', 'border', 'absolute', 'opened');

          // Active transition
          this.contentCalendar?.classList.add('content-calendar--active');
          this.contentVisitor?.classList.remove('content-visitor--active');

          // Delay before hiding the content visitor
          setTimeout(() => {
            this.contentVisitor?.classList.add('hidden');
            this.contentCalendar?.classList.remove('hidden');

            // this.overlay?.classList.add('opened')
            this.smoothScroll(this.contentCalendar, this.contentCalendar.offsetTop + this.contentCalendar.clientHeight + 100);
          }, this.delayBeforeSwitchingTab);

        });

        // Focus on btn visitor
        this.btnVisitor.addEventListener('click', () => {
          this.focusOnVisitors();
        });
    }

    focusOnVisitors(){
      this.btnVisitor.classList.add(...this.focusClass);
      this.btnDate?.classList.remove(...this.focusClass);
      this.separator?.classList.add('hidden');

      this.content.classList.remove('hidden');
      this.container.classList.add('shadow-2xl', 'border', 'absolute', 'opened')

      // Active transition
      this.contentVisitor?.classList.add('content-visitor--active');
      this.contentCalendar?.classList.remove('content-calendar--active');

      // Delay before hiding the content calendar
      setTimeout(() => {
        this.contentCalendar?.classList.add('hidden');
        this.contentVisitor?.classList.remove('hidden');

        // this.overlay?.classList.add('opened')
        this.smoothScroll(this.contentVisitor, this.contentVisitor.offsetTop + this.contentVisitor.clientHeight + 100);
      }, this.delayBeforeSwitchingTab);

    }

    /**
     * Closes the component by removing focus classes, hiding the separator,
     * hiding the content, and removing shadow, border, and absolute classes from the container.
     *
     */
    initCloseComponent(){
        const closeComponent = () => {
          this.btnDate?.classList.remove(...this.focusClass);
          this.btnVisitor.classList.remove(...this.focusClass);
          this.separator?.classList.remove('hidden');

          this.content.classList.add('hidden');
          this.container.classList.remove('shadow-2xl', 'border', 'absolute', 'opened');

          // this.overlay?.classList.remove('opened');
        };

        // On click outside the container-calendar-visitor component
        document.addEventListener('click', (event) => {
          const hasOpenContainer = this.container.classList.contains('opened');
          const isOutsideComponent = !event.target.closest('.container-calendar-visitor');
          if (isOutsideComponent && hasOpenContainer) {
            closeComponent();
          }
        });

        const confirmDateButton = this.wrapper.querySelector('.container-calendar-confirm');
        if(confirmDateButton){
          confirmDateButton.addEventListener('click', () => {
            //if user already set visitors count, just close the component
            if(this.btnVisitor.classList.contains('visitors-set')){
              closeComponent();
            }
            //otherwise, focus on the visitors section
            else{
              this.focusOnVisitors();
              this.btnVisitor?.classList.add('visitors-set');
            }
          });
        }

        const confirmVisitorsButton = this.wrapper.querySelector('.container-calendar-confirm-visitors');
        if(confirmVisitorsButton){
          confirmVisitorsButton.addEventListener('click', () => {
            this.handleVisitorsText();
            closeComponent();
          });
        }
    }

    /**
     * Initializes the event listener on the inputs for visitors and triggers the
     * 'handleVisitorsText' function when any input value changes.
     *
     * @param {type} paramName - description of parameter
     * @return {type} description of return value
     */
    initVisitorsTextOnInputsChange(){
      const events = ['change', 'updateContainerValue'];

      [this.adultspicker, this.childrenpicker, this.infantspicker].forEach(input => {
        events.forEach(event => {
          input?.addEventListener(event, () => {
            this.handleVisitorsText();
          });
        });
      });

      if(this.wrapper.hasAttribute('trigger-visitors-text-change-on-pageload')){
        this.handleVisitorsText();
      }
    }

    initShowNoticeOnMaxReached(){
      const maxReachedNotice = this.wrapper.querySelector('.larger-group-notice');

      if(maxReachedNotice){
        const events = ['change', 'updateContainerValue'];

        events.forEach(event => {
          this.adultspicker.addEventListener(event, () => {
            if(Number(this.adultspicker.value) >= Number(this.adultspicker.max)){
              maxReachedNotice.classList.remove('hidden');
            }
            else{
              maxReachedNotice.classList.add('hidden');
            }
          });
        });
      }
    }

    /**
     * Initializes the date picker with the given input and configuration.
     *
     * @param {HTMLElement} input - The input element to attach the date picker to.
     * @param {Object} config - The configuration object for the date picker.
     * @return {Flatpickr} The initialized Flatpickr instance.
     */
    static initDatePicker(input, config) {
        // const input = document.querySelector('.flatpickr');
        // const localeDateOptions = { month: 'short', day: 'numeric' };
        flatpickr.localize(flatpickrLanguages[document.body.dataset.lang.substr(0, 2)]);
        flatpickr.defaultConfig.prevArrow = `
            <i class="icon icon-angle-left"></i>
        `;
        flatpickr.defaultConfig.nextArrow = `
            <i class="icon icon-angle-right"></i>
        `;

        return flatpickr(input, config);
    }

      /**
   * Handles the text (ex: 2 adults, 1 child, 2 infants) for the visitors.
   *
   * @return {void}
   */
  handleVisitorsText(){
    if(this.visitorsText){
      let text = "";
      if (this.adultspicker.value > 0) {
        text += this.adultspicker.value > 1 ? this.visitorsText.dataset.adults.replace("{0}", this.adultspicker.value)
                                           : this.visitorsText.dataset.adult;
      }

      if (this.childrenpicker && this.childrenpicker.value > 0) {
        if (this.adultspicker.value > 0) {
          text += ", ";
        }
        text += this.childrenpicker?.value > 1 ? this.visitorsText.dataset.children.replace("{0}", this.childrenpicker?.value)
                                              : this.visitorsText.dataset.child;
      }

      if (this.infantspicker && this.infantspicker.value > 0) {
        if (this.adultspicker.value > 0 || (this.childrenpicker && this.childrenpicker.value > 0)) {
          text += ", ";
        }
        text += this.infantspicker.value > 1 ? this.visitorsText.dataset.infants.replace("{0}", this.infantspicker.value)
                                              : this.visitorsText.dataset.infant;
      }

      if (this.adultspicker.value == 0
          && ((this.childrenpicker && this.childrenpicker.value == 0) || !this.childrenpicker)
          && ((this.infantspicker && this.infantspicker.value == 0) || !this.infantspicker)
      ){
        text = this.visitorsText.dataset.initialText;
        this.visitorsText.parentElement.classList.remove('value-set');
      }
      else {
        this.visitorsText.parentElement.classList.add('value-set');
      }

      this.visitorsText.textContent = text;
    }
  }

  /**
   * Performs smooth scrolling.
   *
   * @param {Object} target - the target element to scroll to
   * @param {number} position - the position to scroll to
   */
  smoothScroll(target, position){
    // window.setTimeout(() => {
      //only on mobile and if we need to scroll down and not up
      let wrapper = target.closest('.date-visitor-scroll-wrapper');
      let wrapperIsWindow = false;
      if(!wrapper){
        wrapper = window;
        wrapperIsWindow = true;
      }
      const scrollTop = wrapper.scrollY ?? wrapper.scrollTop;

      //if any device & in a drawer, do the scroll
      //otherwise, do the scroll only if < 840px (we don't want the scroll in desktop outside of a drawer, it looks weird due to sticky booking form)
      if((window.matchMedia("(max-width: 840px)").matches || (!wrapperIsWindow && wrapper.closest('.drawer'))) && scrollTop < position){
        wrapper.scrollTo(0, position);
      }
    // }, this.delayBeforeSwitchingTab + 80);
  }

  /**
   * Switches to visitors content of the component when a date is clicked.
   */
  static switchToVisitorsOnDateClick(wrapper = document){
    if(wrapper.querySelectorAll('.container-calendar-visitor.opened').length > 0){
      const openedDatepickerContainer = wrapper.querySelector('.container-calendar-visitor .content-calendar:not(.hidden)');
      if(openedDatepickerContainer){
        const dateVisitorContainer = openedDatepickerContainer.closest('.container-calendar-visitor');
        if(dateVisitorContainer){
          const switchToVisitorsButton = dateVisitorContainer.querySelector('.container-calendar-visitor__visitor-section');
          if(switchToVisitorsButton && !switchToVisitorsButton.classList.contains('visitors-set')){
            window.setTimeout(() => {
              switchToVisitorsButton.classList.add('visitors-set');
              switchToVisitorsButton.dispatchEvent(new Event('click'));
            }, 200);
          }
        }
      }
    }
  }
}

export default DateVisitor;
