
const subscribeToNewsLetter = (form) => {
    form.addEventListener('submit', function(e){
        e.preventDefault();

        let formData = new FormData(this);

        const checkIcon = form.querySelector(".icon-check");
        const email = form.querySelector(".email");
        const submit = form.querySelector(".submit");
        const rgpdCheckbox = form.querySelector(".rgpdCheckbox");

        if(rgpdCheckbox){
            rgpdCheckbox.classList.add('slideOut');
            rgpdCheckbox.classList.add('hidden');
        }

        if(email && submit && checkIcon){
          email.classList.add('slideOut');
          email.classList.add('hidden');

          submit.classList.add('slideOut');
          submit.classList.add('hidden');

          checkIcon.classList.add('slideIn');
          checkIcon.classList.remove('hidden');
        }

        setTimeout(() => {
            Modal.closeAllModals();
        }, 1500);

        ajax(this.action, (data) => {
          if(data.redirectToGift){
            alert('test')
            window.setTimeout(() => {
              window.location.href = data.giftUrl;
            }, 1000);
          }
        }, 'POST', formData, {
                "X-CSRF-Token": document.querySelector('meta[name="csrfToken"]')?.content
        }, true, true);
    });
}

export { subscribeToNewsLetter }

