class PaymentLoadingScreen {
  constructor(){
    this.screen = document.getElementById('payment-loading-screen');
    if(this.screen){
      this.loadingBar = this.screen.querySelector('.loading-bar');
      this.width = 0;

      this.loadingText = this.screen.querySelector('.loading-bar-text');
    }
  }

  // show the loading screen
  show(){
    this.reset();
    if(this.screen){
      this.screen.classList.add('showed');
      this.startAnimation();
    }
  }

  // hide the loading screen
  hide(){
    if(this.screen){
      this.screen.classList.remove('showed');
    }
  }

  // reset the loading screen
  reset(){
    if(this.loadingBar){
      this.width = 0;
      this.loadingBar.style.width = this.width + '%';

      if(this.loadingText) this.loadingText.textContent = this.loadingText.dataset.initialText;
    }
  }

  // start the animation
  startAnimation(){
    if(this.loadingBar && this.loadingText){
      this.animationInterval = window.setInterval(() => {
        if(this.width < 100){
          // random width between 13 and 19
          const randomWidth = Math.floor(Math.random() * (19 - 13 + 1)) + 13;
          this.width += randomWidth;
          if(this.width > 100) this.width = 100;

          this.loadingBar.style.width = this.width + '%';

          this.loadingText.textContent = this.getLoadingText();
        }
      }, 500);
    }
  }

  // get the loading text
  getLoadingText(){
    if(this.width >= 50 && this.width < 90){
      return this.loadingText.dataset.aboveFiftyText;
    }
    else if(this.width >= 90){
      return this.loadingText.dataset.atHundredText;
    }

    return this.loadingText.dataset.initialText;
  }
}

export default PaymentLoadingScreen;
