import { ajax } from "../utils"

//update cart to know if cookies/gtm are blocked or not - tracking
//type = booking ? gift card ?
const updateTrackingInfos = (type, transactionId, url) => {
  const formData = new FormData();
  formData.append('type', type);
  formData.append('transactionId', transactionId);
  formData.append('cookiesBlocked', window.navigator && !window.navigator.cookieEnabled);
  formData.append('gtmBlocked', window && window.google_tag_manager === undefined);

  try{
    ajax(url, () => {}, 'POST', formData, {
      "X-CSRF-Token": document.querySelector('meta[name="csrfToken"]')?.content
    }, true, false);
  } catch(e){
    console.error(e);
  }

}

export { updateTrackingInfos }
