import flatpickr from "flatpickr";
import flatpickrLanguages from "flatpickr/dist/l10n";
import {ajax, capitalize, debounced} from "../utils";

class KioskBookingProcess {
  constructor() {
    this.wrapper = document.querySelector('#kiosk-booking');

    this.isTicket = this.wrapper.hasAttribute('data-is-ticket');

    this.datepicker = this.wrapper.querySelector('input[name=date]');
    this.langOptions = this.wrapper.querySelectorAll('[name=lang_id]');
    this.adultspicker = this.wrapper.querySelector('input[name=adults]');
    this.childrenpicker = this.wrapper.querySelector('input[name=children]');
    this.experienceId = this.wrapper.dataset.experienceId;

    this.dateHeader = this.wrapper.querySelector('#date-header');
    this.nextDayDiv = this.wrapper.querySelector('#nextDay');
    this.timeslotsContainer = this.wrapper.querySelector('#slotsContainer');
    this.slotsLoading = this.wrapper.querySelector('#slots-loading');
    this.slotChosen = this.wrapper.querySelector('input[name=slot]');
    this.slotId = this.wrapper.querySelector('input[name=slot_id]');
    this.bookingAuto = this.wrapper.querySelector('input[name=booking_auto]');

    this.visitorsNumber = this.wrapper.querySelector('#visitors-number');
    this.total = document.querySelector('.amount');
    // this.wrapperNotAvailableText = this.wrapper.querySelector('.experience-not-available-for-filters');
    // this.notAvailableText = this.wrapperNotAvailableText.querySelector('p');
    // this.session = JSON.parse(document.body.dataset.session);
    this.submit = this.wrapper.parentElement.querySelector('button[data-go-to-payment]');
    // this.rawPrice = 0;
    // this.offerGiftBtn = this.wrapper.querySelector("#gift-offer");
    this.currentIteration = 0;
    this.maxIterations = 12; //Check available slots on 1 year
    this.nextMonthAvailable = false;
    this.nextYearAvailable = false;
    this.ajaxRequestRunning = false;
    this.ajaxRequest = null;
    this.isFree = this.wrapper.dataset.isFree === 'true';

    this.displayPersonsWrapper = this.wrapper.querySelector('#display-persons-wrapper');
    this.displayPersons = this.wrapper.querySelector('#display-persons');
    if(this.displayPersonsWrapper && this.displayPersons)
      this.displayPersonsOnSummary();

    this.displayLangWrapper = this.wrapper.querySelector('#display-lang-wrapper')
    this.displayLangIsoFlag = this.wrapper.querySelector('#display-lang-iso-flag');
    this.displayLangName = this.wrapper.querySelector('#display-lang-name');

    this.displaySlot = this.wrapper.querySelector('#display-slot');

    this.submitButton = document.getElementById('submit-button');

    const _this = this;
    this.debouncedUpdateFlatpickrWithNewPersons = debounced(500, () => {
      _this.updateFlatpickrWithNewPersons();
    });

    this.loadLangPicker();

    if(this.isTicket) this.setPrice();
  }

  loadLangPicker() {
    const _this = this;

    this.currentAdults = parseInt(this.adultspicker.value);
    this.currentChildren = this.childrenpicker ? parseInt(this.childrenpicker.value) : 0;

    this.currentLangId = this.wrapper.querySelector('[name=lang_id]:checked').value;
    this.maxPersons = this.adultspicker.max;

    this.setupEvents();

    //If experience doesn't use the ticket system, then do everything that needs to be done with flatpickr / dates / slots
    if(!this.isTicket){
      let now = this.datepicker.hasAttribute('data-filter-date')
      ? new Date(this.datepicker.dataset.filterDate)
      : new Date();

      this.currentDate = null;
      this.currentDates = [];

      this.currentMonth = now.getMonth()+1;
      this.currentYear = now.getFullYear();

      this.defaultDate = now;
      this.bookedAtLeastOnceDates = [];

      flatpickr.localize(flatpickrLanguages[document.body.dataset.lang.substr(0, 2)]);
      this.initDates(function(dates) {
        _this.currentDates = dates;
        _this.flatpickrDate = flatpickr(_this.datepicker, {
          altInput: true,
          altFormat: "l, M J, Y",
          dateFormat: "Y-m-d",
          enable: _this.getEnabledDates(),
          defaultDate: now,
          // minDate: new Date(),
          disableMobile: true,
          inline: true,
          onMonthChange: function(selectedDates, dateStr, instance) {
            const rContainer = _this.flatpickrDate.calendarContainer.querySelector('.flatpickr-rContainer');
            if(rContainer) rContainer.classList.add('loading');

            _this.currentYear = instance.currentYear;
            _this.currentMonth = instance.currentMonth + 1;

            // const unavailableMonthContainer = _this.flatpickrDate.calendarContainer.querySelector('.month-unavailable');
            // check if we need to display this month as unavailable or not
            // if(_this.currentIteration > 0) {
            //   if (_this.currentYear > _this.nextYearAvailable) {
            //     unavailableMonthContainer.classList.remove('active');
            //   } else if (_this.currentYear === _this.nextYearAvailable && _this.currentMonth >= _this.nextMonthAvailable) {
            //     // do not display
            //     unavailableMonthContainer.classList.remove('active');
            //   } else {
            //     // display
            //     unavailableMonthContainer.classList.add('active');
            //   }
            // }

            _this.fetchDates(function() {
              instance.set('enable', _this.getEnabledDates());

              if(rContainer) rContainer.classList.remove('loading');
            });

            if(_this.timeslotsContainer && _this.slotChosen && _this.bookingAuto && _this.dateHeader && _this.nextDayDiv){
              _this.timeslotsContainer.innerHTML = '';
              _this.slotId.value = '';
              _this.slotChosen.value = '';
              _this.bookingAuto.value = '';
              _this.dateHeader.innerHTML = '';
              _this.nextDayDiv.innerHTML = '';
            }

            _this.submitButton.disabled = true;

            if(_this.displaySlot) _this.displaySlot.textContent = '-';
          },
          onDayCreate: function(dObj, dStr, fp, dayElem){
            let year = dayElem.dateObj.getFullYear();
            let month = ("0" + (dayElem.dateObj.getMonth() + 1)).slice(-2)
            let day = ("0" + dayElem.dateObj.getDate()).slice(-2);

            if(_this.bookedAtLeastOnceDates[`${year}-${month}-${day}`]){
              dayElem.classList.add('booked-at-least-once');
            }
          },
          onChange: function(selectedDates, dateStr) {
            if(selectedDates.length > 0) {
              _this.handleDateChange(selectedDates[0]);
            }

            //reset the slot id and disable the submit button
            _this.slotId.value = '';
            _this.slotChosen.value = '';
            _this.submitButton.disabled = true;
            if(_this.displaySlot) _this.displaySlot.textContent = '-';
          },
          onReady: function(selectedDates, dateStr, instance) {
            // if(selectedDates.length > 0) {
            //   _this.handleDateChange();
            // }

            let enabledDates = _this.getEnabledDates();
            if(enabledDates.length){
              if(_this.datepicker.hasAttribute('data-filter-date')){
                const date = new Date(_this.datepicker.dataset.filterDate);
                date.setHours(12, 0, 0);

                //if date is the same month as flatpickr is now and is among the current enables dates, then set the date
                if(date.getMonth() === instance.currentMonth && enabledDates.includes(date.toISOString().split('T')[0])){
                  date.setHours(0, 0, 0);
                  instance.setDate(date, true);
                }
                else{
                  const firstDateAvailable = new Date(enabledDates[0]);
                  firstDateAvailable.setHours(0,0,0);
                  instance.setDate(firstDateAvailable, true);
                }
              }
              else{
                const firstDateAvailable = new Date(enabledDates[0]);
                firstDateAvailable.setHours(0,0,0);
                instance.setDate(firstDateAvailable, true);
              }
            }
          }
        });

        // if(_this.currentIteration > 0) {
        //   const currentDate = new Date(_this.nextYearAvailable, _this.nextMonthAvailable-1);
        //   const flatpickrContainer = _this.flatpickrDate.calendarContainer.querySelector('.flatpickr-rContainer');
        //   const divElement = document.createElement('div');
        //   divElement.classList.add('month-unavailable', 'active');
        //   const divExplanation = document.createElement('div');
        //   const text = document.createTextNode(_this.wrapper.dataset.textBookedUntil.replace('{0}', capitalize(currentDate.toLocaleDateString('default', {month: 'long'}))));
        //   const button = document.createElement('button');
        //   button.type = 'button';
        //   button.innerText = _this.wrapper.dataset.textView.replace('{0}', capitalize(currentDate.toLocaleDateString(undefined, {month: 'long'})));
        //   button.addEventListener('click', function () {
        //     divElement.classList.remove('active');
        //     _this.flatpickrDate.changeYear(_this.nextYearAvailable);
        //     _this.flatpickrDate.changeMonth(_this.nextMonthAvailable - 1, false);
        //   });
        //   divExplanation.appendChild(text);
        //   divExplanation.appendChild(button);
        //   divElement.appendChild(divExplanation);
        //   flatpickrContainer.append(divElement);
        // }

        _this.setPrice();
        _this.wrapper.classList.remove('loading');
      });
    }
  }

  setupEvents() {
    const _this = this;

    this.langOptions.forEach((lang) => {
      lang.addEventListener('change', () => {
        _this.handleLangChange(lang.value);
        _this.displayLangOnSummary(lang);
      });
    });

    this.adultspicker.addEventListener('change', function() {
      _this.handleChangeAdult(this.value);

      if(!_this.isTicket){
        _this.debouncedUpdateFlatpickrWithNewPersons();
        _this.resetCalendarAndSlot();
      }

      _this.displayPersonsOnSummary();
    });
    if(this.childrenpicker) {
      _this.childrenpicker.addEventListener('change', function () {
        _this.handleChangeChild(this.value);

        if(!_this.isTicket){
          _this.debouncedUpdateFlatpickrWithNewPersons();
          _this.resetCalendarAndSlot();
        }

        _this.displayPersonsOnSummary();
      });
    }
  }

  resetCalendarAndSlot() {
    this.flatpickrDate.clear();

    //empty the slots container
    if(this.timeslotsContainer && this.dateHeader && this.nextDayDiv){
      this.timeslotsContainer.innerHTML = '';
      this.dateHeader.innerHTML = '';
      this.nextDayDiv.innerHTML = '';
    }
  }

  displayPersonsOnSummary(){
    let text = '';
    if(this.adultspicker.value == 1) text = this.displayPersonsWrapper.dataset.adult.replace('{0}', this.adultspicker.value);
    else if(this.adultspicker.value > 1) text = this.displayPersonsWrapper.dataset.adults.replace('{0}', this.adultspicker.value);

    if(this.childrenpicker){
      if(text != '' && this.childrenpicker.value > 0) text += ', ';

      if(this.childrenpicker.value == 1) text += this.displayPersonsWrapper.dataset.child.replace('{0}', this.childrenpicker.value);
      else if(this.childrenpicker.value > 1) text += this.displayPersonsWrapper.dataset.children.replace('{0}', this.childrenpicker.value);
    }

    this.displayPersons.textContent = text;
  }

  displayLangOnSummary(lang){
    this.displayLangIsoFlag.src = this.displayLangWrapper.dataset.langSrc.replace('{0}', lang.dataset.isoFlag);
    this.displayLangName.textContent = lang.dataset.name;
  }

  displaySlotOnSummary(slotDate){
    this.displaySlot.textContent = slotDate;
  }

  //use only when debounced (to not overload with ajax calls)
  updateFlatpickrWithNewPersons(){
    //to trigger manually the OnMonthChange event of flatpickr (see above)
    this.flatpickrDate.changeYear(this.currentYear);
    this.flatpickrDate.changeMonth(this.currentMonth -  1, false);
  }

  handleChangeAdult(newValue) {
    if(this.childrenpicker) {
      this.childrenpicker.max = this.maxPersons - newValue;
    }

    // update current value
    this.currentAdults = parseInt(newValue);
    this.setPrice();

    // update visitors number displayed
    this.updateVisitorsNumber();

    if(!this.isTicket){
      this.resetCalendarAndSlot();
      const _this = this;
      this.fetchDates(function() {
        _this.flatpickrDate.set('enable', _this.getEnabledDates());
      });
    }
  }

  updateVisitorsNumber() {
    if(this.visitorsNumber) {
      this.visitorsNumber.innerHTML = this.wrapper.dataset.textVisitorsNumber.replace('{n_b}', this.currentChildren + this.currentAdults);
    }
  }

  handleChangeChild(newValue) {
    this.adultspicker.max = this.maxPersons - newValue;

    // update current value
    this.currentChildren = parseInt(newValue);
    this.setPrice();

    // update visitors number displayed
    this.updateVisitorsNumber();

    if(!this.isTicket){
      this.resetCalendarAndSlot();
      const _this = this;
      this.fetchDates(function() {
        _this.flatpickrDate.set('enable', _this.getEnabledDates());
      });
    }
  }

  handleLangChange(newValue) {
    this.currentLangId = newValue;

    if(!this.isTicket){
      const _this = this;
      this.fetchDates(function() {
        _this.flatpickrDate.set('enable', _this.getEnabledDates());
      });
      // reset calendar value and timeslot value
      this.resetCalendarAndSlot();
    }
  }

  resetCalendarAndSlot() {
    this.flatpickrDate.clear();

    //empty the slots container
    if(this.timeslotsContainer && this.dateHeader && this.nextDayDiv){
      this.timeslotsContainer.innerHTML = '';
      this.dateHeader.innerHTML = '';
      this.nextDayDiv.innerHTML = '';
    }
  }

  setPrice(){
    let price;
    if(!this.isFree) {
      if (this.childrenpicker) {
        price = ((this.adultspicker.value * this.wrapper.dataset.priceAdult) + (this.childrenpicker.value * this.wrapper.dataset.priceChild)) / 100;
        // this.rawPrice = ((this.adultspicker.value * this.wrapper.dataset.eurPriceAdult) + (this.childrenpicker.value * this.wrapper.dataset.eurPriceChild)) / 100;
      } else {
        price = ((this.adultspicker.value * this.wrapper.dataset.priceAdult)) / 100;
        // this.rawPrice = ((this.adultspicker.value * this.wrapper.dataset.eurPriceAdult)) / 100;
      }
      this.total.innerHTML = price.toLocaleString(document.body.dataset.currencyLocale.replace('_', '-'), {
        style: 'currency',
        currency: document.body.dataset.currencyIso
      });
    }

    // update gift button
    // if(this.offerGiftBtn) {
    //   let priceInEUR = 0;
    //   if(this.childrenpicker) {
    //     priceInEUR = ((this.adultspicker.value * this.wrapper.dataset.eurPriceAdult) + (this.childrenpicker.value * this.wrapper.dataset.eurPriceChild)) / 100;
    //   } else {
    //     priceInEUR = ((this.adultspicker.value * this.wrapper.dataset.eurPriceAdult)) / 100;
    //   }
    //   this.offerGiftBtn.href = this.offerGiftBtn.dataset.url.replace("replaceAmount", priceInEUR);
    // }
  }

  handleDateChange(date) {
    // set current date from datepicker value
    this.currentDate = this.datepicker.value;

    //Update embed timeslots container header and button to next day
    const localeDateOptions = { weekday: 'long', month: 'short', day: 'numeric' };
    this.dateHeader.textContent = date.toLocaleDateString(document.body.dataset.lang.replace('_', '-'), localeDateOptions);

    // update timeslots
    this.updateTimeslots();
  }


  updateTimeslots() {
    // check if we have all information we need to make the request
    if(this.currentDate !== null && this.currentDate !== '' && this.currentLangId !== null) {
      // get available time slots
      // make ajax request
      const _this = this;
      if(this.slotsLoading) this.slotsLoading.classList.add('active');
      ajax(`/api/experience/${this.experienceId}/timeslots?date=${this.currentDate}&adults=${this.currentAdults}&children=${this.currentChildren}&languages=${this.currentLangId}`, function(data) {
        if(_this.timeslotsContainer && _this.slotChosen){
          _this.timeslotsContainer.innerHTML = '';
          _this.slotChosen.value = '';
          _this.slotId.value = '';
          _this.bookingAuto.value = '';
        }

        if (data.timeslots === 'undefined' || data.timeslots.length === 0) {
          console.warn('no time slots');
          // _this.notAvailableText.innerText = _this.wrapper.dataset.textNoTimeslot;
          // _this.wrapperNotAvailableText.classList.remove('hidden');
        } else {
          // _this.wrapperNotAvailableText.classList.add('hidden');
          data.timeslots.forEach(function (timeslot) {
            if(_this.timeslotsContainer){
              _this.timeslotsContainer.innerHTML += _this.buildEmbedSlotDiv(timeslot);
            }
          });

          if(_this.timeslotsContainer){
            const chooseSlot = _this.wrapper.querySelectorAll('.chooseSlot');
            if(chooseSlot){
              chooseSlot.forEach((button) => {
                button.addEventListener('click', () => {
                  _this.slotId.value = button.dataset.slotId;
                  _this.slotChosen.value = button.dataset.startAt;
                  _this.bookingAuto.value = button.dataset.bookingAuto;

                  //enable the submit button
                  _this.submitButton.disabled = false;

                  //remove the active state on the previous slot if there is one
                  const previousChosenSlot = _this.wrapper.querySelector('.slot-chosen');
                  if(previousChosenSlot) previousChosenSlot.classList.remove('slot-chosen');

                  //and add on the new one
                  button.parentElement.classList.add('slot-chosen');

                  //display on summary the chosen slot (in case experience is not a ticket without date)
                  if(_this.displaySlot){
                    _this.displaySlotOnSummary(button.dataset.fullDatetime);
                  }
                });
              });
            }
            _this.buildNextDayButton();
          }

          if(_this.slotsLoading) _this.slotsLoading.classList.remove('active');

          // let wrapperContainer = _this.wrapper.closest('.modal-container');
          // wrapperContainer.scrollTop = wrapperContainer.scrollHeight;
        }
      });
    }
  }

  buildEmbedSlotDiv(timeslot){
    let spotsLeft = this.timeslotsContainer.dataset.spotsLeft.replace('{0}', timeslot.seats_rest);
    let spotsLeftSpan = ``;
    if(timeslot.seats_rest <= timeslot.seats_max/2){
      spotsLeftSpan = `<span class="text-gray-500 text-xs mr-2 mt-1">${spotsLeft} ∙ </span>`;
    }

    let flags = '';
    timeslot.languages_url.forEach((flagUrl) => {
      flags += `<span class='flag flag-xs mr-1'><img src='${flagUrl}' width='28' class='flag flag-sm'/></span>`;
    });

    return `
      <div class="flex items-center p-4 border rounded-lg my-2 slot-wrapper">
        <span class="font-semibold mr-2">${timeslot.start_at} - ${timeslot.end_at}</span>
        <div class="flex flex-wrap items-center mb-px">
          ${spotsLeftSpan}
          <span class="mr-2">${flags}</span>
        </div>
        <button class="btn btn-red rounded-lg ml-auto px-8 py-2 chooseSlot" type="button"
                data-full-datetime="${timeslot.full_datetime}"
                data-start-at="${timeslot.start_at}"
                data-booking-auto="${timeslot.booking_auto}"
                data-slot-id="${timeslot.id}">
          ${this.timeslotsContainer.dataset.choose}
        </button>
        <i class="icon icon-simple-check text-green text-xl ml-auto slot-selected"></i>
      </div>
    `;
  }

  buildNextDayButton(){
    const _this = this;

    let currentDay = this.wrapper.querySelector('.flatpickr-day.selected');
    if(currentDay){
      let nextDay = null;
      let checkLimit = 0;
      while(!nextDay && checkLimit < 30){
        if(currentDay && currentDay.nextSibling && !currentDay.nextSibling.classList.contains('flatpickr-disabled')){
          nextDay = currentDay.nextSibling;
          break;
        }

        if(currentDay) currentDay = currentDay.nextSibling;
        checkLimit++;
      }

      if(nextDay){
        const localeDateOptions = { weekday: 'long', month: 'short', day: 'numeric' };
        let nextDayDate = this.flatpickrDate.selectedDates[0];
        nextDayDate.setDate(nextDay.textContent);
        const seeNextDayHTML = `
          <div class="flex items-center justify-center">
            <button class="btn border border-gray-300 px-12 py-2" type="button" id="checkNextDay">
              ${this.timeslotsContainer.dataset.seeNextDay.replace('{0}', nextDayDate.toLocaleDateString(document.body.dataset.lang.replace('_', '-'), localeDateOptions))}
            </button>
          </div>
        `;

        this.nextDayDiv.innerHTML = seeNextDayHTML;
        const seeNextDayButton = this.wrapper.querySelector('#checkNextDay');
        if(seeNextDayButton){
          seeNextDayButton.addEventListener('click', () => {
            _this.flatpickrDate.setDate(nextDayDate, true);
          });
        }
      }
      else this.nextDayDiv.innerHTML = '';
    }
  }

  //Check if a day is booked at least once on one of its timeslots
  bookedAtLeastOnce(date){
    let res = false;
    date.time_slots.forEach((slot) => {
      if(slot.seats_count > 0){
        res = true;
        return;
      }
    });

    return res;
  }

  getEnabledDates() {
    const _this = this;

    const enabledDates = this.currentDates.filter((date) => {
      if(!date.close && this.bookedAtLeastOnce(date)){
        _this.bookedAtLeastOnceDates[date.date_format] = true;
      }
      return !date.close;
    });

    // check if 0 enabled dates
    if(enabledDates.length === 0) {
      // return false to disable all dates
      return [function() {
        return false;
      }];
    }

    return enabledDates.map((date) => {
      return date.date_format;
    });
  }

  getRawEnabledDates() {
    const enabledDates = this.currentDates.filter((date) => {
      return !date.close;
    });

    return enabledDates.map((date) => {
      return date.date_format;
    });
  }

  fetchDates(callback) {
    const _this = this;
    callback = callback || function(){};

    if(this.ajaxRequestRunning) {
      this.ajaxRequest.abort();
    }

    this.ajaxRequestRunning = true;
    this.ajaxRequest = ajax(this.wrapper.dataset.datesUrl + `?month=${this.currentMonth}&year=${this.currentYear}&lang_id=${this.currentLangId}&adults=${this.currentAdults}&children=${this.currentChildren}`, function(data) {
      _this.currentDates = data.dates;
      callback(data.dates);
      _this.ajaxRequestRunning = false;
    });
  }

  initDates(callback) {
    const _this = this;
    callback = callback || function(){};
    ajax(this.wrapper.dataset.datesUrl + `?month=${this.currentMonth}&year=${this.currentYear}&lang_id=${this.currentLangId}&adults=${this.currentAdults}&children=${this.currentChildren}`, function(data) {
      _this.currentDates = data.dates;
      let enabledDates = _this.getRawEnabledDates();
      if(enabledDates.length === 0 && _this.currentIteration < _this.maxIterations) {
        if(_this.currentMonth === 12) {
          _this.currentMonth = 1;
          _this.currentYear++;
        } else {
          _this.currentMonth = _this.currentMonth + 1;
        }
        _this.currentIteration++;
        _this.initDates(callback);
        return;
      }

      _this.nextMonthAvailable = _this.currentMonth;
      _this.nextYearAvailable = _this.currentYear;

      callback(data.dates);
    });
  }
}

export default KioskBookingProcess;
