import { ajax, debounced } from "../utils";

class Rating {
  constructor() {
    this.wrapper = document.getElementById("reviewProcess");
    if(this.wrapper){
      const _this = this;

      this.wineBought = this.wrapper.querySelectorAll("input[name='wine_bought']");
      this.wineAmount = this.wrapper.querySelector("#wineAmount");
      if(this.wineBought && this.wineAmount){
        this.wineBought.forEach(function(input){
          input.addEventListener('change', function(){
            if(this.value == 1) _this.wineAmount.classList.remove('hidden');
            else _this.wineAmount.classList.add('hidden');
          });

          if(input.checked)
            input.dispatchEvent(new Event('change'));
        });
      }

      this.next = this.wrapper.querySelector('#next');
      // this.errorField = this.wrapper.querySelector('#error-field');
      this.form = this.wrapper.querySelector('form');
      if(this.form){
        this.formSubmit();

        if(this.next/* && this.errorField*/){
          this.next.addEventListener('click', (e) => {
            _this.onNextClick(e);

            // If we are on the last step, we want to disable the button after the form is submitted
            // Preventing the user from submitting the form multiple times
            if(this.next.dataset.lastLabel && this.next.dataset.lastLabel === this.next.textContent.trim())
            _this.next.classList.add('disabled');
          });
        }
      }

      this.ratings = this.wrapper.querySelectorAll('.rating');
      this.currentRatingElts = this.wrapper.querySelectorAll('.current-rating');
      this.userExampleStars = this.wrapper.querySelector('.user-example-stars-wrapper .front-stars');
      if(this.ratings){
        this.handleRatings();
      }

      this.handleAnonymous();

      this.save = document.getElementById('save');
      if(this.save){
        this.save.addEventListener('click', (e) => _this.onSaveClick(e));
      }

      this.lastSave = document.getElementById('last-save');

      this.radios = this.wrapper.querySelectorAll("input[type='radio'], input[type='checkbox']");
      if(this.radios){
        this.radios.forEach(function(radio){
          radio.addEventListener('change', () => _this.store());
        });
      }

      this.comment = this.wrapper.querySelector("textarea[name='comment']");
      this.privateComment = this.wrapper.querySelector("textarea[name='private_comment']");
      if((this.comment || this.privateComment) && this.next){
        this.debounceKeyUp = debounced(1000, function () {
          _this.store();
          // _this.next.textContent = _this.comment.value.length > 0
          //                         ? _this.next.dataset.next
          //                         : _this.next.dataset.skip;
        });

        if(this.comment) this.comment.addEventListener('keyup', () => _this.debounceKeyUp());
        if(this.privateComment) this.privateComment.addEventListener('keyup', () => _this.debounceKeyUp());
      }

      this.numbers = this.wrapper.querySelectorAll('.nps .number');
      this.activeNumber = this.wrapper.querySelector('.nps .number.active');
      this.inputNPS = this.wrapper.querySelector("input[name='nps']");
      if(this.numbers){
        this.numbers.forEach(function(number){
          number.addEventListener('click', () => _this.npsClick(number));
        });
      }
    }
  }

  formSubmit(){
    this.form.addEventListener('submit', function(e){
      e.preventDefault();
      let formData = new FormData(this);

      ajax(this.action, function () {
      }, 'POST', formData, {
              "X-CSRF-Token": document.querySelector('meta[name="csrfToken"]')?.content
      }, true, false);
    });
  }

  onNextClick(e){
    if(this.form.comment){
      this.form.comment.value = this.form.comment.value.trim();
    }

    if(!this.form.checkValidity()){
      e.preventDefault();
      // this.errorField.classList.remove('hidden');
    }
    else{
      if(this.form.comment){
        this.store();
      }
    }
  }

  handleRatings(){
    const _this = this;

    this.ratings.forEach(function(rating){
      const stars = rating.querySelectorAll('.star');
      const description = rating.querySelector('.description');
      const input = rating.querySelector("input[type='hidden']");
      if(stars){
        // half star
        if (rating.dataset.initialNote % 1 !== 0) {
          let halfStar = stars[Math.floor(rating.dataset.initialNote)];
          halfStar.classList.add('half-star'); // ! change to half star
        }

        stars.forEach(function(star){
          star.addEventListener('click', (event) => _this.handleStars(event, star, stars, description, input, rating, true));
          star.addEventListener('mousemove', (event) => _this.handleStars(event, star, stars, description, input, rating, false));
          star.addEventListener('mouseout', () => _this.onStarMouseLeft(rating, stars));
        });
      }
    });
  }

  handleStars(event, currentStar, stars, description, input, rating, click = false) {
    stars.forEach((star, index) => {
      star.classList.toggle('active', index < currentStar.dataset.note);
      star.classList.remove('half-star');
    });

    let middleOfStar = currentStar.getBoundingClientRect().width / 2;
    let isHalfStar = event.clientX - currentStar.getBoundingClientRect().left < middleOfStar;
    if (isHalfStar) {
      currentStar.classList.add('half-star'); // ! change to half star
    }

    if(click){
      let note = currentStar.dataset.note;
      if (isHalfStar) {
        note = parseFloat(note) - 0.5;
      }

      rating.dataset.initialNote = note;
      if (description) {
        const descriptions = {
          1: rating.dataset.one,
          2: rating.dataset.two,
          3: rating.dataset.three,
          4: rating.dataset.four,
          5: rating.dataset.five
        };

        description.textContent = descriptions[Math.ceil(note)];
        if(this.currentRatingElts){
          this.currentRatingElts.forEach(currentRating => currentRating.textContent = note);
          this.updateUserExampleStars(note);
        }
      }

      if (input) {
        input.value = note;
        this.store();
      }
    }
  }

  onStarMouseLeft(rating, stars){
    const initialNote = parseFloat(rating.dataset.initialNote);
    const isHalfStar = initialNote % 1 !== 0;

    stars.forEach((star) => {
      star.classList.remove('active');
      star.classList.remove('half-star');
    });

    stars.forEach((star) => {
      const starNote = parseFloat(star.dataset.note);

      if (starNote <= initialNote) {
        star.classList.add('active');
      } else if (isHalfStar && starNote === initialNote + 0.5) {
        star.classList.add('active');
        star.classList.add('half-star');
      }
    });
  }

  updateUserExampleStars(note){
    if(this.userExampleStars && note > 0){
      this.userExampleStars.style.width = note * 20 + '%';
    }
  }

  handleAnonymous(){
    const anonymousInput = this.wrapper.querySelector('input[name=anonymous]');
    const postedBy = this.wrapper.querySelector('.posted-by');
    if(anonymousInput && postedBy){
      anonymousInput.addEventListener('change', () => {
        if(anonymousInput.checked){
          postedBy.textContent = postedBy.dataset.anonymous;
        } else {
          postedBy.textContent = postedBy.dataset.firstname;
        }
      });
    }
  }

  onSaveClick(e){
    e.preventDefault();

    this.store(this.save.href);
  }

  store(redirectUrl = null){
    this.form.dispatchEvent(new Event('submit', { cancelable: true }));

    if(this.lastSave){
      const locale = document.body.dataset.lang.replace("_", "-");
      const localeDateOptions = {hour: '2-digit', minute: '2-digit', second: '2-digit'};
      const now = new Date();
      this.lastSave.textContent = this.lastSave.dataset.text.replace('{0}', now.toLocaleTimeString(locale, localeDateOptions));
    }

    if(redirectUrl){
      window.location.href = redirectUrl;
    }
  }

  npsClick(number){
    if(this.activeNumber){
      this.activeNumber.classList.remove('active');
    }

    number.classList.add('active');
    this.activeNumber = number;

    if(this.inputNPS){
      this.inputNPS.value = number.dataset.num;
      this.store();
    }
  }
}

export default Rating;
