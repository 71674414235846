class BookingRecap {
  constructor() {
    this.wrapper = document.getElementById('booking-recap');
    if(this.wrapper){
      this.initCheaperPriceInput();
    }
  }

  initCheaperPriceInput(){
    this.cheaperPriceInputWrapper = this.wrapper.querySelector('#cheaper-price-url-wrapper');
    this.cancelReasonSelect = this.wrapper.querySelector('#select-cancel-reason');
    if(this.cheaperPriceInputWrapper && this.cancelReasonSelect){
      this.cancelReasonSelect.addEventListener('change', () => {
        this.cheaperPriceInputWrapper.classList.toggle('hidden', this.cancelReasonSelect.value != 'cheaper_price');
      });
    }
  }
}

export default BookingRecap;
