import { initNps } from "./initNps";
import { initTabs } from "../common/initTabs";
import Drawer from "../common/Drawer";
import { ajax } from "../utils";
import Message from "../Message";


const thankYouPage = () => {
    let wrapper = document.getElementById('thank-you-page');
    if (!wrapper) return;

    let btnOpenDrawer = document.querySelector('[data-drawer-tag="recommendWinalist"]');
    if (!btnOpenDrawer) return;

    let form = document.querySelector('#nps-form');
    if (!form) return;

    formSubmit(form);

    // ? Init tabs
    initTabs();

    setTimeout(() => {
        btnOpenDrawer.click();

        initNps();

        // on click on nps score change to the next tab
        document.querySelectorAll('.nps-score').forEach((button) => {
            button.addEventListener('click', () => {
                // save nps score on the first tab
                saveNpsScore(form);
                document.getElementById('tab-nps-description').click();
            });
        });

    }, 3000);
}

const formSubmit = (form) => {
    let btnSubmit = form.querySelector('#submitButton');
    if (!btnSubmit) return;
    btnSubmit.addEventListener('click', (e) => {
        btnSubmit.disabled = true;
        btnSubmit.classList.add('loading');

        let formData = new FormData(form);

        ajax(form.action,
            (data) => {
                data = JSON.parse(data);
                if (data.success) {
                    Drawer.closeDrawer(document.querySelector('.drawer.opened'));
                    Message.success(data.message);
                } else {
                    Message.error(data.message);
                    btnSubmit.disabled = false;
                }
                btnSubmit.classList.remove('loading');
            },
            'POST',
            formData,
            {
                "X-CSRF-Token": document.querySelector('meta[name="csrfToken"]')?.content
            },
            true,
            false
        );
    })
}

const saveNpsScore = (form) => {
    let formData = new FormData(form);

    // if description is empty remove it from formData
    if (formData.get('description').trim() === '') {
        formData.delete('description');
    }

    ajax(form.action,
        () => {},
        'POST',
        formData,
        {
            "X-CSRF-Token": document.querySelector('meta[name="csrfToken"]')?.content
        },
        true,
        false
    );
}

export { thankYouPage };


